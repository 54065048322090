import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../firebase.js";
import "../Styles/Sass/ViewMyJobsCard.scss";
import ChatModal from "./ChatModal.js";

function ViewMyJobsCard(props) {
  const [currentJob, setCurrentJob] = React.useState({});
  const [currentJobAuthor, setCurrentJobAuthor] = React.useState("");
  const [isExpired, setIsExpired] = React.useState(false);
  const [messagesLength, setMessagesLength] = React.useState(0);
  const [chatModalIsOpen, setChatModalIsOpen] = React.useState(false);
  const [hasNewMessage, setHasNewMessage] = React.useState(true);
  const toggleChatModal = () => {
    updateTakenByLastOpen();
    getMessages();
    setChatModalIsOpen(!chatModalIsOpen);
  };
  React.useEffect(() => {
    getMessages();
    const getJobDetail = () => {
      let currentTime = Date.now();
      firestore
        .collection("jobs")
        .doc(props.jobId)
        .get()
        .then((res) => {
          let job = res.data();
          let secondsPassed = Math.floor((currentTime - job.timestamp) / 1000);
          if (secondsPassed / 86400 > 30) {
            setIsExpired(true);
          }
          setCurrentJob(job);
          firestore
            .collection("users")
            .doc(job.postedBy)
            .get()
            .then((res) => {
              setCurrentJobAuthor(res.data().name);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getJobDetail();
  }, [props.jobId, props.user]);

  const withdrawApplication = () => {
    firestore
      .collection("jobs")
      .doc(props.jobId)
      .get()
      .then((res) => {
        let applicants = res.data().applicants;
        let indexOfApplicant = applicants.findIndex((applicant) => {
          return applicant.id === props.user.uid;
        });
        let jobTakenBy = res.data().takenBy;
        if (jobTakenBy === props.user.uid) {
          jobTakenBy = "open";
        }
        applicants.splice(indexOfApplicant, 1);
        firestore
          .collection("jobs")
          .doc(props.jobId)
          .update({
            applicants: applicants,
            takenBy: jobTakenBy,
          })
          .then((res) => {
            firestore
              .collection("users")
              .doc(props.user.uid)
              .get()
              .then((res) => {
                let userJobs = res.data().jobs;
                let indexOfJob = userJobs.findIndex((job) => {
                  return job.jobId === props.jobId;
                });
                userJobs.splice(indexOfJob, 1);
                firestore
                  .collection("users")
                  .doc(props.user.uid)
                  .update({
                    jobs: userJobs,
                  })
                  .then((res) => {
                    firestore
                      .collection("users")
                      .doc(currentJob.postedBy)
                      .get()
                      .then((res) => {
                        let userJobs = res.data().jobs;
                        let indexOfJob = userJobs.findIndex((job) => {
                          return job.jobId === props.jobId;
                        });
                        userJobs[indexOfJob].status = "posted";
                        firestore
                          .collection("users")
                          .doc(currentJob.postedBy)
                          .update({
                            jobs: userJobs,
                          })
                          .then((res) => {
                            props.getJobs();
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateTakenByLastOpen = () => {
    firestore
      .collection("jobs")
      .doc(props.jobId)
      .update({ takenByMessageLastOpen: Date.now() })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const getMessages = () => {
    firestore
      .collection("jobs")
      .doc(props.jobId)
      .collection("messages")
      .orderBy("timestamp")
      .onSnapshot((snapshot) => {
        const messagesData = snapshot.docs.map((doc) => doc.data());
        setMessagesLength(messagesData.length);
        firestore
          .collection("jobs")
          .doc(props.jobId)
          .get()
          .then((res) => {
            let takenByMessageLastOpen = res.data().takenByMessageLastOpen;
            setHasNewMessage(
              messagesData[messagesData.length - 1].timestamp >
                takenByMessageLastOpen &&
                messagesData[0].sender !== props.user.uid
            );
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  return (
    <>
      <ChatModal
        modalIsOpen={chatModalIsOpen}
        closeModal={toggleChatModal}
        job={currentJob}
        user={props.user}
      />
      <li className="viewMyJobsCard">
        <Link to={`/profile/${currentJob.postedBy}`}>
          <h4 className="viewMyJobsCard__name">
            Posted by: {currentJobAuthor}
          </h4>
        </Link>
        <h5 className="viewMyJobsCard__headline">
          {currentJob.headline}
          {isExpired === true ? <span> (EXPIRED POSTING)</span> : <></>}
        </h5>
        <p className="viewMyJobsCard__text viewMyJobsCard__text--description">
          {currentJob.description}
        </p>
        <p className="viewMyJobsCard__text viewMyJobsCard__text--status">
          {currentJob.takenBy === props.user.uid ? "Accepted" : ""}
          {currentJob.takenBy !== "open" &&
          currentJob.takenBy !== props.user.uid
            ? "Rejected"
            : ""}
          {currentJob.takenBy === "open" ? "Open" : ""}
        </p>
        {messagesLength >= 1 ? (
          <button className="jobCard__button" onClick={toggleChatModal}>
            Respond to Employer
            {hasNewMessage ? (
              <span className="jobCard__newMessage">!</span>
            ) : (
              <></>
            )}
          </button>
        ) : (
          <></>
        )}
        <button
          className="viewMyJobsCard__button"
          onClick={withdrawApplication}
        >
          Withdraw Application
        </button>
        <div className="viewMyJobsCard__subBox">
          <p className="viewMyJobsCard__rate">${currentJob.rate} per hour</p>
          <p className="viewMyJobsCard__location">
            {currentJob.city} | {currentJob.postal} | {currentJob.province}
          </p>
        </div>
      </li>
    </>
  );
}

export default ViewMyJobsCard;
