import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../firebase.js";
import iconBadge from "../Images/iconBadge.svg";
import "../Styles/Sass/Profile.scss";
import usePremiumStatus from "../stripe/usePremiumStatus";
import ConfirmCleanerSuspensionModal from "./ConfirmCleanerSuspensionModal.js";
// import Modal from "react-modal";
// import FreePostModal from "../Components/FreePostModal.js";
function Profile(props) {
  const [userProfile, setUserProfile] = React.useState({});
  const userIsPremium = usePremiumStatus(props.user);
  const [modalSuspension, setModalSuspension] = React.useState(false);
  React.useEffect(() => {
    if (props.user) {
      firestore
        .collection("users")
        .doc(props.user.uid)
        .get()
        .then((res) => {
          let userdata = res.data();
          setUserProfile(userdata);
        });
    }
  }, [props.user]);
  //Handlers for suspending cleaner accounts
  function suspendHandle(userProfile) {
    firestore
      .collection("users")
      .doc(props.user.uid)
      .update({
        suspended: true,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function unsuspendHandle(userProfile) {
    firestore
      .collection("users")
      .doc(props.user.uid)
      .update({
        suspended: false,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      {userProfile.profileSetup ? (
        <section className="profile">
          <ConfirmCleanerSuspensionModal
            modalIsOpen={modalSuspension}
            closeModal={() => setModalSuspension(false)}
            clickFunction={
              userProfile.suspended ? unsuspendHandle : suspendHandle
            }
            suspensionStatus={userProfile.suspended}
          />
          {/* <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="freepostmodal"
      overlayClassName="freepostmodal__overlay"
      contentLabel="Free Post Modal"
    >
      <div className="freepostmodal__contentbox">
        <h2>Post your first listing on us</h2>
        <button onClick={closeModal} className={"freepostmodal__button"}>
          Submit a New Job
        </button>
      </div>
    </Modal> */}
          {props.user && props.page !== "dashboard" ? (
            <>
              <div className="profile__detailBox">
                <h3 className="profile__title">{props.name}'s Profile </h3>
                <h4 className="profile__subtitle profile__subtitle--head">
                  {props.headline && props.job === "cleaner"
                    ? props.headline
                    : ""}
                  {!props.headline && props.job === "cleaner"
                    ? "Incomplete Profile"
                    : ""}
                </h4>
                {props.job === "cleaner" ? (
                  <>
                    <h4 className="profile__subtitle">
                      {props.city
                        ? `${props.city}, ${props.postal}, ${props.province}`
                        : ""}
                    </h4>
                    <h4 className="profile__subtitle">
                      {props.rate
                        ? `$${props.rate} per hour (${
                            props.negotiable === "true"
                              ? "Negotiable"
                              : "Non-negotiable"
                          })`
                        : ""}
                    </h4>
                    {props.uploads.resumeFile.url && userIsPremium ? (
                      <a
                        className="profile__subtitle profile__subtitle--link"
                        href={props.uploads.resumeFile.url}
                      >
                        Link to Resume
                      </a>
                    ) : (
                      <></>
                    )}
                    {/*
                  //No longer plans for marking a job complete
                  <h4 className='profile__subtitle'>Completed {completedJobs()} Jobs</h4>
                */}
                  </>
                ) : (
                  <>
                    <br></br>
                    <br></br>
                    <h4 className="profile__subtitle">Hired {props.hired()}</h4>
                  </>
                )}
              </div>
              <div className="profile__contactBox">
                {userProfile.job !== props.job &&
                props.page === "profile" &&
                userIsPremium ? (
                  <button className="profile__button">
                    <a
                      href={`mailto:${props.email}?subject=Let’s Make a Clean Connection `}
                    >
                      Contact Now
                    </a>
                  </button>
                ) : (
                  <></>
                )}
                {props.whimis === true ? (
                  <div className="profile__checkBox">
                    <img className="profile__icon" src={iconBadge} alt="" />
                    <h5 className="profile__validText">WHMIS Certified</h5>
                  </div>
                ) : (
                  <></>
                )}
                {props.policecheck === true ? (
                  <div
                    className={
                      props.job === "cleaner"
                        ? "profile__checkBox profile__checkBox--police"
                        : "profile__checkBox"
                    }
                  >
                    <img className="profile__icon" src={iconBadge} alt="" />
                    <h5 className="profile__validText">Police Check</h5>
                  </div>
                ) : (
                  <></>
                )}
                {props.insurance && props.job === "cleaner" ? (
                  <div className="profile__checkBox">
                    <img className="profile__icon" src={iconBadge} alt="" />
                    <h5 className="profile__validText">Insured</h5>
                  </div>
                ) : (
                  <></>
                )}
                {props.businessNumberVerified && props.job === "employer" ? (
                  <div className="profile__checkBox">
                    <img className="profile__icon" src={iconBadge} alt="" />
                    <h5 className="profile__validText">Business</h5>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {!props.user ? (
            <h3 className="profile__title">
              Please Log-in to see user profile
            </h3>
          ) : (
            <></>
          )}
          {props.user && props.page === "dashboard" ? (
            <>
              <div className="profile__detailBox">
                <h3 className="profile__title">
                  {userProfile.name}'s Dashboard
                </h3>
                {userProfile.job === "employer" ? (
                  <>
                    <p className="profile__freetext">
                      You have {userProfile.freePost ? "1" : "0"} free posts
                      available
                    </p>
                    <Link to="/payment" className="profile__link">
                      Go to Payment
                    </Link>
                    <br></br>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/nexus-9bd48.appspot.com/o/Sample%20Questions%20and%20Contracts%2FNew%2FCleanconnection%20Sample%20Interview%20Questions.pdf?alt=media&token=6c95bb5a-ab51-411f-ae2f-4192b3f67bad"
                      className="profile__link"
                    >
                      Basic interview Questions
                    </a>
                    <br></br>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/nexus-9bd48.appspot.com/o/Sample%20Questions%20and%20Contracts%2FNew%2FCleanconnection%20Sample%20Ref%20Check.pdf?alt=media&token=45a9743d-4e4f-412c-b469-c0d6439db0f7"
                      className="profile__link"
                    >
                      Reference Check Questions
                    </a>
                  </>
                ) : userProfile.job === "cleaner" ? (
                  <>
                    {" "}
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/nexus-9bd48.appspot.com/o/Sample%20Questions%20and%20Contracts%2FNew%2FCleanconnection%20Sample%20Agreement.pdf?alt=media&token=e9d77215-72fa-4334-b7dc-bfc558052743"
                      className="profile__link"
                    >
                      Sample Contract Form
                    </a>
                    <br></br>
                    {/*
              Buttons to toggle suspension
              TODO: Add modal to confirm suspension
              */}
                    {userProfile.suspended ? (
                      <a
                        onClick={() => setModalSuspension(true)}
                        className="profile__link"
                      >
                        Unsuspend my account
                      </a>
                    ) : (
                      <a
                        onClick={() => setModalSuspension(true)}
                        className="profile__link"
                      >
                        Suspend my account
                      </a>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="profile__contactBox--userDashboard">
                <Link to={`/profile/${props.user.uid}`}>
                  <button className="profile__button ">Profile</button>
                </Link>
              </div>
            </>
          ) : (
            <></>
          )}
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
export default Profile;
