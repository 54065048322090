import React from "react";
import { firestore } from "../firebase.js";
import ViewMyJobsCard from "./ViewMyJobsCard";
import "../Styles/Sass/ViewMyJobs.scss";

function ViewMyJobs(props) {
  const [userJobs, setUserJobs] = React.useState([]);
  const [isCleaner, setIsCleaner] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState(true);
  const [profileSetup, setProfileSetup] = React.useState(false);

  React.useEffect(() => {
    setPageNumber(1);
    getJobs();
  }, [props.user]);

  const getJobs = () => {
    if (props.user) {
      firestore
        .collection("users")
        .doc(props.user.uid)
        .get()
        .then((res) => {
          let userStatus = res.data().job;
          if (userStatus !== "cleaner") {
            setIsCleaner(false);
          }
          if (res.data().profileSetup) {
            setProfileSetup(true);
          }
          let jobs = res.data().jobs;
          setUserJobs(jobs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderMyJobs = () => {
    let currentPageJobs = [];
    for (let i = 6 * pageNumber - 6; i < 6 * pageNumber; i++) {
      if (userJobs[i]) {
        currentPageJobs.push(userJobs[i]);
      }
    }
    let renderedJobs = currentPageJobs.map((job) => {
      if (job.status !== "posted") {
        return (
          <ViewMyJobsCard
            key={job.jobId}
            jobId={job.jobId}
            status={job.status}
            user={props.user}
            getJobs={getJobs}
          />
        );
      } else {
        return <></>;
      }
    });
    if (userJobs.length === 0) {
      return (
        <li className="viewMyJobs__emptybox">
          <h4 className="viewMyJobs__emptyTitle">No Jobs To Show</h4>
        </li>
      );
    } else {
      return renderedJobs;
    }
  };

  return (
    <section className="viewMyJobs">
      {isCleaner === true && profileSetup ? (
        <>
          <ul className="viewMyJobs__joblist">
            <h3 className="viewMyJobs__title">My Jobs</h3>
            {renderMyJobs()}
          </ul>
          {userJobs.length > 0 ? (
            <div className="posts__pageBox">
              <button
                className="posts__pageLink posts__pageLink--left"
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                &lt; Prev
              </button>
              <div className="posts__displayPage">
                Page {pageNumber} of {Math.ceil(userJobs.length / 6)}
              </div>
              <button
                className="posts__pageLink posts__pageLink--right"
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === Math.ceil(userJobs.length / 6)}
              >
                Next &gt;
              </button>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </section>
  );
}

export default ViewMyJobs;
