import React from 'react';
import { firestore } from '../../firebase.js';
import ListingItem from '../ListingItem';
import '../../Styles/Sass/SimilarUser.scss';

function SimilarUser(props){
  const [similarUsersArray, setSimilarUsersArray] = React.useState([]);

  React.useEffect(() => {
    if (props.profile.city && props.page === 'cleaner') {
      getSimilarUsers();
    } else if (props.profile.city && props.page === 'employer') {
      getSimilarEmployers();
    } else if (props.profile.city && props.page === 'jobmodal') {
      getSimilarJobs();
    }
  }, [props.user, props.profile]);

  const getSimilarUsers = () => {
    let similarUsers = [];
    let profileCity = props.profile.city;
    let profileAreaOfWork = props.profile.areaOfWork;
    let profileEmail = props.profile.email;
    firestore.collection('users').where('city', '==', `${profileCity}`).get()
      .then(async res => {
        res.forEach(userData => {
          let userCard = userData.data();
          userCard.uid = userData.id;
          if (userData.data().email !== profileEmail && userData.data().job === 'cleaner') {
            similarUsers.push(userCard);
          };
        });
        similarUsers = similarUsers.filter(user => {
          if (user.areaOfWork) {
            return user.areaOfWork.length === profileAreaOfWork.length;
          }
          return false;
        });
        similarUsers = similarUsers.filter(user => {
          for (let i = 0; i < profileAreaOfWork.length; i++) {
            if (profileAreaOfWork[i] !== user.areaOfWork[i]) {
              return false;
            }
          }
          return true;
        });
        setSimilarUsersArray(similarUsers);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getSimilarJobs = () => {
    let similarJobs = [];
    let jobCity = props.profile.city;
    let jobAreaOfWork = props.profile.areaOfWork;
    firestore.collection('jobs').where('city', '==', `${jobCity}`).get()
      .then(res => {
        res.forEach(job => {
          let jobData = job.data();
          if (job.id !== props.profile.jobId && jobData.takenBy === 'open') {
            similarJobs.push(jobData);
          }
        });
        similarJobs = similarJobs.filter(job => {
          if (job.areaOfWork.length === jobAreaOfWork.length) {
            return true;
          } else {
            return false;
          }
        });
        similarJobs = similarJobs.filter(job => {
          for (let i = 0; i < jobAreaOfWork.length; i++) {
            if (jobAreaOfWork[i] !== job.areaOfWork[i]) {
              return false;
            }
          }
          return true;
        });
        setSimilarUsersArray(similarJobs);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const getSimilarEmployers = () => {
    let similarEmployers = [];
    let profileCity = props.profile.city;
    let profileEmail = props.profile.email;
    firestore.collection('users').where('city', '==', `${profileCity}`).get()
      .then(res => {
        res.forEach(userData => {
          let userCard = userData.data();
          userCard.uid = userData.id;
          if (userCard.email !== profileEmail && userCard.job === 'employer') {
            similarEmployers.push(userCard);
          };
        });
        setSimilarUsersArray(similarEmployers);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const renderSimilarUsers = () => {
    let renderedUsers = similarUsersArray.map(user => {
      return (
        <ListingItem
          user={props.user}
          page={props.page === 'jobmodal' ? 'jobmodal' : 'findCleaner'}
          postedBy={props.page === 'jobmodal' ? user.postedBy : user.uid }
          postedByName={props.page === 'jobmodal' ? user.postedByName : user.name }
          headline={user.headline}
          description={
            (props.page === 'cleaner' || props.page === 'jobmodal') ?
              user.description :
              'Visit Employers Profile'
          }
          rate={(props.page === 'cleaner' || props.page ==='jobmodal') ? user.rate : ''}
          city={user.city}
          postal={user.postal}
          province={user.province}
          onClick={
            props.page === 'jobmodal' ?
              () => props.setJobDetail(user) :
              () => console.log('')
          }
        />
      );
    });
    if (similarUsersArray.length > 2) {
      let randomNumbers = [];
      while (randomNumbers.length < 2) {
        let randomNumber = Math.floor(Math.random() * similarUsersArray.length);
        if (randomNumbers.indexOf(randomNumber) == -1) {
          randomNumbers.push(randomNumber);
        }
      }
      let randomUsers = [];
      randomNumbers.forEach(number => {
        randomUsers.push(renderedUsers[number]);
      });
      return randomUsers;
    } else if (similarUsersArray.length === 0) {
      return (
        <div className='similaruser__emptybox'>
          <h4 className='similaruser__emptytitle'>No Similar Users/Jobs</h4>
        </div>
      )
    } else {
      return renderedUsers;
    }
  }

  return(
    <section className={props.user ? 'similaruser' : 'similaruser--noauth'}>
    	{
    		props.user ?
    			<>
            {
              props.page !== 'jobmodal' ?
                <h2 className='similaruser__title'>Similar User Profiles</h2> :
                <></>
            }
			      <div className='similaruser__list'>
              {
                renderSimilarUsers()
              }
			      </div>
    			</> :
		      <></>
    	}
    </section>
  );
}
export default SimilarUser;