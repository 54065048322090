import React, { useContext } from "react";
import { firestore } from "../firebase.js";
import { UserContext } from "../Providers/UserProvider";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import AdminDashboard from "../Components/AdminDashboard";
import UserCard from "../Components/UserCard";
import UserReviews from "../Components/UserReviews";
import UserQuestions from "../Components/UserQuestions";
import UserReport from "../Components/UserReport";
import CleanerData from "../Components/CleanerData";
import AdminInit from "../Components/AdminInit";
import EmployerData from "../Components/EmployerData";
import JobData from "../Components/JobData.js";

import icnCleaner from "../Images/icnCleaner.png";
import icnEmployer from "../Images/icnEmployer.png";
import icnReport from "../Images/icnReport.png";
import icnQuestions from "../Images/icnQuestions.png";
import icnVerify from "../Images/icnVerify.png";
import icnJobs from "../Images/icnJobs.png";
import "../Styles/Sass/Admin.scss";

function Admin() {
  const [currentTab, setCurrentTab] = React.useState("Admin dashboard");
  const [allUsers, setUsers] = React.useState([]);
  const [jobFilters, setJobFilters] = React.useState("all");
  const [verificationFilters, setVerificationFilters] = React.useState("all");
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(5);
  React.useEffect(() => {
    setAllUsers();
  }, [jobFilters, verificationFilters]);
  const user = useContext(UserContext);

  const setAllUsers = () => {
    let query = firestore.collection("users").where("profileSetup", "==", true);
    if (jobFilters === "all" && verificationFilters === "all") {
      query = query;
    } else if (jobFilters !== "all" && verificationFilters === "all") {
      query = query.where("job", "==", `${jobFilters}`);
    }
    query
      .get()
      .then((users) => {
        const userContainer = [];
        users.docs.forEach((doc) => {
          if (verificationFilters != "all") {
            if (
              (doc.data().whimis === false &&
                doc.data().uploads.whimisFile !== "" &&
                doc.data().job === "cleaner") ||
              (doc.data().policecheck === false &&
                doc.data().uploads.policecheckFile !== "" &&
                doc.data().job === "employer") ||
              (doc.data().insurance === false &&
                doc.data().uploads?.insuranceFile !== "" &&
                doc.data().uploads?.insuranceFile) ||
              (doc.data().businessNumberVerified === false &&
                doc.data()?.businessNumber !== "" &&
                doc.data().job === "employer")
            )
              userContainer.push({
                user: doc.data(),
                userId: doc.id,
              });
          } else {
            userContainer.push({
              user: doc.data(),
              userId: doc.id,
            });
          }
        });
        setUsers(userContainer);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyUser = (userID, verificationKey, verified, job) => {
    firestore
      .collection("users")
      .doc(userID)
      .update({
        [verificationKey]: !verified,
      })
      .then((res) => {
        setAllUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterUsers = (filter) => {
    setJobFilters(filter);
    if (jobFilters !== "all") {
      let newList = allUsers.filter((user) => {
        return user.user.job === jobFilters;
      });
      setFilteredUsers(newList);
    }
  };

  const deleteUser = (userId) => {
    firestore
      .collection("users")
      .doc(userId)
      .get()
      .then((res) => {
        let userData = res.data();
      });
  };

  const deleteUserApplied = (userId, jobs) => {
    jobs.forEach((job) => {
      firestore
        .collection("jobs")
        .doc(job.jobId)
        .get()
        .then((res) => {
          let jobApplicants = res.data().applicants;
          jobApplicants.filter((applicant) => {
            return applicant.id !== userId;
          });
          firestore
            .collection("jobs")
            .doc(job.jobId)
            .update({
              applicants: jobApplicants,
            })
            .then((res) => {
              console.log("Applicants updated");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const renderUsers = (userGroup) => {
    let currentGroup = userGroup;
    let currentPageUsers = [];
    // if (jobFilters !== "all") {
    //   currentGroup = currentGroup.filter((user) => {
    //     return user.user.job === jobFilters;
    //   });
    // }
    // if (verificationFilters !== "all") {
    //   currentGroup = currentGroup.filter((user) => {
    //     if (user.user.job === "cleaner") {
    //       return user.user.whimis === false || user.user.policecheck === false;
    //     } else {
    //       return user.user.policecheck === false;
    //     }
    //   });
    // }
    for (
      let i = postsPerPage * pageNumber - postsPerPage;
      i < postsPerPage * pageNumber;
      i++
    ) {
      if (currentGroup[i]) {
        currentPageUsers.push(currentGroup[i]);
      }
    }
    const render = currentPageUsers.map((user) => {
      return (
        <UserCard
          key={user.userId}
          userId={user.userId}
          name={user.user.name}
          email={user.user.email}
          job={user.user.job}
          verified={user.user.verified}
          whimis={user.user.whimis}
          policecheck={user.user.policecheck}
          insurance={user.user.insurance}
          insuranceFileUrl={user.user.uploads?.insuranceFile?.url}
          businessNumber={user.user.businessNumber}
          businessNumberVerified={user.user.businessNumberVerified}
          verifyUser={verifyUser}
          uploads={user.user.uploads}
          resumeFileUrl={user.user.uploads?.resumeFile?.url}
          policeCheckFileUrl={user.user.uploads?.policecheckFile?.url}
          whmisFileUrl={user.user.uploads?.whimisFile?.url}
          setAllUsers={setAllUsers}
        />
      );
    });
    return render;
  };

  return (
    <>
      <Navbar />
      <section className="admin">
        <div className="admin__banner">
          <h3 className="admin__title">{currentTab}</h3>
        </div>
        {currentTab === "Admin dashboard" ? (
          <AdminDashboard user={user} setCurrentTab={setCurrentTab} />
        ) : (
          <div className="admin__selectsection">
            <div className="sidebar">
              <h4 className="sidebar__title">Admin dashboard</h4>
              <div
                className={
                  currentTab === "Cleaner data & info"
                    ? "selectcard selectcard--active selectcard--menu"
                    : "selectcard selectcard--menu"
                }
                onClick={() => setCurrentTab("Cleaner data & info")}
              >
                <p
                  className={
                    currentTab === "Cleaner data & info"
                      ? "selectcard__text selectcard__text--active"
                      : "selectcard__text"
                  }
                >
                  Cleaner data & information
                </p>
                <img className="selectcard__image" src={icnCleaner} />
              </div>
              <div
                className={
                  currentTab === "View questions"
                    ? "selectcard selectcard--active selectcard--menu"
                    : "selectcard selectcard--menu"
                }
                onClick={() => setCurrentTab("View questions")}
              >
                <p
                  className={
                    currentTab === "View questions"
                      ? "selectcard__text selectcard__text--active"
                      : "selectcard__text"
                  }
                >
                  View questions
                </p>
                <img className="selectcard__image" src={icnQuestions} />
              </div>
              <div
                className={
                  currentTab === "Employer data & info"
                    ? "selectcard selectcard--active selectcard--menu"
                    : "selectcard selectcard--menu"
                }
                onClick={() => setCurrentTab("Employer data & info")}
              >
                <p
                  className={
                    currentTab === "Employer data & info"
                      ? "selectcard__text selectcard__text--active"
                      : "selectcard__text"
                  }
                >
                  Employer data & information
                </p>
                <img className="selectcard__image" src={icnEmployer} />
              </div>
              <div
                className={
                  currentTab === "Verify users"
                    ? "selectcard selectcard--active selectcard--menu"
                    : "selectcard selectcard--menu"
                }
                onClick={() => setCurrentTab("Verify users")}
              >
                <p
                  className={
                    currentTab === "Verify users"
                      ? "selectcard__text selectcard__text--active"
                      : "selectcard__text"
                  }
                >
                  Verify Users
                </p>
                <img className="selectcard__image" src={icnVerify} />
              </div>
              <div
                className={
                  currentTab === "User report"
                    ? "selectcard selectcard--active selectcard--menu"
                    : "selectcard selectcard--menu"
                }
                onClick={() => setCurrentTab("User report")}
              >
                <p
                  className={
                    currentTab === "User report"
                      ? "selectcard__text selectcard__text--active"
                      : "selectcard__text"
                  }
                >
                  User report
                </p>
                <img className="selectcard__image" src={icnReport} />
              </div>
              <div
                className={
                  currentTab === "Admin Init"
                    ? "selectcard selectcard--active selectcard--menu"
                    : "selectcard selectcard--menu"
                }
                onClick={() => setCurrentTab("Admin Init")}
              >
                <p
                  className={
                    currentTab === "Admin Init"
                      ? "selectcard__text selectcard__text--active"
                      : "selectcard__text"
                  }
                >
                  Admin Init
                </p>
                <img className="selectcard__image" src={icnReport} />
              </div>
              <div
                className={
                  currentTab === "Job data"
                    ? "selectcard selectcard--active selectcard--menu"
                    : "selectcard selectcard--menu"
                }
                onClick={() => setCurrentTab("Job data")}
              >
                <p
                  className={
                    currentTab === "Job data"
                      ? "selectcard__text selectcard__text--active"
                      : "selectcard__text"
                  }
                >
                  Job data
                </p>
                <img className="selectcard__image" src={icnJobs} />
              </div>
            </div>
            {currentTab === "Cleaner data & info" ? (
              <CleanerData setCurrentTab={setCurrentTab} />
            ) : (
              <></>
            )}
            {currentTab === "Employer data & info" ? (
              <EmployerData setCurrentTab={setCurrentTab} />
            ) : (
              <></>
            )}
            {currentTab === "User report" ? (
              <UserReport setCurrentTab={setCurrentTab} />
            ) : (
              <></>
            )}
            {currentTab === "Admin Init" ? (
              <AdminInit setCurrentTab={setCurrentTab} />
            ) : (
              <></>
            )}
            {currentTab === "View questions" ? (
              <UserQuestions setCurrentTab={setCurrentTab} />
            ) : (
              <></>
            )}
            {currentTab === "Job data" ? (
              <JobData setCurrentTab={setCurrentTab} />
            ) : (
              <></>
            )}
            {currentTab === "Verify users" ? (
              <div className="verifyusers">
                <div className="adminnav">
                  <label className="adminnav__label" htmlFor="">
                    Dashboard view:
                  </label>
                  <select
                    className="adminnav__select"
                    onChange={(e) => setCurrentTab(e.target.value)}
                    name=""
                    id=""
                  >
                    <option value="Verify users">Verify users</option>
                    <option value="View questions">View questions</option>
                    <option value="User report">User report</option>
                    <option value="Cleaner data & info">
                      Cleaner data & info
                    </option>
                    <option value="Employer data & info">
                      Employer data & info
                    </option>
                  </select>
                </div>
                <div className="filterbox">
                  <div className="filterbox__subbox">
                    <label htmlFor="jobfilter" className="filterbox__label">
                      Job Filter:
                    </label>
                    <select
                      name="jobfilter"
                      onChange={(e) => {
                        setJobFilters(e.target.value);
                      }}
                      className="filterbox__filter"
                    >
                      <option value="all">All</option>
                      <option value="cleaner">Cleaners</option>
                      <option value="employer">Employers</option>
                    </select>
                  </div>
                  <div className="filterbox__subbox">
                    <label
                      htmlFor="verificationfilter"
                      className="filterbox__label"
                    >
                      Verification Filter:
                    </label>
                    <select
                      name="verificationfilter"
                      onChange={(e) => {
                        setVerificationFilters(e.target.value);
                      }}
                      className="filterbox__filter"
                    >
                      <option value="all">All</option>
                      <option value="verification">Needs Verification</option>
                    </select>
                  </div>
                </div>
                <ul className="admin__list">
                  {renderUsers(allUsers)}
                  {allUsers.length > 0 ? (
                    <div className="posts__pageBox">
                      <button
                        className="posts__pageLink posts__pageLink--left"
                        onClick={() => setPageNumber(pageNumber - 1)}
                        disabled={pageNumber === 1}
                      >
                        &lt; Prev
                      </button>
                      <div className="posts__displayPage">
                        Page {pageNumber} of{" "}
                        {Math.ceil(allUsers.length / postsPerPage)}
                      </div>
                      <button
                        className="posts__pageLink posts__pageLink--right"
                        onClick={() => setPageNumber(pageNumber + 1)}
                        disabled={
                          pageNumber ===
                          Math.ceil(allUsers.length / postsPerPage)
                        }
                      >
                        Next &gt;
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}

export default Admin;
