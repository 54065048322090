import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import "../Styles/Sass/TermsAndConditions.scss";

function Home() {
  return (
    <>
      <Navbar />
      <section className="tandc">
        <h2 className="tandc__title">Privacy Policy</h2>
        <p className="tandc__text">
          This Privacy Policy explains how Cleanconnection.ca collects, uses,
          discloses, stores, safeguards and retains your Personal Information.
          If you do not agree to the terms of this Privacy Policy, you may not
          use our website, services or products.
        </p>
        <p className="tandc__text">
          PLEASE READ THESE TERMS OF USE CAREFULLY. THEY CONTAIN IMPORTANT
          INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, AS
          WELL AS EXCLUSIONS AND LIMITATIONS ON LIABILITY.
        </p>
        <h3 className="tandc__subtitle">Overview</h3>
        <p className="tandc__text">
          Our primary goal in collecting any personal information is to protect
          all that use this site. Further to provide you with an efficient and
          customized service to advertise all the information provided by
          yourself to other members or visitors of the web site
          Cleanconnection.ca in a clear safe manner. As part of the normal
          operational procedures we collect and, in some cases, may disclose
          information about you for the comfort of our users. By accepting this
          Privacy Policy and our Terms of Services, you expressly consent to our
          use and disclosure of your personal information in the manner
          described in this Privacy Policy. This Privacy Policy is incorporated
          into and subject to the terms of the Cleanconnection.ca Terms of
          Services.
        </p>
        <h3 className="tandc__subtitle">The Information We Collect:</h3>
        <p className="tandc__text">
          Contact and identification information: your name, address, postal
          code, telephone number and e-mail address. Your personal information:
          marriage status, date of birth, gender, nationality, religion, your
          pictures, education, interests and hobbies and other details related
          to your personal skills. Information regarding your previous work
          experience, your job preferences, references from previous employer's
          and information regarding your recruitment needs. Relationship
          information: information related to your preferences, service needs
          and decision-making, customer service inquiries and feed-back, and
          other information requested or provided by you. Communication
          information messages and chat with other members of the web site.
          Technological information about the computer you use to access our
          website: your IP address, information on your browser, and which pages
          you view. Our website also uses "cookies" (small data files placed on
          your computer by our website that allows the site to identify you and
          track your visit). We use third party company products to analyze
          traffic to the website and to count the number of users that visit a
          page. These products may use "cookies". We use a third-party
          advertising company to serve advertisements on our behalf across the
          Internet. That advertising company may also collect anonymous
          information about your visits to our websites and provide targeted
          advertising in the <span className="tnc__bold">Contact Us</span>.
          Cleanconnection.ca will make every effort to respond quickly to all
          inquiries related to any management issues, or the collection and
          disclosure of any personal information. If you have any questions,
          concerns or complaints in relation to the policy or practices of
          Cleanconnection.ca in reference specifically to privacy issues, please
          contact our Privacy Officer by e-mail at privacy@Cleanconnection.ca
        </p>
      </section>
      <Footer />
    </>
  );
}

export default Home;
