import { firestore } from "../firebase.js";
import React from "react";
import DataCard from "./DataCard";
import iconSearch from "../Images/iconSearch.svg";
import "../Styles/Sass/EmployerData.scss";

function EmployerData(props) {
  const [employerData, setEmployerData] = React.useState([]);
  const [jobData, setJobData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("newest");
  const [searchValue, setSearchValue] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(5);

  React.useEffect(() => {
    getJobPostings();
    getEmployerData();
  }, [sortBy, searchValue]);

  const getEmployerData = () => {
    firestore
      .collection("users")
      .get()
      .then((res) => {
        let allEmployers = [];
        res.forEach((doc) => {
          let userDetail = doc.data();
          let joinDate = new Date(userDetail.joined).toString().split(" ");
          userDetail.id = doc.id;
          userDetail.joinDate = `${joinDate[1]} ${joinDate[2]} ${joinDate[3]}`;
          if (doc.data().job === "employer") {
            if (searchValue === "") {
              allEmployers.push(userDetail);
            } else if (
              searchValue !== "" &&
              (userDetail.email
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
                userDetail.name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()))
            ) {
              allEmployers.push(userDetail);
            }
          }
        });
        if (sortBy === "newest") {
          allEmployers = allEmployers.sort((a, b) => {
            return b.joined - a.joined;
          });
        } else if (sortBy === "oldest") {
          allEmployers = allEmployers.sort((a, b) => {
            return a.joined - b.joined;
          });
        }
        setEmployerData(allEmployers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobPostings = () => {
    firestore
      .collection("jobs")
      .get()
      .then((res) => {
        let allJobs = [];
        res.forEach((doc) => {
          allJobs.push(doc.data());
        });
        setJobData(allJobs);
      });
  };

  const renderEmployers = () => {
    let renderedEmployers = employerData.map((employer) => {
      return (
        <DataCard
          name={employer.name}
          email={employer.email}
          endorsements={employer.endorsements}
          suspended={employer.suspended}
          memberSince={employer.joined}
          policecheck={employer.policecheck}
          id={employer.id}
          getData={getEmployerData}
          job="employer"
          joinDate={employer.joinDate}
          jobs={jobData}
          getJobPostings={getJobPostings}
          getEmployerData={getEmployerData}
        />
      );
    });
    return renderedEmployers;
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchValue(e.target.search.value);
  };

  return (
    <section className="cleanerdata">
      <div className="adminnav">
        <label className="adminnav__label" htmlFor="">
          Dashboard view:
        </label>
        <select
          className="adminnav__select"
          onChange={(e) => props.setCurrentTab(e.target.value)}
          name=""
          id=""
        >
          <option value="Employer data & info">Employer data & info</option>
          <option value="View questions">View questions</option>
          <option value="User report">User report</option>
          <option value="Cleaner data & info">Cleaner data & info</option>
          <option value="Verify users">Verify users</option>
        </select>
      </div>
      <div className="cleanerdata__titlebox">
        <h2 className="cleanerdata__title">
          {employerData.length} employers found
        </h2>
        <div className="cleanerdata__sortbox">
          <label className="cleanerdata__label" htmlFor="">
            Sort by:
          </label>
          <select
            className="cleanerdata__select"
            onChange={(e) => setSortBy(e.target.value)}
            name=""
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
      </div>
      <form
        className="cleanerdata__searchbox"
        onSubmit={(e) => searchHandler(e)}
      >
        <input
          className="cleanerdata__input"
          name="search"
          type="text"
          placeholder="Search by name or email"
        />
        <button className="cleanerdata__search" type="submit">
          <img className="cleanerdata__icon" src={iconSearch} alt="" />
        </button>
      </form>
      {renderEmployers()}
    </section>
  );
}

export default EmployerData;
