import React from "react";
import Modal from "react-modal";
import { firestore } from "../firebase.js";
import UserListingCard from "./UserListingCard.js";
import "../Styles/Sass/ConfirmDeleteModal.scss";

function ConfirmJobSuspensionModal(props) {
  const confirmSuspension = () => {
    props.clickFunction();
    props.closeModal();
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="confirmmodal"
      overlayClassName="confirmmodal__overlay"
      contentLabel="Example Modal"
    >
      <div className="confirmmodal__contentbox">
        {!props.suspensionStatus ? (
          <p className="confirmmodal__text">
            Are you sure you want to suspend this job?
          </p>
        ) : (
          <p className="confirmmodal__text">
            Are you sure you want to unsuspend this job?
          </p>
        )}

        <button
          className="confirmmodal__button confirmmodal__button--delete"
          onClick={confirmSuspension}
        >
          {!props.suspensionStatus ? "Suspend" : "Unsuspend"}
        </button>
        <button className="confirmmodal__button" onClick={props.closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default ConfirmJobSuspensionModal;
