import React from "react";
import { v4 as uuidv4 } from "uuid";
import { firestore } from "../firebase.js";
import { Redirect } from "react-router-dom";
import usePremiumStatus from "../stripe/usePremiumStatus";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "../Styles/Sass/CreateJobForm.scss";
import { useContext, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
function CreateJobForm(props) {
  const [city, setCity] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [postal, setPostal] = React.useState("");
  const [postalTwo, setPostalTwo] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [latLng, setLatLng] = React.useState({});
  const [redirectTo, setRedirectTo] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const userIsPremium = usePremiumStatus(props.user);
  const [alertText, setAlertText] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [congratulationOpen, setCongratulationOpen] = useState(false);
  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }
  function yesCongratulationAlert() {
    window.location.reload();
    setCongratulationOpen(false);
  }
  function noCongratulationAlert() {
    setCongratulationOpen(false);
  }
  function openCongratulationAlert() {
    setCongratulationOpen(true);
  }
  //Limit search results to within CANADA and regions (for postal and province)
  const searchOptionsTwo = {
    componentRestrictions: { country: "CA" },
    types: ["(regions)"],
  };

  const handleSelectTwo = async (value) => {
    const results = await geocodeByAddress(value);
    const resultLatLng = await getLatLng(results[0]);
    if (
      results[0].types.includes("postal_code") &&
      results[0].types.length === 1
    ) {
      setPostal(
        value.split(",")[1].split(" ")[2] +
          " " +
          value.split(",")[1].split(" ")[3]
      );
      setPostalTwo(
        value.split(",")[1].split(" ")[2] +
          " " +
          value.split(",")[1].split(" ")[3]
      );
      setCity(value.split(",")[0]);
      setProvince(value.split(",")[1].split(" ")[1]);
      setPlaceId(results[0].place_id);
      setLatLng(resultLatLng);
    } else {
      setPostal("");
      setPostalTwo("");
      setCity("");
      setProvince("");
      setAlertText("Please enter valid full postal code");
      openAlert();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!props.userProfile.freePost && !userIsPremium) {
      setAlertText("Please subscribe for additional job postings!");
      openAlert();
      setRedirectTo(true);
      return "";
    }
    const jobId = uuidv4();
    if (!e.target.rate.value || e.target.rate.value < 16) {
      setAlertText("Minimum for rate is 16");
      openAlert();
      return "";
    }
    if (
      !e.target.numberOfCleaners.value ||
      e.target.numberOfCleaners.value < 1
    ) {
      setAlertText("Please provide valid number of cleaners");
      openAlert();
      return "";
    }
    if (!e.target.headline.value) {
      setAlertText("Please provide valid headline");
      openAlert();
      return "";
    }
    if (!e.target.description.value) {
      setAlertText("Please provide valid description");
      openAlert();
      return "";
    }
    setButtonLoading(true);
    let jobTimeStamp = Date.now();
    //Area of Work
    let areaOfWork = [];
    e.target.home.checked && areaOfWork.push("home");
    e.target.commercial.checked && areaOfWork.push("commercial");
    e.target.restaurant.checked && areaOfWork.push("restaurant");
    e.target.industrial.checked && areaOfWork.push("industrial");
    e.target.project.checked && areaOfWork.push("project");
    e.target.events.checked && areaOfWork.push("events");
    e.target.specialty.checked && areaOfWork.push("specialty");
    e.target.airbnb.checked && areaOfWork.push("airbnb");
    e.target.other.checked && areaOfWork.push("other");
    if (areaOfWork.length === 0) {
      setAlertText("Please select at least one area of work");
      openAlert();
      return "";
    }
    //Services
    let serviceArray = [];
    e.target.offices.checked && serviceArray.push("offices");
    e.target.washrooms.checked && serviceArray.push("washrooms");
    e.target.kitchen.checked && serviceArray.push("kitchen");
    e.target.cafeteria.checked && serviceArray.push("cafeteria");
    e.target.carpet.checked && serviceArray.push("carpet");
    e.target.floorCare.checked && serviceArray.push("floorCare");
    e.target.window.checked && serviceArray.push("window");
    e.target.serviceOther.checked && serviceArray.push("other");
    if (serviceArray.length === 0) {
      setAlertText("Please select at least one service");
      openAlert();
      return "";
    }
    //Availability
    let availabilityArray = [];
    e.target.mornings.checked && availabilityArray.push("mornings");
    e.target.evenings.checked && availabilityArray.push("evenings");
    e.target.days.checked && availabilityArray.push("days");
    e.target.nights.checked && availabilityArray.push("nights");
    e.target.afternoons.checked && availabilityArray.push("afternoons");
    e.target.weekends.checked && availabilityArray.push("weekends");
    if (availabilityArray.length === 0) {
      setAlertText("Please select at least one availability");
      openAlert();
      return "";
    }
    let userName = "";
    let userEmail = "";
    await firestore
      .collection("users")
      .doc(props.user.uid)
      .get()
      .then((res) => {
        userName = res.data().name;
        userEmail = res.data().email;
      })
      .catch((err) => {
        console.log(err);
      });
    const jobPosting = {
      jobId: jobId,
      applicants: [],
      takenBy: "open",
      postedBy: props.user.uid,
      postedByName: userName,
      postedByEmail: userEmail,
      province: e.target.province.value,
      city: e.target.city.value,
      postal: e.target.postalcode.value,
      areaOfWork: areaOfWork,
      requiredServices: serviceArray,
      rate: e.target.rate.value,
      negotiable: e.target.negotiable.value,
      numberOfCleaners: e.target.numberOfCleaners.value,
      headline: e.target.headline.value,
      description: e.target.description.value,
      suppliesProvided: e.target.supplies.value,
      equipmentProvided: e.target.equipment.value,
      availability: availabilityArray,
      placeId: placeId,
      latLng: latLng,
      timestamp: jobTimeStamp,
      jobType: e.target.jobType.value,
      suspended: false,
    };
    firestore
      .collection("jobs")
      .doc(jobId)
      .set(jobPosting)
      .then((res) => {
        firestore
          .collection("users")
          .doc(props.user.uid)
          .get()
          .then((res) => {
            let userJobs = res.data().jobs;
            let freePostStatus = res.data().freePost;
            userJobs.push({
              jobId: jobId,
              status: "posted",
            });
            if (!userIsPremium) {
              freePostStatus = false;
            }
            firestore
              .collection("users")
              .doc(props.user.uid)
              .update({
                jobs: userJobs,
                freePost: freePostStatus,
              })
              .then((res) => {
                e.target.reset();
                openCongratulationAlert();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function setTwoNumberDecimal(el) {
    el.value = parseFloat(el.value).toFixed(2);
  }
  return (
    <>
      <form className="createjob" onSubmit={submitHandler}>
        <Modal
          isOpen={alertOpen}
          className="alertmodal"
          onRequestClose={closeAlert}
          overlayClassName="alertmodal__overlay"
          contentLabel="Error Modal"
        >
          <div className="alertmodal__contentbox">
            <h4>{alertText}</h4>
            <button onClick={closeAlert} className={"alertmodal__button"}>
              Close
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={congratulationOpen}
          className="alertmodal"
          onRequestClose={yesCongratulationAlert}
          overlayClassName="alertmodal__overlay"
          contentLabel="Congratulation Modal"
        >
          <div className="alertmodal__contentbox">
            <h4>Congratulations your post is live</h4>
            <h5>Would you like to make another post?</h5>
            <div className="">
              <Link to={"/submitjob"}>
                <button
                  onClick={yesCongratulationAlert}
                  className={"alertmodal__button"}
                >
                  Yes
                </button>
              </Link>
              <Link to={"/dashboard"}>
                <button className={"alertmodal__button"}>No</button>
              </Link>
            </div>
          </div>
        </Modal>
        <h2 className="createjob__title">
          Job Details & Requirements{" "}
          <span className="createjob__label">- Job postings last 30 days.</span>
        </h2>

        {/*Location Section*/}
        <h3 className="createjob__locationtitle">
          Where do you need a cleaner? (Enter Postal Code)
        </h3>
        <label className="createjob__label">
          Please Search for Postal Code*
        </label>
        <PlacesAutocomplete
          value={postal}
          onChange={setPostal}
          onSelect={handleSelectTwo}
          searchOptions={searchOptionsTwo}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({ className: "createjob__textinput" })}
              />
              <div>
                {loading ? <div>...loading</div> : null}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "createjob__textinput createjob__textinput--active"
                    : "createjob__textinput";
                  return (
                    <div
                      key={suggestion.placeId}
                      {...getSuggestionItemProps(suggestion, { className })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <label className="createjob__label" htmlFor="city">
          City
        </label>
        <input
          className="createjob__textinput"
          type="text"
          value={city}
          disabled={true}
          name="city"
        />
        <label className="createjob__label" htmlFor="postalcode">
          Postal Code
        </label>
        <input
          className="createjob__textinput"
          type="text"
          value={postalTwo}
          disabled={true}
          name="postalcode"
        />
        <label className="createjob__label" htmlFor="province">
          Province
        </label>
        <input
          className="createjob__textinput"
          type="text"
          value={province}
          disabled={true}
          name="province"
        />
        {/*Area of Work Section*/}
        <h3 className="createjob__subtitle">
          Which areas do you need cleaned?
        </h3>
        <p className="createjob__desc">Select all that apply.</p>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="home"
          />
          <label className="createjob__checklabel" htmlFor="home">
            Home
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="commercial"
          />
          <label className="createjob__checklabel" htmlFor="commercial">
            Commercial
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="restaurant"
          />
          <label className="createjob__checklabel" htmlFor="restaurant">
            Restaurant
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="industrial"
          />
          <label className="createjob__checklabel" htmlFor="industrial">
            Industrial
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="project"
          />
          <label className="createjob__checklabel" htmlFor="project">
            Project
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="events"
          />
          <label className="createjob__checklabel" htmlFor="events">
            Events
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="specialty"
          />
          <label className="createjob__checklabel" htmlFor="specialty">
            Specialty (Winow Cleaning etc.)
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="airbnb"
          />
          <label className="createjob__checklabel" htmlFor="airbnb">
            Air BNB
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="other"
          />
          <label className="createjob__checklabel" htmlFor="other">
            Other (Please describe in description)
          </label>
        </div>
        {/*Services Section*/}
        <h3 className="createjob__subtitle">Which services do you need?</h3>
        <p className="createjob__desc">Select all that apply.</p>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="offices"
          />
          <label className="createjob__checklabel" htmlFor="offices">
            Offices
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="washrooms"
          />
          <label className="createjob__checklabel" htmlFor="washrooms">
            Washrooms
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="kitchen"
          />
          <label className="createjob__checklabel" htmlFor="kitchen">
            Kitchen
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="cafeteria"
          />
          <label className="createjob__checklabel" htmlFor="cafeteria">
            Cafeteria
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="carpet"
          />
          <label className="createjob__checklabel" htmlFor="carpet">
            Carpet
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="floorCare"
          />
          <label className="createjob__checklabel" htmlFor="floorCare">
            Floor Care
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="window"
          />
          <label className="createjob__checklabel" htmlFor="window">
            Window
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="serviceOther"
          />
          <label className="createjob__checklabel" htmlFor="serviceOther">
            Other (Please describe in description)
          </label>
        </div>
        {/*Rate Section*/}
        <h3 className="createjob__subtitle createjob__label--title">
          What is the rate of pay for this job?
        </h3>
        <label className="createjob__label" htmlFor="rate">
          Rates under Provincial minimums are not supported.
        </label>
        {/* <input
          className="createjob__textinput"
          name="rate"
          type="number"
          defaultValue={16}
          min={16}
        /> */}
        {/* Old Rate Design on Job form */}
        <div className="createjob__ratebox">
          <input
            className="createjob__textinput createjob__textinput--rate"
            name="rate"
            type="number"
            step={0.01}
            min={16}
            defaultValue="22.00"
          />
          {/* <div className="createjob__rate">
            <p className="createjob__rateunit">.00</p>
          </div> */}
        </div>
        {/*Number of People Section*/}
        <label className="createjob__label" htmlFor="numberOfCleaners">
          How many cleaners do you require?
        </label>
        <select className="createjob__select" name="numberOfCleaners">
          <option value={1}>1 Cleaner</option>
          <option value={2}>2 Cleaners</option>
          <option value={3}>3 Cleaners</option>
          <option value={4}>4 Cleaners</option>
          <option value={5}>5+ Cleaners</option>
        </select>
        <label className="createjob__label" htmlFor="jobType">
          Please select the type of job.
        </label>
        <select className="createjob__select" name="jobType">
          <option value={"fullTime"}>Full Time</option>
          <option value={"partTime"}>Part Time</option>
          <option value={"permanent"}>Permanent</option>
          <option value={"other"}>Other</option>
        </select>
        {/*<input className='createjob__textinput' name='numberOfCleaners' type="number" />*/}
        <h4 className="createjob__subtitle createjob__subtitle--small">
          Is your rate negotiable?
        </h4>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="negotiable"
            value={true}
            defaultChecked
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            Negotiable
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="negotiable"
            value={false}
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            Non-negotiable
          </label>
        </div>
        {/*Description Section*/}
        <h3 className="createjob__subtitle">
          What should cleaners know about the job?
        </h3>
        <label className="createjob__label" htmlFor="headline">
          Headline
        </label>
        <input className="createjob__textinput" name="headline" type="text" />
        <label className="createjob__label" htmlFor="description">
          Description of Services Required
        </label>
        <textarea
          className="createjob__textinput createjob__textinput--area"
          name="description"
        ></textarea>
        {/*Supplies Section*/}
        <h4 className="createjob__subtitle createjob__subtitle--small">
          Do you provide cleaning supplies?
        </h4>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="supplies"
            value={true}
            defaultChecked
            required
          />
          <label className="createjob__checklabel" htmlFor="supplies">
            I provide cleaning supplies
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="supplies"
            value={false}
            required
          />
          <label className="createjob__checklabel" htmlFor="supplies">
            I do not provide cleaning supplies
          </label>
        </div>
        {/*Supplies Section*/}
        <h4 className="createjob__subtitle createjob__subtitle--small">
          Do you provide equipment?
        </h4>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="equipment"
            value={true}
            defaultChecked
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            I provide cleaning equipment
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="equipment"
            value={false}
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            I do not provide cleaning equipment
          </label>
        </div>
        {/*Availability Section*/}
        <h4 className="createjob__subtitle createjob__subtitle--small">
          What availability do you require?
        </h4>
        <p className="createjob__desc">Select all that apply</p>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="mornings"
          />
          <label className="createjob__checklabel" htmlFor="mornings">
            Mornings
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="evenings"
          />
          <label className="createjob__checklabel" htmlFor="evenings">
            Evenings
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="days"
          />
          <label className="createjob__checklabel" htmlFor="days">
            Days
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="nights"
          />
          <label className="createjob__checklabel" htmlFor="nights">
            Nights
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="afternoons"
          />
          <label className="createjob__checklabel" htmlFor="afternoons">
            Afternoons
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="weekends"
          />
          <label className="createjob__checklabel" htmlFor="weekends">
            Weekends
          </label>
        </div>
        <button
          className={
            !buttonLoading
              ? "createjob__button"
              : "createjob__button createjob__button__disabled"
          }
          type="submit"
        >
          SUBMIT{"   "}
          <ClipLoader
            loading={buttonLoading}
            color={"#ffffff"}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </button>
        {redirectTo === true ? <Redirect to="/payment" /> : <></>}
      </form>
    </>
  );
}

export default CreateJobForm;
