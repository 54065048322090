import React from "react";
import { firestore } from "../firebase.js";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import ConfirmJobSuspensionModal from "./ConfirmJobSuspensionModal";
import EditJobModal from "./EditJobModal";
import { Link } from "react-router-dom";
import "../Styles/Sass/UserListingCard.scss";
import ChatModal from "./ChatModal.js";
function UserListingCard(props) {
  const [takenByName, setTakenByName] = React.useState("open");
  const [takenBy, setTakenBy] = React.useState("open");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenTwo, setIsOpenTwo] = React.useState(false);
  const [modalSuspension, setModalSuspension] = React.useState(false);
  const [hasNewMessage, setHasNewMessage] = React.useState(false);
  const [chatModalIsOpen, setChatModalIsOpen] = React.useState(false);

  const toggleChatModal = () => {
    updatePostedByLastOpen();
    getNewMessage();
    setChatModalIsOpen(!chatModalIsOpen);
  };

  React.useEffect(() => {
    getUserName();
    getNewMessage();
  }, [props.job, props.takenBy, props.refreshPage]);

  function openModal(job) {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalTwo(job) {
    setIsOpenTwo(true);
  }

  function closeModalTwo() {
    setIsOpenTwo(false);
  }
  function openSuspensionModal(job) {
    setModalSuspension(true);
  }

  function closeModalSuspension() {
    setModalSuspension(false);
  }
  const getUserName = () => {
    if (props.takenBy !== "open") {
      firestore
        .collection("users")
        .doc(props.takenBy)
        .get()
        .then((res) => {
          let userName = res.data().name;
          setTakenByName(userName);
          setTakenBy(props.takenBy);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updatePostedByLastOpen = () => {
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .update({ postedByMessageLastOpen: Date.now() })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const getNewMessage = () => {
    if (props.takenBy) {
      firestore
        .collection("jobs")
        .doc(props.job.jobId)
        .collection("messages")
        .orderBy("timestamp", "desc")
        .limit(1)
        .onSnapshot((snapshot) => {
          const messagesData = snapshot.docs.map((doc) => doc.data());
          if (messagesData.length >= 1) {
            const newMessageTimeStamp = messagesData[0].timestamp;
            firestore
              .collection("jobs")
              .doc(props.job.jobId)
              .get()
              .then((res) => {
                let postedByMessageLastOpen =
                  res.data().postedByMessageLastOpen;
                setHasNewMessage(
                  newMessageTimeStamp > postedByMessageLastOpen &&
                    messagesData[0].sender !== props.user.uid
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    }
  };
  const cancelApplicant = () => {
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .update({
        takenBy: "open",
      })
      .then((res) => {
        firestore
          .collection("users")
          .doc(props.job.postedBy)
          .get()
          .then((res) => {
            let userJobs = res.data().jobs;
            let indexOfJob = userJobs.findIndex((job) => {
              return job.jobId === props.job.jobId;
            });
            userJobs[indexOfJob].status = "open";
            firestore
              .collection("users")
              .doc(props.job.postedBy)
              .update({
                jobs: userJobs,
              })
              .then((res) => {
                firestore
                  .collection("users")
                  .doc(props.takenBy)
                  .get()
                  .then((res) => {
                    let userJobs = res.data().jobs;
                    let indexOfJob = userJobs.findIndex((job) => {
                      return job.jobId === props.job.jobId;
                    });
                    userJobs[indexOfJob].status = "applied";
                    firestore
                      .collection("users")
                      .doc(props.takenBy)
                      .update({
                        jobs: userJobs,
                      })
                      .then((res) => {
                        console.log(res);
                        window.location.reload();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const suspendPosting = () => {
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .update({
        suspended: true,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const unsuspendPosting = () => {
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .update({
        suspended: false,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteJobPosting = () => {
    let jobApplicants = [];
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .get()
      .then((res) => {
        res.data().applicants.forEach((applicant) => {
          jobApplicants.push(applicant.id);
        });
        firestore
          .collection("jobs")
          .doc(props.job.jobId)
          .delete()
          .then(() => {
            console.log("Job deleted from job collection");
            firestore
              .collection("users")
              .doc(props.job.postedBy)
              .get()
              .then((res) => {
                let userJobs = res.data().jobs;
                let indexOfJob = userJobs.findIndex((job) => {
                  return job.jobId === props.job.jobId;
                });
                userJobs.splice(indexOfJob, 1);
                firestore
                  .collection("users")
                  .doc(props.job.postedBy)
                  .update({
                    jobs: userJobs,
                  })
                  .then((res) => {
                    console.log("Employer Job List Updated");
                    if (jobApplicants.length > 0) {
                      for (let i = 0; i < jobApplicants.length; i++) {
                        firestore
                          .collection("users")
                          .doc(jobApplicants[i])
                          .get()
                          .then((res) => {
                            let userJobs = res.data().jobs;
                            let indexOfJob = userJobs.findIndex((job) => {
                              return job.jobId === props.job.jobId;
                            });
                            userJobs.splice(indexOfJob, 1);
                            firestore
                              .collection("users")
                              .doc(jobApplicants[i])
                              .update({
                                jobs: userJobs,
                              })
                              .then((res) => {
                                console.log(
                                  "Applicant Job Listing Updated: ",
                                  jobApplicants[i]
                                );
                                if (i == jobApplicants.length - 1) {
                                  window.location.reload();
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }
                    } else {
                      window.location.reload();
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ChatModal
        modalIsOpen={chatModalIsOpen}
        closeModal={toggleChatModal}
        job={props.job}
        user={props.user}
      />
      <li className="jobCard">
        <ConfirmDeleteModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          clickFunction={deleteJobPosting}
        />
        <ConfirmJobSuspensionModal
          modalIsOpen={modalSuspension}
          closeModal={() => setModalSuspension(false)}
          clickFunction={
            props.job.suspended ? unsuspendPosting : suspendPosting
          }
          suspensionStatus={props.job.suspended}
        />
        <EditJobModal
          modalIsOpen={modalIsOpenTwo}
          closeModal={closeModalTwo}
          job={props.job}
          getJobs={props.getJobs}
        />
        <h3 className="jobCard__headline">{props.job.headline}</h3>
        <p className="jobCard__description">{props.job.description}</p>
        <h4 className="jobCard__subtitle">Status</h4>
        <p className="jobCard__status">
          {takenBy === "open" ? "Open" : `Cleaner Chosen: ${takenByName}`}
        </p>

        {takenBy !== "open" ? (
          <button className="jobCard__button" onClick={toggleChatModal}>
            Message Applicant{" "}
            {hasNewMessage ? (
              <span className="jobCard__newMessage">!</span>
            ) : (
              <></>
            )}
          </button>
        ) : (
          <></>
        )}

        <div className="jobCard__buttonContainer">
          {takenBy === "open" ? (
            <>
              <button
                className="jobCard__button"
                onClick={() => {
                  props.openNewApplicantModal(props.job);
                }}
              >
                View New Applicants
              </button>
              <button
                className="jobCard__button"
                onClick={() => {
                  props.openModal(props.job);
                }}
              >
                View Applicants
              </button>
            </>
          ) : (
            <button className="jobCard__button" onClick={cancelApplicant}>
              Cancel Applicant
            </button>
          )}

          {props.userIsPremium === true ? (
            <button
              className="jobCard__button"
              onClick={() => setIsOpenTwo(true)}
            >
              Edit Job
            </button>
          ) : (
            <></>
          )}
          {
            <button
              className="jobCard__button jobCard__button--delete"
              onClick={() => setModalSuspension(true)}
            >
              {props.job.suspended ? "Unsuspend Job" : "Suspend Job"}
            </button>
          }

          <button
            className="jobCard__button jobCard__button--delete"
            onClick={() => setIsOpen(true)}
          >
            Delete Job
          </button>
        </div>
        <div className="jobCard__subBox">
          <p className="jobCard__rate">${props.job.rate} per hour</p>
          <p className="jobCard__location">
            {props.job.city} | {props.job.postal} | {props.job.province}
          </p>
        </div>
      </li>
    </>
  );
}

export default UserListingCard;
