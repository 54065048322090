import heroAbout from "../../Images/About/heroAbout.svg";
import "../../Styles/Sass/OurStory.scss";

function OurStory() {
  return (
    <section className="ourStory">
      <img className="ourStory__hero" src={heroAbout} alt="" />
      <div className="ourStory__textBox">
        <h2 className="ourStory__title">Our Story</h2>
        <p className="ourStory__text">
          First impressions matter, let the experts handle it. Clean Connection
          is focused on connecting you to the best industry professional for
          your job
        </p>
      </div>
    </section>
  );
}

export default OurStory;
