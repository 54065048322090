import React from "react";
import Modal from "react-modal";
import { Redirect } from "react-router-dom";
import "../Styles/Sass/LoginModal.scss";
import { firestore, auth } from "../firebase.js";
import firebase from "firebase/compat/app";
import "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "../Styles/Sass/LoginModal.scss";
import Logo from "../Images/Home/CC_SubmarkLogo_Colour.svg";

function LoginModal(props) {
  const [userStatus, setUserStatus] = React.useState(false);
  const [adminStatus, setAdminStatus] = React.useState(false);
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (res) => {
        firestore
          .collection("users")
          .doc(`${res.user.uid}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("Existing User");
              setAdminStatus(doc.data().admin);
              setUserStatus(true);
            } else {
              let joinDate = Date.now();
              firestore
                .collection("users")
                .doc(res.user.uid)
                .set({
                  name: res.user.displayName,
                  email: res.user.email,
                  joined: joinDate,
                  verified: false,
                  policecheck: false,
                  whimis: false,
                  job: "",
                  jobs: [],
                  admin: false,
                  description: "",
                  headline: "",
                  rate: 0,
                  freePost: true,
                  endorsed: [],
                  reviews: [],
                  uploads: {
                    whimisFile: "",
                    policecheckFile: "",
                    resumeFile: "",
                    insuranceFile: "",
                  },
                  availability: [],
                  areaOfWork: [],
                  services: [],
                  equipment: "",
                  supplies: "",
                  province: "",
                  city: "",
                  postal: "",
                  latLng: {},
                  placeId: "",
                  profileSetup: false,
                  tos: false,
                  negotiable: "",
                  suspended: false,
                  insurance: false,
                  businessNumber: "",
                  businessNumber: false,
                  endorsements: [
                    {
                      name: "endorsement1",
                      up: 0,
                      down: 0,
                    },
                    {
                      name: "endorsement2",
                      up: 0,
                      down: 0,
                    },
                    {
                      name: "endorsement3",
                      up: 0,
                      down: 0,
                    },
                    {
                      name: "endorsement4",
                      up: 0,
                      down: 0,
                    },
                    {
                      name: "endorsement5",
                      up: 0,
                      down: 0,
                    },
                    {
                      name: "endorsement6",
                      up: 0,
                      down: 0,
                    },
                  ],
                })
                .then((docRef) => {
                  console.log("Document written with ID: ", res.user.uid);
                  setUserStatus(true);
                })
                .catch((error) => {
                  console.error("Error adding document: ", error);
                });
            }
          })
          .then(() => {
            //props.closeModal();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="loginmodal"
      overlayClassName="loginmodal__overlay"
    >
      <div className="loginmodal__contentbox">
        {userStatus === true && !adminStatus ? (
          <Redirect to="/dashboard" />
        ) : userStatus === true && adminStatus ? (
          <Redirect to="/admin" />
        ) : (
          <></>
        )}
        <img className="loginmodal__logo" src={Logo}></img>
        <h3 className="loginmodal__title">Sign In</h3>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        <button className="loginmodal__button" onClick={props.closeModal}>
          Close
        </button>
      </div>
    </Modal>
  );
}

export default LoginModal;
