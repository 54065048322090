import React from "react";
import { firestore } from "../../firebase.js";
import ListingItem from "../ListingItem";
import JobModal from "../JobModal";
import LoginModal from "../SignUpModal";
//import '../../Styles/Sass/Listings/listing.sass';
import "../../Styles/Sass/Listings/listing.scss";

function Posts(props) {
  const [posts, setPosts] = React.useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenTwo, setIsOpenTwo] = React.useState(false);
  const [jobDetail, setJobDetail] = React.useState({});
  const [pageNumber, setPageNumber] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(6);
  const [sort, setSort] = React.useState("");

  React.useEffect(() => {
    if (props.page === "findCleaner") {
      setSort("rate:low");
      getAllUsers();
    } else {
      setSort("date:recent");
      getAllJobs();
    }
    setPageNumber(1);
    if (props.page === "profile") {
      setPostsPerPage(3);
    }
  }, [
    props.jobFilter,
    props.user,
    props.triggerUpdate,
    props.profile,
    props.page,
  ]);

  const openModal = async (job) => {
    await setJobDetail(job);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const openModalTwo = async (job) => {
    setIsOpenTwo(true);
  };

  function closeModalTwo() {
    setIsOpenTwo(false);
  }

  const getAllJobs = () => {
    let allJobs = [];
    let currentTime = Date.now();
    firestore
      .collection("jobs")
      .where("takenBy", "==", "open")
      .where("suspended", "==", false)
      .get()
      .then((res) => {
        res.forEach((job) => {
          let secondsPassed = Math.floor(
            (currentTime - job.data().timestamp) / 1000
          );
          if (secondsPassed / 86400 < 30) {
            allJobs.push(job.data());
          }
        });
        if (props.page === "findJob") {
          if (props.jobFilter.city !== "") {
            allJobs = allJobs.filter((job) => {
              return job.city === props.jobFilter.city;
            });
          }
          if (props.jobFilter.province !== "") {
            allJobs = allJobs.filter((job) => {
              return job.province === props.jobFilter.province;
            });
          }
          if (props.jobFilter.placeId !== "") {
            allJobs = allJobs.filter((job) => {
              if (job.latLng) {
                let lon1 = (job.latLng.lng * Math.PI) / 180;
                let lon2 = (props.jobFilter.latLng.lng * Math.PI) / 180;
                let lat1 = (job.latLng.lat * Math.PI) / 180;
                let lat2 = (props.jobFilter.latLng.lat * Math.PI) / 180;
                let dlon = lon2 - lon1;
                let dlat = lat2 - lat1;
                let a =
                  Math.pow(Math.sin(dlat / 2), 2) +
                  Math.cos(lat1) *
                    Math.cos(lat2) *
                    Math.pow(Math.sin(dlon / 2), 2);
                let c = 2 * Math.asin(Math.sqrt(a));
                let r = 6371;
                if (c * r <= props.jobFilter.distance) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            });
          }
          props.jobFilter.areaOfWork.forEach((criteria) => {
            allJobs = allJobs.filter((job) => {
              return job.areaOfWork.includes(criteria);
            });
          });
          props.jobFilter.requiredServices.forEach((criteria) => {
            allJobs = allJobs.filter((job) => {
              return job.requiredServices.includes(criteria);
            });
          });
          props.jobFilter.availability.forEach((criteria) => {
            allJobs = allJobs.filter((job) => {
              return job.availability.includes(criteria);
            });
          });
          props.jobFilter.jobType.forEach((criteria) => {
            allJobs = allJobs.filter((job) => {
              return job.jobType.includes(criteria);
            });
          });
          if (
            props.jobFilter.numberOfWorkers !== 0 &&
            props.jobFilter.numberOfWorkers < 5
          ) {
            allJobs = allJobs.filter((job) => {
              return job.numberOfCleaners == props.jobFilter.numberOfWorkers;
            });
          } else {
            allJobs = allJobs.filter((job) => {
              return job.numberOfCleaners >= props.jobFilter.numberOfWorkers;
            });
          }
          if (props.jobFilter.supplies) {
            allJobs = allJobs.filter((job) => {
              return job.suppliesProvided === "true";
            });
          }
          if (props.jobFilter.equipment) {
            allJobs = allJobs.filter((job) => {
              return job.equipmentProvided === "true";
            });
          }
          if (props.jobFilter.negotiable) {
            allJobs = allJobs.filter((job) => {
              return job.negotiable === "true";
            });
          }
          if (props.jobFilter.rate < 150) {
            allJobs = allJobs.filter((job) => {
              return job.rate <= parseInt(props.jobFilter.rate);
            });
          }
          if (props.jobFilter.keyword !== "") {
            allJobs = allJobs.filter((job) => {
              if (
                job.areaOfWork.includes(props.jobFilter.keyword.toLowerCase())
              ) {
                return true;
              } else if (
                job.availability.includes(props.jobFilter.keyword.toLowerCase())
              ) {
                return true;
              } else if (
                job.jobType.includes(props.jobFilter.keyword.toLowerCase())
              ) {
                return true;
              } else if (
                job.requiredServices.includes(
                  props.jobFilter.keyword.toLowerCase()
                )
              ) {
                return true;
              } else if (
                job.description
                  .toLowerCase()
                  .includes(props.jobFilter.keyword.toLowerCase())
              ) {
                return true;
              } else if (
                job.headline
                  .toLowerCase()
                  .includes(props.jobFilter.keyword.toLowerCase())
              ) {
                return true;
              } else {
                return false;
              }
            });
          }
        } else if (props.page === "profile") {
          allJobs = allJobs.filter((job) => {
            return job.postedBy === props.profile;
          });
        }
        setPosts(allJobs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderListings = () => {
    let currentPosts = posts;
    if (sort === "date:old") {
      currentPosts.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });
    } else if (sort === "date:recent") {
      currentPosts.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
    } else if (sort === "rate:high") {
      currentPosts.sort((a, b) => {
        return b.rate - a.rate;
      });
    } else if (sort === "rate:low") {
      currentPosts.sort((a, b) => {
        return a.rate - b.rate;
      });
    }
    let currentPagePosts = [];
    for (
      let i = postsPerPage * pageNumber - postsPerPage;
      i < postsPerPage * pageNumber;
      i++
    ) {
      if (currentPosts[i]) {
        currentPagePosts.push(currentPosts[i]);
      }
    }
    if (posts.length === 0) {
      return (
        <div className="posts__emptybox">
          <h4 className="posts__emptytitle">No Listings</h4>
        </div>
      );
    } else {
      if (props.page === "findCleaner") {
        const render = currentPagePosts.map((post) => {
          //Suspended cleaner post are removed
          return (
            <ListingItem
              key={post.uid}
              id={post.uid}
              onClickTwo={openModalTwo}
              headline={post.headline}
              areaOfWork={post.areaOfWork}
              postedByName={post.name}
              postedBy={post.uid}
              description={post.description}
              rate={post.rate}
              province={post.province}
              city={post.city}
              postal={post.postal}
              page={props.page}
              user={props.user}
              negotiable={post.negotiable}
            />
          );
        });
        return render;
      } else {
        const render = currentPagePosts.map((post) => {
          return (
            <ListingItem
              key={post.jobId}
              onClick={() => openModal(post)}
              onClickTwo={openModalTwo}
              id={post.jobId}
              headline={post.headline}
              areaOfWork={post.areaOfWork}
              postedByName={post.postedByName}
              postedBy={post.postedBy}
              description={post.description}
              rate={post.rate}
              province={post.province}
              city={post.city}
              postal={post.postal}
              page={props.page}
              user={props.user}
              negotiable={post.negotiable}
            />
          );
        });
        return render;
      }
    }
  };

  const getAllUsers = () => {
    let allUsers = [];
    firestore
      .collection("users")
      .where("job", "==", "cleaner")
      .where("suspended", "==", false)
      .get()
      .then((res) => {
        res.forEach((user) => {
          let userData = user.data();
          userData.uid = user.id;
          if (userData.availability.length > 0) {
            allUsers.push(userData);
          }
        });
        if (props.jobFilter.city !== "") {
          allUsers = allUsers.filter((user) => {
            return user.city === props.jobFilter.city;
          });
        }
        if (props.jobFilter.province !== "") {
          allUsers = allUsers.filter((user) => {
            return user.province === props.jobFilter.province;
          });
        }
        if (props.jobFilter.placeId !== "") {
          allUsers = allUsers.filter((user) => {
            if (user.latLng) {
              let lon1 = (user.latLng.lng * Math.PI) / 180;
              let lon2 = (props.jobFilter.latLng.lng * Math.PI) / 180;
              let lat1 = (user.latLng.lat * Math.PI) / 180;
              let lat2 = (props.jobFilter.latLng.lat * Math.PI) / 180;
              let dlon = lon2 - lon1;
              let dlat = lat2 - lat1;
              let a =
                Math.pow(Math.sin(dlat / 2), 2) +
                Math.cos(lat1) *
                  Math.cos(lat2) *
                  Math.pow(Math.sin(dlon / 2), 2);
              let c = 2 * Math.asin(Math.sqrt(a));
              let r = 6371;
              if (c * r <= props.jobFilter.distance) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
        props.jobFilter.areaOfWork.forEach((criteria) => {
          allUsers = allUsers.filter((user) => {
            return user.areaOfWork.includes(criteria);
          });
        });
        props.jobFilter.requiredServices.forEach((criteria) => {
          allUsers = allUsers.filter((user) => {
            return user.services.includes(criteria);
          });
        });
        props.jobFilter.availability.forEach((criteria) => {
          allUsers = allUsers.filter((user) => {
            return user.availability.includes(criteria);
          });
        });
        if (props.jobFilter.supplies) {
          allUsers = allUsers.filter((user) => {
            return user.supplies === "true";
          });
        }
        if (props.jobFilter.equipment) {
          allUsers = allUsers.filter((user) => {
            return user.equipment === "true";
          });
        }
        if (props.jobFilter.negotiable) {
          allUsers = allUsers.filter((user) => {
            return user.negotiable === "true";
          });
        }
        if (props.jobFilter.rate < 150) {
          allUsers = allUsers.filter((user) => {
            return user.rate <= parseInt(props.jobFilter.rate);
          });
        }
        if (props.jobFilter.keyword !== "") {
          allUsers = allUsers.filter((user) => {
            if (
              user.areaOfWork.includes(props.jobFilter.keyword.toLowerCase())
            ) {
              return true;
            } else if (
              user.availability.includes(props.jobFilter.keyword.toLowerCase())
            ) {
              return true;
            } else if (
              user.services.includes(props.jobFilter.keyword.toLowerCase())
            ) {
              return true;
            } else if (
              user.description
                .toLowerCase()
                .includes(props.jobFilter.keyword.toLowerCase())
            ) {
              return true;
            } else if (
              user.headline
                .toLowerCase()
                .includes(props.jobFilter.keyword.toLowerCase())
            ) {
              return true;
            } else if (
              user.name
                .toLowerCase()
                .includes(props.jobFilter.keyword.toLowerCase())
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
        setPosts(allUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {!props.user && props.page === "profile" ? (
        <></>
      ) : (
        <ul
          className={
            props.page === "findJob" || props.page === "findCleaner"
              ? "posts"
              : "profilePosts"
          }
        >
          <JobModal
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            jobDetail={jobDetail}
            user={props.user}
            setJobDetail={setJobDetail}
          />
          <LoginModal modalIsOpen={modalIsOpenTwo} closeModal={closeModalTwo} />
          <div className="posts__titlebox">
            <>
              {props.page === "findJob" || props.page === "findCleaner" ? (
                <>
                  {props.jobFilter.keyword !== "" ? (
                    <h1 className="posts__title">
                      {posts.length} Total Listings
                    </h1>
                  ) : (
                    <h1 className="posts__title"></h1>
                  )}
                </>
              ) : (
                <>
                  <h1 className="posts__titleProfile">
                    {posts.length} Total Listings
                  </h1>
                </>
              )}
            </>
            {props.page === "findCleaner" || props.page === "findJob" ? (
              <div className="posts__sortbox">
                <label className="posts__sortlabel" htmlFor="sort">
                  Sort by:{" "}
                </label>
                <select
                  name="sort"
                  className="posts__sortBy"
                  onChange={(e) => setSort(e.target.value)}
                >
                  {props.page === "findJob" ? (
                    <>
                      <option value="date:recent">Date (Recent)</option>
                      <option value="date:old">Date (Old)</option>
                    </>
                  ) : (
                    <></>
                  )}
                  <option value="rate:low">Rate (Low)</option>
                  <option value="rate:high">Rate (High)</option>
                </select>
              </div>
            ) : (
              <></>
            )}
          </div>
          {renderListings()}
          {posts.length > 0 ? (
            <div className="posts__pageBox">
              <button
                className="posts__pageLink posts__pageLink--left"
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                &lt; Prev
              </button>
              <div className="posts__displayPage">
                Page {pageNumber} of {Math.ceil(posts.length / postsPerPage)}
              </div>
              <button
                className="posts__pageLink posts__pageLink--right"
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === Math.ceil(posts.length / postsPerPage)}
              >
                Next &gt;
              </button>
            </div>
          ) : (
            <></>
          )}
        </ul>
      )}
    </>
  );
}

export default Posts;
