import Footer from "../Components/Footer";
// import Navbar from "../Components/Navbar";
import HomeHero from "../Components/Home/HomeHero";
import HowItWorks from "../Components/Home/HowItWorks";
import LookingCleaner from "../Components/Home/LookingCleaner";
import Testimonials from "../Components/Testimonials";
import WhoWeAre from "../Components/Home/WhoWeAre";
import WhyConnect from "../Components/Home/WhyConnect";
import React from "react";

function Home(){

  return(
    <>
      <HomeHero/>
      <HowItWorks/>
      <Testimonials
        page={"home"}
        title={"What Others Are Saying"}
      />
      <WhoWeAre/>
      <WhyConnect/>
      <LookingCleaner/>
      <Footer/>
    </>
  );
}

export default Home;