import React from "react";
import { Link } from "react-router-dom";
import "../Styles/Sass/Listings/listing.scss";

function ListingItem(props) {
  const clickHandler = (e) => {
    e.preventDefault();
    props.onClickTwo();
  };

  const concatArray = (array) => {
    if (array) {
      let returnString = "";
      array.forEach((word) => {
        returnString = returnString.concat(
          `${word[0].toUpperCase() + word.slice(1)} `
        );
        returnString = returnString.replace(/[ ,]+/g, ", ");
      });
      returnString = returnString.slice(0, returnString.length - 2);
      returnString = (
        <p className="jobmodal__description jobmodal__description--array">
          {returnString}
        </p>
      );
      return returnString;
    }
  };

  return (
    <>
      {props.page === "findCleaner" ? (
        <Link
          onClick={
            props.user ? () => console.log("Logged in") : (e) => clickHandler(e)
          }
          to={`/profile/${props.postedBy}`}
          className="listingContainer__link"
        >
          <div className="listingContainer">
            <h2 className="listingName">{props.postedByName}</h2>
            <h3 className="listingJobTitle">{concatArray(props.areaOfWork)}</h3>
            <p className="listingJobDesc">{props.description}</p>
            <div className="listingSubBox">
              <h5 className="listingCost">
                ${props.rate} per hour (
                {props.negotiable === "true" ? "Negotiable" : "Non-negotiable"})
              </h5>
              <h5 className="listingSFL">
                {props.user
                  ? `${props.city} | ${props.postal} | ${props.province}`
                  : props.city}
              </h5>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className="listingContainer"
          onClick={props.user ? props.onClick : props.onClickTwo}
        >
          <h2 className="listingName">{props.postedByName}</h2>
          <h3 className="listingJobTitle">{concatArray(props.areaOfWork)}</h3>
          <p className="listingJobDesc">{props.description}</p>
          <div className="listingSubBox">
            <h5 className="listingCost">
              ${props.rate} per hour (
              {props.negotiable === "true" ? "Negotiable" : "Non-negotiable"})
            </h5>
            <h5 className="listingSFL">
              {props.user
                ? `${props.city} | ${props.postal} | ${props.province}`
                : props.city}
            </h5>
          </div>
        </div>
      )}
    </>
  );
}

export default ListingItem;
