import React, { useEffect, useState } from "react";
import { firestore } from "../firebase.js";
import "../Styles/Sass/Chat/Chat.scss";
import { useContext, useRef } from "react";
import { UserContext } from "../Providers/UserProvider.js";
import { MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
const Chat = (props) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [job, setJob] = useState({});
  const [firstMessage, setFirstMessage] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const unsubscribe = firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .collection("messages")
      .orderBy("timestamp")
      .onSnapshot((snapshot) => {
        const messagesData = snapshot.docs.map((doc) => doc.data());
        setMessages(messagesData);
        setFirstMessage(messagesData.length < 1);
      });
    return () => unsubscribe();
  }, [props.user, props.job.jobId]);

  const handleSendMessage = async () => {
    const message = {
      sender: props.user.uid,
      recipient:
        props.user.uid === props.job.postedBy
          ? props.job.takenBy
          : props.job.postedBy,
      text: newMessage,
      timestamp: Date.now(),
      firstMessage: firstMessage,
    };

    await firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .collection("messages")
      .add(message);
    setNewMessage("");
    scrollToBottom();
  };

  const suggestSendMessage = async (suggestedMessage) => {
    const message = {
      sender: props.user.uid,
      recipient:
        props.user.uid === props.job.postedBy
          ? props.job.takenBy
          : props.job.postedBy,
      text: suggestedMessage,
      timestamp: Date.now(),
      firstMessage: firstMessage,
    };

    await firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .collection("messages")
      .add(message);
    setNewMessage("");
    scrollToBottom();
  };

  return (
    <div className="chat">
      <div className="chat__box">
        {messages.map((message) => (
          <div
            className={
              message.sender === props.user.uid
                ? "chat__currentbox"
                : "chat__recipientbox"
            }
          >
            <MessageBox
              position={message.sender === props.user.uid ? "right" : "left"}
              // title={
              //   message.sender === props.user.uid
              //     ? props.user.displayName
              //     : props.job.postedByName
              // }
              type="text"
              text={message.text}
              date={message.timestamp}
              replyButton={false}
            />

            {/* <p
              className={
                message.sender === props.user.uid
                  ? "chat__currenttext"
                  : "chat__recipienttext"
              }
              key={message.timestamp}
            >
              {message.text}
            </p> */}
          </div>
        ))}
      </div>
      <div ref={messagesEndRef} />
      {messages.length < 1 ? (
        <div className="chat__sendsuggestion">
          <button
            className="chat__sendsuggestion--button"
            onClick={() =>
              suggestSendMessage("How soon would you be able to start?")
            }
          >
            How soon would you be able to start?
          </button>
          <button
            className="chat__sendsuggestion--button"
            onClick={() =>
              suggestSendMessage(
                "Can you provide us with more information about your skills?"
              )
            }
          >
            Can you provide us with more information about your skills?
          </button>
          <button
            className="chat__sendsuggestion--button"
            onClick={() =>
              suggestSendMessage(
                "When is the time best to contact you via phone?"
              )
            }
          >
            When is the time best to contact you via phone?
          </button>
        </div>
      ) : (
        <></>
      )}

      <div className="chat__sendbuttonbox">
        <input
          type="text"
          className="chat__sendinput"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message here ..."
        />
        <button className="chat__sendbutton" onClick={handleSendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
