import React from "react";
import { firestore } from "../firebase.js";
import ViewApplicantsModal from "./ViewApplicantsModal";
import ViewNewApplicantsModal from "./ViewNewApplicantsModal.js";
import AssignJobCard from "./AssignJobCard";
import "../Styles/Sass/AssignJob.scss";
import usePremiumStatus from "../stripe/usePremiumStatus";

function AssignJob(props) {
  const [userJobs, setUserJobs] = React.useState([]);
  const [isEmployer, setIsEmployer] = React.useState(true);
  const [profileSetup, setProfileSetup] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [newApplicantModalIsOpen, setNewApplicantModalIsOpen] =
    React.useState(false);
  const [modalJob, setModalJob] = React.useState({});
  const [pageNumber, setPageNumber] = React.useState(1);
  const userIsPremium = usePremiumStatus(props.user);

  React.useEffect(() => {
    getJobs();
    getUserJob();
    setPageNumber(1);
  }, [props.openFilter, props.user]);

  function openModal(job) {
    setIsOpen(true);
    setModalJob(job);
  }

  function openNewApplicantModal(job) {
    setNewApplicantModalIsOpen(true);
    updateApplicantsViewTimestamp(job);
    setModalJob(job);
  }
  function closeNewApplicantModal() {
    setNewApplicantModalIsOpen(false);
    setModalJob({});
  }
  function closeModal() {
    setIsOpen(false);
    setModalJob({});
  }

  const getUserJob = () => {
    if (props.user) {
      firestore
        .collection("users")
        .doc(props.user.uid)
        .get()
        .then((res) => {
          let userData = res.data();
          if (userData.job !== "employer") {
            setIsEmployer(false);
          }
          if (userData.profileSetup) {
            setProfileSetup(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const updateApplicantsViewTimestamp = (job) => {
    firestore
      .collection("jobs")
      .doc(job.jobId)
      .update({ applicantsViewTimestamp: Date.now() })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const getJobs = () => {
    if (props.user) {
      firestore
        .collection("jobs")
        .where("postedBy", "==", props.user.uid)
        .get()
        .then((res) => {
          let jobs = [];
          let currentTime = Date.now();
          res.forEach((job) => {
            let secondsPassed = Math.floor(
              (currentTime - job.data().timestamp) / 1000
            );
            if (props.openFilter === false && secondsPassed / 86400 < 30) {
              jobs.push(job.data());
            } else if (props.openFilter === true) {
              if (job.data().takenBy === "open" && secondsPassed / 86400 < 30) {
                jobs.push(job.data());
              }
            }
          });
          setUserJobs(jobs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderJobs = () => {
    let currentPageJobs = [];
    for (let i = 6 * pageNumber - 6; i < 6 * pageNumber; i++) {
      if (userJobs[i]) {
        currentPageJobs.push(userJobs[i]);
      }
    }
    let renderedJobs = currentPageJobs.map((job) => {
      return (
        <AssignJobCard
          key={job.jobId}
          job={job}
          takenBy={job.takenBy}
          openModal={openModal}
          openNewApplicantModal={openNewApplicantModal}
          getJobs={getJobs}
          user={props.user}
          userIsPremium={userIsPremium}
        />
      );
    });
    if (renderedJobs.length > 0) {
      return renderedJobs;
    } else {
      return (
        <div className="assignJob__emptybox">
          <h4 className="assignJob__emptytitle">No Jobs Posted</h4>
        </div>
      );
    }
  };

  return (
    <section className="assignJob">
      {isEmployer === true && profileSetup ? (
        <>
          <ViewApplicantsModal
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            job={modalJob}
            applicants={modalJob.applicants}
            user={props.user}
          />
          <ViewNewApplicantsModal
            modalIsOpen={newApplicantModalIsOpen}
            closeModal={closeNewApplicantModal}
            job={modalJob}
            applicants={modalJob.applicants}
            user={props.user}
          />
          <div className="assignJob__titlebox">
            <h2 className="assignJob__title">My Posted Jobs</h2>
            {userJobs.length > 0 ? (
              <div className="assignJob__buttonBox">
                <button
                  className={
                    props.openFilter === true
                      ? "assignJob__button assignJob__button--active"
                      : "assignJob__button"
                  }
                  onClick={() => props.setOpenFilter(true)}
                >
                  Only Show Open Jobs
                </button>
                <button
                  className={
                    props.openFilter === false
                      ? "assignJob__button assignJob__button--active"
                      : "assignJob__button"
                  }
                  onClick={() => props.setOpenFilter(false)}
                >
                  Show All Jobs
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <ul className="assignJob__list">{renderJobs()}</ul>
          {userJobs.length > 0 ? (
            <div className="posts__pageBox">
              <button
                className="posts__pageLink posts__pageLink--left"
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                &lt; Prev
              </button>
              <div className="posts__displayPage">
                Page {pageNumber} of {Math.ceil(userJobs.length / 6)}
              </div>
              <button
                className="posts__pageLink posts__pageLink--right"
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === Math.ceil(userJobs.length / 6)}
              >
                Next &gt;
              </button>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </section>
  );
}

export default AssignJob;
