import React from "react";
import Modal from "react-modal";
import { firestore } from "../firebase.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "../Styles/Sass/EditJobModal.scss";

function ViewApplicantsModal(props) {
  const [currentJob, setCurrentJob] = React.useState({});
  const [city, setCity] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [postal, setPostal] = React.useState("");
  const [postalTwo, setPostalTwo] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [latLng, setLatLng] = React.useState({});
  const [checkBoxStatus, setCheckBoxStatus] = React.useState({
    home: false,
    commercial: false,
    restaurant: false,
    industrial: false,
    project: false,
    events: false,
    specialty: false,
    airbnb: false,
    other: false,
    offices: false,
    washrooms: false,
    kitchen: false,
    cafeteria: false,
    carpet: false,
    floorCare: false,
    window: false,
    serviceOther: false,
    mornings: false,
    evenings: false,
    days: false,
    nights: false,
    afternoons: false,
    weekends: false,
  });
  const [alertText, setAlertText] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }
  React.useEffect(() => {
    getJobData();
  }, [props.job]);

  //Limit search results to within CANADA and regions (for postal and province)
  const searchOptionsTwo = {
    componentRestrictions: { country: "CA" },
    types: ["(regions)"],
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!e.target.headline.value) {
      setAlertText("Please provide valid headline");
      openAlert();
      return "";
    }
    if (!e.target.description.value) {
      setAlertText("Please provide valid description");
      openAlert();
      return "";
    }
    //Area of Work
    let areaOfWork = [];
    e.target.home.checked && areaOfWork.push("home");
    e.target.commercial.checked && areaOfWork.push("commercial");
    e.target.restaurant.checked && areaOfWork.push("restaurant");
    e.target.industrial.checked && areaOfWork.push("industrial");
    e.target.project.checked && areaOfWork.push("project");
    e.target.events.checked && areaOfWork.push("events");
    e.target.specialty.checked && areaOfWork.push("specialty");
    e.target.airbnb.checked && areaOfWork.push("airbnb");
    e.target.other.checked && areaOfWork.push("other");
    if (areaOfWork.length === 0) {
      setAlertText("Please select at least one area of work");
      openAlert();
      return "";
    }
    //Services
    let serviceArray = [];
    e.target.offices.checked && serviceArray.push("offices");
    e.target.washrooms.checked && serviceArray.push("washrooms");
    e.target.kitchen.checked && serviceArray.push("kitchen");
    e.target.cafeteria.checked && serviceArray.push("cafeteria");
    e.target.carpet.checked && serviceArray.push("carpet");
    e.target.floorCare.checked && serviceArray.push("floorCare");
    e.target.window.checked && serviceArray.push("window");
    e.target.serviceOther.checked && serviceArray.push("other");
    if (serviceArray.length === 0) {
      setAlertText("Please select at least one service");
      openAlert();
      return "";
    }
    //Availability
    let availabilityArray = [];
    e.target.mornings.checked && availabilityArray.push("mornings");
    e.target.evenings.checked && availabilityArray.push("evenings");
    e.target.days.checked && availabilityArray.push("days");
    e.target.nights.checked && availabilityArray.push("nights");
    e.target.afternoons.checked && availabilityArray.push("afternoons");
    e.target.weekends.checked && availabilityArray.push("weekends");
    if (availabilityArray.length === 0) {
      setAlertText("Please select at least one availability");
      openAlert();
      return "";
    }
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .update({
        province: e.target.province.value,
        city: e.target.city.value,
        postal: e.target.postalcode.value,
        areaOfWork: areaOfWork,
        requiredServices: serviceArray,
        headline: e.target.headline.value,
        description: e.target.description.value,
        negotiable: e.target.negotiable.value,
        suppliesProvided: e.target.supplies.value,
        equipmentProvided: e.target.equipment.value,
        availability: availabilityArray,
        placeId: placeId,
        latLng: latLng,
      })
      .then((res) => {
        console.log("SUCCESS");
        props.getJobs();
        props.closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobData = () => {
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .get()
      .then((res) => {
        let jobData = res.data();
        setCurrentJob(jobData);
        let checkBox = checkBoxStatus;
        setCity(jobData.province);
        setPostalTwo(jobData.postal);
        setProvince(jobData.city);
        setPlaceId(jobData.placeId);
        setLatLng(jobData.latLng);
        jobData.areaOfWork.includes("home")
          ? (checkBox.home = true)
          : (checkBox.home = false);
        jobData.areaOfWork.includes("commercial")
          ? (checkBox.commercial = true)
          : (checkBox.commercial = false);
        jobData.areaOfWork.includes("restaurant")
          ? (checkBox.restaurant = true)
          : (checkBox.restaurant = false);
        jobData.areaOfWork.includes("industrial")
          ? (checkBox.industrial = true)
          : (checkBox.industrial = false);
        jobData.areaOfWork.includes("project")
          ? (checkBox.project = true)
          : (checkBox.project = false);
        jobData.areaOfWork.includes("events")
          ? (checkBox.events = true)
          : (checkBox.events = false);
        jobData.areaOfWork.includes("specialty")
          ? (checkBox.specialty = true)
          : (checkBox.specialty = false);
        jobData.areaOfWork.includes("airbnb")
          ? (checkBox.airbnb = true)
          : (checkBox.airbnb = false);
        jobData.areaOfWork.includes("other")
          ? (checkBox.other = true)
          : (checkBox.other = false);
        jobData.requiredServices.includes("offices")
          ? (checkBox.offices = true)
          : (checkBox.offices = false);
        jobData.requiredServices.includes("washrooms")
          ? (checkBox.washrooms = true)
          : (checkBox.washrooms = false);
        jobData.requiredServices.includes("kitchen")
          ? (checkBox.kitchen = true)
          : (checkBox.kitchen = false);
        jobData.requiredServices.includes("cafeteria")
          ? (checkBox.cafeteria = true)
          : (checkBox.cafeteria = false);
        jobData.requiredServices.includes("carpet")
          ? (checkBox.carpet = true)
          : (checkBox.carpet = false);
        jobData.requiredServices.includes("floorCare")
          ? (checkBox.floorCare = true)
          : (checkBox.floorCare = false);
        jobData.requiredServices.includes("window")
          ? (checkBox.window = true)
          : (checkBox.window = false);
        jobData.requiredServices.includes("serviceOther")
          ? (checkBox.serviceOther = true)
          : (checkBox.serviceOther = false);
        jobData.availability.includes("mornings")
          ? (checkBox.mornings = true)
          : (checkBox.mornings = false);
        jobData.availability.includes("evenings")
          ? (checkBox.evenings = true)
          : (checkBox.evenings = false);
        jobData.availability.includes("days")
          ? (checkBox.days = true)
          : (checkBox.days = false);
        jobData.availability.includes("nights")
          ? (checkBox.nights = true)
          : (checkBox.nights = false);
        jobData.availability.includes("afternoons")
          ? (checkBox.afternoons = true)
          : (checkBox.afternoons = false);
        jobData.availability.includes("weekends")
          ? (checkBox.weekends = true)
          : (checkBox.weekends = false);
        setCheckBoxStatus(checkBox);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectTwo = async (value) => {
    const results = await geocodeByAddress(value);
    const resultLatLng = await getLatLng(results[0]);
    if (
      results[0].types.includes("postal_code") &&
      results[0].types.length === 1
    ) {
      setPostal(
        value.split(",")[1].split(" ")[2] +
          " " +
          value.split(",")[1].split(" ")[3]
      );
      setPostalTwo(
        value.split(",")[1].split(" ")[2] +
          " " +
          value.split(",")[1].split(" ")[3]
      );
      setCity(value.split(",")[0]);
      setProvince(value.split(",")[1].split(" ")[1]);
      setPlaceId(results[0].place_id);
      setLatLng(resultLatLng);
    } else {
      setPostal("");
      setPostalTwo("");
      setCity("");
      setProvince("");
      setLatLng({});
      setAlertText("Please enter valid full postal code");
      openAlert();
    }
  };

  const clickCheckBox = (value) => {
    let checkBox = checkBoxStatus;
    let valueStatus = checkBox[value];
    checkBox[value] = !valueStatus;
    setCheckBoxStatus(checkBox);
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="editjobmodal"
      overlayClassName="editjobmodal__overlay"
      contentLabel="Example Modal"
    >
      {/*Alert Modal */}
      <Modal
        isOpen={alertOpen}
        className="alertmodal"
        onRequestClose={closeAlert}
        overlayClassName="alertmodal__overlay"
        contentLabel="Free Post Modal"
      >
        <div className="alertmodal__contentbox">
          <h4>{alertText}</h4>
          <button onClick={closeAlert} className={"alertmodal__button"}>
            Close
          </button>
        </div>
      </Modal>
      <form className="createjob" onSubmit={submitHandler}>
        {/*Location Section*/}
        <h3 className="createjob__subtitle">
          Where do you need a cleaner? (Enter Postal Code)
        </h3>
        <label className="createjob__label">Please enter Postal Code*</label>
        <PlacesAutocomplete
          value={postal}
          onChange={setPostal}
          onSelect={handleSelectTwo}
          searchOptions={searchOptionsTwo}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({ className: "createjob__textinput" })}
              />
              <div>
                {loading ? <div>...loading</div> : null}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "createjob__textinput createjob__textinput--active"
                    : "createjob__textinput";
                  return (
                    <div {...getSuggestionItemProps(suggestion, { className })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <label className="createjob__label" htmlFor="city">
          City
        </label>
        <input
          className="createjob__textinput"
          type="text"
          value={city}
          disabled={true}
          name="city"
        />
        <label className="createjob__label" htmlFor="postalcode">
          Postal Code
        </label>
        <input
          className="createjob__textinput"
          type="text"
          value={postalTwo}
          disabled={true}
          name="postalcode"
        />
        <label className="createjob__label" htmlFor="province">
          Province
        </label>
        <input
          className="createjob__textinput"
          type="text"
          value={province}
          disabled={true}
          name="province"
        />
        {/*Area of Work Section*/}
        <h3 className="createjob__subtitle">
          Which area of work do you need cleaning?
        </h3>
        <p className="createjob__desc">Select all that apply.</p>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="home"
            defaultChecked={checkBoxStatus.home}
            onClick={() => clickCheckBox("home")}
          />
          <label className="createjob__checklabel" htmlFor="home">
            Home
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="commercial"
            defaultChecked={checkBoxStatus.commercial}
            onClick={() => clickCheckBox("commercial")}
          />
          <label className="createjob__checklabel" htmlFor="commercial">
            Commercial
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="restaurant"
            defaultChecked={checkBoxStatus.restaurant}
            onClick={() => clickCheckBox("restaurant")}
          />
          <label className="createjob__checklabel" htmlFor="restaurant">
            Restaurant
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="industrial"
            defaultChecked={checkBoxStatus.industrial}
            onClick={() => clickCheckBox("industrial")}
          />
          <label className="createjob__checklabel" htmlFor="industrial">
            Industrial
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="project"
            defaultChecked={checkBoxStatus.project}
            onClick={() => clickCheckBox("project")}
          />
          <label className="createjob__checklabel" htmlFor="project">
            Projects
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="events"
            defaultChecked={checkBoxStatus.events}
            onClick={() => clickCheckBox("events")}
          />
          <label className="createjob__checklabel" htmlFor="events">
            Events
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="specialty"
            defaultChecked={checkBoxStatus.specialty}
            onClick={() => clickCheckBox("specialty")}
          />
          <label className="createjob__checklabel" htmlFor="specialty">
            Specialty (Window Cleaning etc.)
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="airbnb"
            defaultChecked={checkBoxStatus.airbnb}
            onClick={() => clickCheckBox("airbnb")}
          />
          <label className="createjob__checklabel" htmlFor="airbnb">
            Air BNB
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="other"
            defaultChecked={checkBoxStatus.other}
            onClick={() => clickCheckBox("other")}
          />
          <label className="createjob__checklabel" htmlFor="other">
            Other (Please describe in description)
          </label>
        </div>
        {/*Services Section*/}
        <h3 className="createjob__subtitle">Which services do you need?</h3>
        <p className="createjob__desc">Select all that apply.</p>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="offices"
            defaultChecked={checkBoxStatus.offices}
            onClick={() => clickCheckBox("offices")}
          />
          <label className="createjob__checklabel" htmlFor="offices">
            Offices
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="washrooms"
            defaultChecked={checkBoxStatus.washrooms}
            onClick={() => clickCheckBox("washrooms")}
          />
          <label className="createjob__checklabel" htmlFor="washrooms">
            Washrooms
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="kitchen"
            defaultChecked={checkBoxStatus.kitchen}
            onClick={() => clickCheckBox("kitchen")}
          />
          <label className="createjob__checklabel" htmlFor="kitchen">
            Kitchen
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="cafeteria"
            defaultChecked={checkBoxStatus.cafeteria}
            onClick={() => clickCheckBox("cafeteria")}
          />
          <label className="createjob__checklabel" htmlFor="cafeteria">
            Cafeteria
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="carpet"
            defaultChecked={checkBoxStatus.carpet}
            onClick={() => clickCheckBox("carpet")}
          />
          <label className="createjob__checklabel" htmlFor="carpet">
            Carpet
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="floorCare"
            defaultChecked={checkBoxStatus.floorCare}
            onClick={() => clickCheckBox("floorCare")}
          />
          <label className="createjob__checklabel" htmlFor="floorCare">
            Floor Care
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="window"
            defaultChecked={checkBoxStatus.window}
            onClick={() => clickCheckBox("window")}
          />
          <label className="createjob__checklabel" htmlFor="window">
            Window
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="serviceOther"
            defaultChecked={checkBoxStatus.other}
            onClick={() => clickCheckBox("other")}
          />
          <label className="createjob__checklabel" htmlFor="other">
            Other (Please describe in description)
          </label>
        </div>
        <h4 className="createjob__subtitle createjob__subtitle--small">
          Is your rate negotiable?
        </h4>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="negotiable"
            value={true}
            defaultChecked={currentJob.negotiable === "true" ? true : false}
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            Negotiable
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="negotiable"
            defaultChecked={currentJob.negotiable === "false" ? true : false}
            value={false}
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            Non-negotiable
          </label>
        </div>
        {/*Description Section*/}
        <h3 className="createjob__subtitle">
          What should cleaners know about the job?
        </h3>
        <label className="createjob__label" htmlFor="headline">
          Headline
        </label>
        <input
          className="createjob__textinput"
          name="headline"
          type="text"
          defaultValue={currentJob.headline}
        />
        <label className="createjob__label" htmlFor="description">
          Description of Services Required
        </label>
        <textarea
          className="createjob__textinput createjob__textinput--area"
          name="description"
          defaultValue={currentJob.description}
        ></textarea>
        {/*Supplies Section*/}
        <h4 className="createjob__subtitle createjob__subtitle--small">
          Do you provide cleaning supplies?
        </h4>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="supplies"
            value={true}
            defaultChecked={
              currentJob.suppliesProvided === "true" ? true : false
            }
            required
          />
          <label className="createjob__checklabel" htmlFor="supplies">
            I provide cleaning supplies
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="supplies"
            value={false}
            defaultChecked={
              currentJob.suppliesProvided === "false" ? true : false
            }
            required
          />
          <label className="createjob__checklabel" htmlFor="supplies">
            I do not provide cleaning supplies
          </label>
        </div>
        {/*Supplies Section*/}
        <h4 className="createjob__subtitle createjob__subtitle--small">
          Do you provide equipment?
        </h4>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="equipment"
            value={true}
            defaultChecked={
              currentJob.equipmentProvided === "true" ? true : false
            }
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            I provide cleaning equipment
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__radioinput"
            type="radio"
            name="equipment"
            value={false}
            defaultChecked={
              currentJob.equipmentProvided === "false" ? true : false
            }
            required
          />
          <label className="createjob__checklabel" htmlFor="equipment">
            I do not provide cleaning equipment
          </label>
        </div>
        {/*Availability Section*/}
        <h4 className="createjob__subtitle createjob__subtitle--small">
          What is your availability?
        </h4>
        <p className="createjob__desc">Select all that apply</p>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="mornings"
            defaultChecked={checkBoxStatus.mornings}
            onClick={() => clickCheckBox("mornings")}
          />
          <label className="createjob__checklabel" htmlFor="mornings">
            Mornings
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="evenings"
            defaultChecked={checkBoxStatus.evenings}
            onClick={() => clickCheckBox("evenings")}
          />
          <label className="createjob__checklabel" htmlFor="evenings">
            Evenings
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="days"
            defaultChecked={checkBoxStatus.days}
            onClick={() => clickCheckBox("days")}
          />
          <label className="createjob__checklabel" htmlFor="days">
            Days
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="nights"
            defaultChecked={checkBoxStatus.nights}
            onClick={() => clickCheckBox("nights")}
          />
          <label className="createjob__checklabel" htmlFor="nights">
            Nights
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="afternoons"
            defaultChecked={checkBoxStatus.afternoons}
            onClick={() => clickCheckBox("afternoons")}
          />
          <label className="createjob__checklabel" htmlFor="afternoons">
            Afternoons
          </label>
        </div>
        <div className="createjob__checkContainer">
          <input
            className="createjob__checkinput"
            type="checkbox"
            name="weekends"
            defaultChecked={checkBoxStatus.weekends}
            onClick={() => clickCheckBox("weekends")}
          />
          <label className="createjob__checklabel" htmlFor="weekends">
            Weekends
          </label>
        </div>
        <button className="createjob__button" type="submit">
          SUBMIT
        </button>
      </form>
    </Modal>
  );
}

export default ViewApplicantsModal;
