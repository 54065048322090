import "../../Styles/Sass/FAQ.scss";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import JSONDATA from "./faq.json";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#74ABBA",
    boxShadow: "none",
    borderBottom: "2px solid white",
    padding: "0rem 0rem",
  },
  parentRoot: {
    border: "none",
    backgroundColor: "rgba(0,0,0,0)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    width: "80%",
    ["@media (max-height:768px)"]: {
      width: "95%",
    },
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    color: "white",
    fontSize: "1.883vw",
    ["@media (max-width:768px)"]: {
      fontSize: "4.383vw",
    },
  },
  expandIcon: {
    color: "white",
  },
  text: {
    color: "white",
    fontSize: "1.363vw",
    padding: "1.172vw 0",
    ["@media (max-width:768px)"]: {
      fontSize: "3.783vw",
    },
  },
}));

export default function FAQ() {
  const [searchTerm, setSearchTerm] = React.useState("");

  const classes = useStyles();

  return (
    <section className="FAQ">
      <h2 className="FAQ__title">FAQ'S</h2>

      <input
        type="text"
        className="searchBar"
        placeholder="Search..."
        onChange={(event) => {
          setSearchTerm(event.target.value);
        }}
      />
      {JSONDATA.filter((val) => {
        if (searchTerm == "") {
          return val;
        } else if (
          val.question.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return val;
        }
      }).map((val, key) => {
        return (
          <Accordion classes={{ root: classes.parentRoot }}>
            <AccordionSummary
              classes={{
                root: classes.root,
                expandIcon: classes.expandIcon,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                <strong>+</strong> {val.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{ __html: val.answer }}
                classes={{ root: classes.text }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </section>
  );
}
