import React, { useContext } from "react";
import { UserContext } from "../Providers/UserProvider";
import { firestore, storage } from "../firebase.js";
export default function ProfileInfo() {
  const user = useContext(UserContext);
  const [userProfile, setUserProfile] = React.useState({});
  const [policeFile, setPoliceFile] = React.useState(null);
  const [whimisFile, setWhimisFile] = React.useState(null);
  const [resume, setResume] = React.useState(null);
  const [city, setCity] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [postal, setPostal] = React.useState("");
  const [postalTwo, setPostalTwo] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [latLng, setLatLng] = React.useState({});
  const [recaptcha, setRecaptcha] = React.useState(true);
  const [checkBoxStatus, setCheckBoxStatus] = React.useState({
    home: false,
    commercial: false,
    restaurant: false,
    industrial: false,
    project: false,
    events: false,
    specialty: false,
    airbnb: false,
    other: false,
    offices: false,
    washrooms: false,
    kitchen: false,
    cafeteria: false,
    carpet: false,
    floorCare: false,
    window: false,
    serviceOther: false,
    mornings: false,
    evenings: false,
    days: false,
    nights: false,
    afternoons: false,
    weekends: false,
  });
  const getUserData = () => {
    firestore
      .collection("users")
      .doc(user)
      .get()
      .then((res) => {
        let userData = res.data();
        setUserProfile(userData);
        let checkBox = checkBoxStatus;
        setCity(userData.province);
        setPostalTwo(userData.postal);
        setProvince(userData.city);
        setPlaceId(userData.placeId);
        userData.areaOfWork.includes("home")
          ? (checkBox.home = true)
          : (checkBox.home = false);
        userData.areaOfWork.includes("commercial")
          ? (checkBox.commercial = true)
          : (checkBox.commercial = false);
        userData.areaOfWork.includes("restaurant")
          ? (checkBox.restaurant = true)
          : (checkBox.restaurant = false);
        userData.areaOfWork.includes("industrial")
          ? (checkBox.industrial = true)
          : (checkBox.industrial = false);
        userData.areaOfWork.includes("project")
          ? (checkBox.project = true)
          : (checkBox.project = false);
        userData.areaOfWork.includes("events")
          ? (checkBox.events = true)
          : (checkBox.events = false);
        userData.areaOfWork.includes("specialty")
          ? (checkBox.specialty = true)
          : (checkBox.specialty = false);
        userData.areaOfWork.includes("airbnb")
          ? (checkBox.airbnb = true)
          : (checkBox.airbnb = false);
        userData.areaOfWork.includes("other")
          ? (checkBox.other = true)
          : (checkBox.other = false);
        userData.services.includes("offices")
          ? (checkBox.offices = true)
          : (checkBox.offices = false);
        userData.services.includes("washrooms")
          ? (checkBox.washrooms = true)
          : (checkBox.washrooms = false);
        userData.services.includes("kitchen")
          ? (checkBox.kitchen = true)
          : (checkBox.kitchen = false);
        userData.services.includes("cafeteria")
          ? (checkBox.cafeteria = true)
          : (checkBox.cafeteria = false);
        userData.services.includes("carpet")
          ? (checkBox.carpet = true)
          : (checkBox.carpet = false);
        userData.services.includes("floorCare")
          ? (checkBox.floorCare = true)
          : (checkBox.floorCare = false);
        userData.services.includes("window")
          ? (checkBox.window = true)
          : (checkBox.window = false);
        userData.services.includes("serviceOther")
          ? (checkBox.serviceOther = true)
          : (checkBox.serviceOther = false);
        userData.availability.includes("mornings")
          ? (checkBox.mornings = true)
          : (checkBox.mornings = false);
        userData.availability.includes("evenings")
          ? (checkBox.evenings = true)
          : (checkBox.evenings = false);
        userData.availability.includes("days")
          ? (checkBox.days = true)
          : (checkBox.days = false);
        userData.availability.includes("nights")
          ? (checkBox.nights = true)
          : (checkBox.nights = false);
        userData.availability.includes("afternoons")
          ? (checkBox.afternoons = true)
          : (checkBox.afternoons = false);
        userData.availability.includes("weekends")
          ? (checkBox.weekends = true)
          : (checkBox.weekends = false);
        setCheckBoxStatus(checkBox);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getUserData();
  }, [user]);

  return (
    <section className="dashinfo">
      {userProfile.job === "employer" ? <>Employer set up</> : <></>}
      {userProfile.job === "cleaner" ? <>Cleaner set up</> : <></>}
    </section>
  );
}
