import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../Images/Home/CC_PrimaryLogo_Colour.svg";
import "../Styles/Sass/Footer.scss";

function Footer(props) {
  return (
    <div className="footer">
      <div
        className={
          props.page === "cleanerprofile"
            ? "footer__nav footer__nav--cleanerprofile"
            : "footer__nav"
        }
      >
        <Link className="footer__link" to="/findcleaner">
          Hire a Professional
        </Link>
        <Link className="footer__link" to="/findjob">
          Find a Job
        </Link>
        <img className="footer__logo" src={FooterLogo} alt="Company Logo" />
        <Link className="footer__link" to="/faq">
          FAQ
        </Link>
        <Link className="footer__link" to="/termsandconditions">
          Terms & Conditions
        </Link>
      </div>
      <div className="footer__nav--mobile">
        <Link className="footer__link" to="/findcleaner">
          Hire a Professional
        </Link>
        <Link className="footer__link" to="/findjob">
          Find a Job
        </Link>
        <Link className="footer__link" to="/faq">
          FAQ
        </Link>
        <Link className="footer__link" to="/termsandconditions">
          Terms & Conditions
        </Link>
        <img className="footer__logo" src={FooterLogo} alt="Company Logo" />
      </div>
      <div className="footer__subBox">
        <p className="footer__trademark">
          © 2023 by Clean Connection. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
