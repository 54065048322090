import React from "react";
import LoginModal from "./LoginModal";
import { logOut } from "../firebase.js";
import Logo from "../Images/Home/CC_SecondaryLogo_Colour.svg";
import { Nav, Navbar, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserContext } from "../Providers/UserProvider";
import { useContext } from "react";
import "../Styles/Navbar.scss";

function Header(props) {
  const user = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <>
      <LoginModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
      <Navbar className="nexusNav" expand="md">
        <Navbar.Brand href="/">
          <img
            src={Logo}
            alt="Clean Connection Logo"
            className="nexusLogo"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <br className="getStartedButton navMobile"></br>
            <Nav.Link className="navLinks" href="/About">
              About
            </Nav.Link>
            <Nav.Link className="navLinks" href="/findjob">
              Find a Job
            </Nav.Link>
            <Nav.Link className="navLinks" href="/findcleaner">
              Hire a Cleaner
            </Nav.Link>
            <>
              {user === null ? (
                <>
                  {props.page === "findcleaner" ? (
                    <Nav.Link className="navLinks navMobile" href="/payment">
                      Pricing
                    </Nav.Link>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="getStartedButton navMobile"
                    onClick={openModal}
                  >
                    Login
                  </Button>
                </>
              ) : (
                <>
                  <Nav.Link className="navLinks navMobile" href="/dashboard">
                    Dashboard
                  </Nav.Link>
                  {props.page === "findcleaner" ? (
                    <Nav.Link className="navLinks navMobile" href="/submitjob">
                      Post a Job
                    </Nav.Link>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="getStartedButton navMobile"
                    onClick={logOut}
                  >
                    Logout
                  </Button>
                </>
              )}
            </>
          </Nav>
          <Form className="navDesktop" inline>
            <>
              {user === null ? (
                <>
                  {props.page === "findcleaner" ? (
                    <Nav.Link className="navLinks" href="/submitjob">
                      Post a Job
                    </Nav.Link>
                  ) : (
                    <></>
                  )}
                  <Button className="getStartedButton" onClick={openModal}>
                    Login
                  </Button>
                </>
              ) : (
                <>
                  <Nav.Link className="navLinks" href="/dashboard">
                    Dashboard
                  </Nav.Link>
                  {props.page === "findcleaner" ? (
                    <Nav.Link className="navLinks" href="/submitjob">
                      Post a Job
                    </Nav.Link>
                  ) : (
                    <></>
                  )}
                  <Button className="getStartedButton" onClick={logOut}>
                    Logout
                  </Button>
                </>
              )}
            </>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Header;
