import React from "react";
import Modal from "react-modal";
import { firestore } from "../firebase.js";
import UserListingCard from "./UserListingCard";
import "../Styles/Sass/JobModal.scss";
import "../Styles/Sass/ViewApplicantsModal.scss";

function ViewApplicantsModal(props) {
  const renderApplicants = () => {
    if (props.applicants) {
      if (props.applicants.length > 0) {
        let renderedApplicants = props.applicants.map((applicant) => {
          return (
            <>
              <UserListingCard user={applicant.id} />
              <button
                className="chooseApplicantButton"
                onClick={() => chooseHandler(applicant.id)}
              >
                Choose Applicant
              </button>
            </>
          );
        });
        return renderedApplicants;
      } else {
        return <p>No Applicants</p>;
      }
    }
  };

  const chooseHandler = (applicantId) => {
    firestore
      .collection("jobs")
      .doc(props.job.jobId)
      .update({
        takenBy: applicantId,
      })
      .then((res) => {
        firestore
          .collection("jobs")
          .doc(props.job.jobId)
          .collection("messages")
          .add({})
          .catch((err) => {
            console.log(err);
          });
      })
      .then((res) => {
        firestore
          .collection("users")
          .doc(applicantId)
          .get()
          .then((res) => {
            let userJobs = res.data().jobs;
            let indexOfJob = userJobs.findIndex((job) => {
              return job.jobId === props.job.jobId;
            });
            userJobs[indexOfJob].status = "accepted";
            firestore.collection("users").doc(applicantId).update({
              jobs: userJobs,
            });
            firestore
              .collection("users")
              .doc(applicantId)
              .get()
              .then((res) => {
                let userData = res.data();
                console.log(userData);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .then((res) => {
            firestore
              .collection("users")
              .doc(props.user.uid)
              .get()
              .then((res) => {
                let userData = res.data();
                let indexOfJob = userData.jobs.findIndex((job) => {
                  return job.jobId === props.job.jobId;
                });
                userData.jobs[indexOfJob].status = "chosen";

                firestore
                  .collection("users")
                  .doc(props.user.uid)
                  .update({
                    jobs: userData.jobs,
                  })
                  .then((res) => {
                    props.closeModal();
                    window.location.reload();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="jobmodal"
      overlayClassName="jobmodal__overlay"
      contentLabel="Example Modal"
    >
      <div className="jobmodal__contentbox">{renderApplicants()}</div>
    </Modal>
  );
}

export default ViewApplicantsModal;
