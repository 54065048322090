import { firestore } from "../firebase.js";
import React, { useContext } from "react";
import "../Styles/Sass/UserReport.scss";

function UserReport(props) {
  const [sortFilter, setSortFilter] = React.useState("all");
  const [affiliate, setAffiliate] = React.useState([
    { name: "", count: [], code: "" },
  ]);
  const [cleanerCounter, setCleanerCounter] = React.useState([]);
  const [employerCounter, setEmployerCounter] = React.useState([]);
  const [partialCounter, setPartialCounter] = React.useState([]);

  React.useEffect(() => {
    getAffiliates();
    getUserData();
  }, [sortFilter]);

  const getAffiliates = () => {
    firestore
      .collection("affiliate")
      .get()
      .then((res) => {
        let allAffiliates = [];
        res.docs.forEach((doc) => {
          allAffiliates.push(doc.data());
        });
        setAffiliate(allAffiliates);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = () => {
    firestore
      .collection("users")
      .get()
      .then((res) => {
        let cleaners = [];
        let employers = [];
        let partials = [];
        res.forEach((doc) => {
          let memberFor = Date.now() - doc.data().joined;
          if (doc.data().job === "cleaner") {
            if (sortFilter === "all") {
              cleaners.push(doc.data().joined);
            } else if (sortFilter === "day" && memberFor < 86400000) {
              cleaners.push(doc.data().joined);
            } else if (sortFilter === "week" && memberFor < 604800000) {
              cleaners.push(doc.data().joined);
            } else if (sortFilter === "month" && memberFor < 2419200000) {
              cleaners.push(doc.data().joined);
            } else if (sortFilter === "quarter" && memberFor < 7257600000) {
              cleaners.push(doc.data().joined);
            } else if (sortFilter === "year" && memberFor < 31536000000) {
              cleaners.push(doc.data().joined);
            }
          } else if (doc.data().job === "employer") {
            if (sortFilter === "all") {
              employers.push(doc.data().joined);
            } else if (sortFilter === "day" && memberFor < 86400000) {
              employers.push(doc.data().joined);
            } else if (sortFilter === "week" && memberFor < 604800000) {
              employers.push(doc.data().joined);
            } else if (sortFilter === "month" && memberFor < 2419200000) {
              employers.push(doc.data().joined);
            } else if (sortFilter === "quarter" && memberFor < 7257600000) {
              employers.push(doc.data().joined);
            } else if (sortFilter === "year" && memberFor < 31536000000) {
              employers.push(doc.data().joined);
            }
          } else {
            if (sortFilter === "all") {
              partials.push(doc.data().joined);
            } else if (sortFilter === "day" && memberFor < 86400000) {
              partials.push(doc.data().joined);
            } else if (sortFilter === "week" && memberFor < 604800000) {
              partials.push(doc.data().joined);
            } else if (sortFilter === "month" && memberFor < 2419200000) {
              partials.push(doc.data().joined);
            } else if (sortFilter === "quarter" && memberFor < 7257600000) {
              partials.push(doc.data().joined);
            } else if (sortFilter === "year" && memberFor < 31536000000) {
              partials.push(doc.data().joined);
            }
          }
        });
        setCleanerCounter(cleaners);
        setEmployerCounter(employers);
        setPartialCounter(partials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderAffiliates = () => {
    let renderedAffiliates = affiliate.map((user) => {
      let redeemCounter = 0;
      user.count.forEach((date) => {
        let memberFor = Date.now() - date;
        if (sortFilter === "all") {
          redeemCounter++;
        } else if (sortFilter === "day" && memberFor < 86400000) {
          redeemCounter++;
        } else if (sortFilter === "week" && memberFor < 604800000) {
          redeemCounter++;
        } else if (sortFilter === "month" && memberFor < 2419200000) {
          redeemCounter++;
        } else if (sortFilter === "quarter" && memberFor < 7257600000) {
          redeemCounter++;
        } else if (sortFilter === "year" && memberFor < 31536000000) {
          redeemCounter++;
        }
      });
      return (
        <div className="refercard">
          <div className="refercard__topbar">
            <h4 className="refercard__user">{user.name}</h4>
            <h4 className="refercard__text">
              {redeemCounter} users have redeemed this code
            </h4>
          </div>
          <h4 className="refercard__text">{user.code}</h4>
        </div>
      );
    });
    return renderedAffiliates;
  };

  return (
    <div className="reportbox">
      <div className="userreport">
        <div className="adminnav">
          <label className="adminnav__label" htmlFor="">
            Dashboard view:
          </label>
          <select
            className="adminnav__select"
            onChange={(e) => props.setCurrentTab(e.target.value)}
            name=""
            id=""
          >
            <option value="User report">User report</option>
            <option value="Cleaner data & info">Cleaner data & info</option>
            <option value="Employer data & info">Employer data & info</option>
            <option value="Verify users">Verify users</option>
            <option value="View questions">View questions</option>
          </select>
        </div>
        <div className="userreport__titlebar">
          <h4 className="userreport__title">Data results:</h4>
          <div className="userreport__selectbox">
            <label className="userreport__label" htmlFor="filter">
              Filter results by:
            </label>
            <select
              className="userreport__select"
              onChange={(e) => setSortFilter(e.target.value)}
              name="filter"
            >
              <option value="all">All</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="quarter">Quarter</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>

        {/* <p className="userreport__subtitle">
          Counts reflect users that have selected their account types{" "}
          <strong>except</strong> "Partial profiles"{" "}
        </p> */}

        <div className="userreport__databox">
          <div className="datacard">
            <h5 className="datacard__title">Joined employers</h5>
            <p className="datacard__number">{employerCounter.length}</p>
          </div>
          <div className="datacard">
            <h5 className="datacard__title">Joined cleaners</h5>
            <p className="datacard__number">{cleanerCounter.length}</p>
          </div>

          <div className="datacard datacard--bottom">
            <h5 className="datacard__title">Joined users</h5>
            <p className="datacard__number">
              {cleanerCounter.length + employerCounter.length}
            </p>
          </div>
          <div className="datacard datacard--bottom">
            <h5 className="datacard__title">Partial profiles</h5>
            <p className="datacard__number">{partialCounter.length}</p>
          </div>
        </div>
      </div>
      <div className="affiliates">{renderAffiliates()}</div>
    </div>
  );
}

export default UserReport;
