import React from "react";
import { firestore } from "../firebase.js";
import iconUp from "../Images/iconUp.svg";
import iconUpHighlight from "../Images/iconUpHighlight.svg";
import iconDown from "../Images/iconDown.svg";
import iconDownHighlight from "../Images/iconDownHighlight.svg";
import "../Styles/Sass/Endorsements.scss";
import Modal from "react-modal";
function Endorsements(props) {
  const [userEndorsed, setUserEndorsed] = React.useState({});
  const [myPage, setMyPage] = React.useState(false);
  const [userJob, setUserJob] = React.useState("");
  const [alertText, setAlertText] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }

  React.useEffect(() => {
    if (props.user) {
      getCurrentUserEndorsements();
      if (props.user.uid === props.profileId) {
        setMyPage(true);
      }
    }
  }, [props.user, props.endorsements, props.profileId, props.profileJob]);

  //Get current user endorsed status for user of profile visiting
  const getCurrentUserEndorsements = () => {
    if (props.user) {
      firestore
        .collection("users")
        .doc(props.user.uid)
        .get()
        .then((res) => {
          let jobOfUser = res.data().job;
          setUserJob(jobOfUser);
          let userEndorsements = res.data().endorsed;
          let indexOfEndorsement = userEndorsements.findIndex((endorsed) => {
            return endorsed.userId === props.profileId;
          });
          if (indexOfEndorsement == -1) {
            let endorsedObject = {
              userId: props.profileId,
              endorsement1: 0,
              endorsement2: 0,
              endorsement3: 0,
              endorsement4: 0,
              endorsement5: 0,
              endorsement6: 0,
            };
            userEndorsements.push(endorsedObject);
            // let newIndex = userEndorsements.length - 1;
            firestore
              .collection("users")
              .doc(props.user.uid)
              .update({
                endorsed: userEndorsements,
              })
              .then((res) => {
                setUserEndorsed(endorsedObject);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setUserEndorsed(userEndorsements[indexOfEndorsement]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("fail");
    }
  };

  //render endorsement cards
  const renderEndorsements = () => {
    if (props.endorsements) {
      const renderedElements = props.endorsements.map((endorsement) => {
        return (
          <li className="endorseCard" key={endorsement.name}>
            <h3 className="endorseCard__title">
              {endorsement.name === "endorsement1" ? "On Time Worker" : ""}
              {endorsement.name === "endorsement2" ? "Good Communicator" : ""}
              {endorsement.name === "endorsement3"
                ? "Great Attention to Detail"
                : ""}
              {endorsement.name === "endorsement4"
                ? "Great in a Team Setting"
                : ""}
              {endorsement.name === "endorsement5" ? "Self Started" : ""}
              {endorsement.name === "endorsement6"
                ? "Great Team Motivator"
                : ""}
            </h3>
            <div className="endorseCard__voteBox">
              <button
                onClick={
                  !myPage
                    ? () => props.upvoteEndorse(endorsement.name)
                    : () => {
                        setAlertText("You cannot vote for yourself!");
                        openAlert();
                      }
                }
                className="endorseCard__button"
              >
                <img
                  className="endorseCard__icon"
                  src={
                    userEndorsed[endorsement.name] == 1
                      ? iconUpHighlight
                      : iconUp
                  }
                  alt="Up Arrow"
                />
              </button>
              <p className="endorseCard__voteCount">
                {endorsement.up + endorsement.down}
              </p>
              <button
                onClick={
                  myPage || userJob === props.profileJob
                    ? () => {
                        setAlertText(
                          "You cannot vote for yourself, and you cannot downvote others with same job status as you"
                        );
                        openAlert();
                      }
                    : () => props.downvoteEndorse(endorsement.name)
                }
                className="endorseCard__button"
              >
                <img
                  className="endorseCard__icon"
                  src={
                    userEndorsed[endorsement.name] == -1
                      ? iconDownHighlight
                      : iconDown
                  }
                  alt="Down Arrow"
                />
              </button>
            </div>
          </li>
        );
      });
      return renderedElements;
    }
  };

  return (
    <>
      {props.user ? (
        <section className="endorsements">
          {/*Alert Modal */}
          <Modal
            isOpen={alertOpen}
            className="alertmodal"
            onRequestClose={closeAlert}
            overlayClassName="alertmodal__overlay"
            contentLabel="Free Post Modal"
          >
            <div className="alertmodal__contentbox">
              <h4>{alertText}</h4>
              <button onClick={closeAlert} className={"alertmodal__button"}>
                Close
              </button>
            </div>
          </Modal>
          <div className="endorsements__contentBox">
            <h2 className="endorsements__title">Leave an Endorsement</h2>
            <ul className="endorsements__list">{renderEndorsements()}</ul>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
export default Endorsements;
