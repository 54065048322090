import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import PaymentDetail from "../Components/PaymentDetail";
import { UserContext } from "../Providers/UserProvider";
import JobForm from "../Components/JobForm";
import { firestore } from "../firebase";
import usePremiumStatus from "../stripe/usePremiumStatus";
import { Redirect } from "react-router-dom";
function SubmitJob() {
  const user = React.useContext(UserContext);
  const [userProfile, setUserProfile] = React.useState({});
  const userIsPremium = usePremiumStatus(user);

  React.useEffect(() => {
    if (user) {
      firestore
        .collection("users")
        .doc(user.uid)
        .get()
        .then((res) => {
          let userdata = res.data();
          setUserProfile(userdata);
        });
    }
  }, [user, userIsPremium]);

  return (
    <>
      <Navbar />

      {userProfile.job === "employer" &&
      (userProfile.freePost || userIsPremium) ? (
        <JobForm user={user} userProfile={userProfile} />
      ) : userProfile.job === "employer" &&
        !(userProfile.freePost || userIsPremium) ? (
        <>
          <PaymentDetail user={user} />
        </>
      ) : userProfile.job === "cleaner" ? (
        <>
          <Redirect to={"/dashboard"} />
        </>
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
}

export default SubmitJob;
