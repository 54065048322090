import React, { useState } from "react";
import Chat from "../Components/Chat.js";
import { useContext } from "react";
import { UserContext } from "../Providers/UserProvider.js";
import { firestore } from "../firebase.js";
import { useEffect } from "react";
const App = (props) => {
  const user = useContext(UserContext);
  const [job, setJob] = useState({});

  useEffect(() => {
    getJob();
  }, []);
  const getJob = () => {
    firestore
      .collection("jobs")
      .doc(props.match.params.id)
      .get()
      .then((res) => {
        setJob(res.data());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1>Chat App</h1>
      <Chat job={job} />
    </div>
  );
};

export default App;
