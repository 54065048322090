import React from "react";
import CreateJobForm from "../Components/CreateJobForm";
import "../Styles/Sass/JobForm.scss";

function JobForm(props) {
  return (
    <>
      <section className="jobform">
        <CreateJobForm user={props.user} userProfile={props.userProfile} />
      </section>
    </>
  );
}

export default JobForm;
