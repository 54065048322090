import iconBooking from '../../Images/iconBooking.svg';
import iconStar from '../../Images/iconStar.svg';
import iconTransparency from '../../Images/iconTransparency.svg';
import iconTrust from '../../Images/iconTrust.svg';
import '../../Styles/Sass/WhyConnect.scss';

function App() {
  return (
    <section className='whyConnect'>
    	<h3 className='whyConnect__title'>Why You Should Use Clean Connection</h3>
    	<ul className='whyConnect__list'>
    		<li className='whyCard'>
    			<img className='whyCard__image whyCard__image--trust' src={iconTrust} alt="" />
    			<h3 className='whyCard__title'>Trusted Service</h3>
    			<p className='whyCard__description'>Connecting you with some of the best cleaners in your area</p>
    		</li>
    		<li className='whyCard'>
    			<img className='whyCard__image whyCard__image--easytouse' src={iconBooking} alt="" />
    			<h3 className='whyCard__title'>Easy to Use</h3>
    			<p className='whyCard__description'>Choose the best candidate for your job or apply for a cleaning job hassle-free</p>
    		</li>
    		<li className='whyCard'>
    			<img className='whyCard__image' src={iconStar} alt="" />
    			<h3 className='whyCard__title'>Scout an Expert</h3>
    			<p className='whyCard__description'>Explore candidate profiles and choose an expert that serves your needs</p>
    		</li>
    		<li className='whyCard'>
    			<img className='whyCard__image whyCard__image--transparency' src={iconTransparency} alt="" />
    			<h3 className='whyCard__title'>Total Transparency</h3>
    			<p className='whyCard__description'>Trust who you hire through our review system</p>
    		</li>
    	</ul>
    </section>
  );
}

export default App;
