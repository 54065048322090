import Navbar from "../Components/Navbar";
import Profile from "../Components/Profile";
import CleanerDetail from "../Components/CleanerProfile/CleanerDetail";
import Testimonials from "../Components/Testimonials";
import Endorsements from "../Components/Endorsements";
import Listing from "../Components/JobListing/Listing";
import SimilarUser from "../Components/CleanerProfile/SimilarUser";
import Footer from "../Components/Footer";
import { firestore } from "../firebase.js";
import React, { useContext } from "react";
import { UserContext } from "../Providers/UserProvider";
import Modal from "react-modal";
function CleanerProfile(props) {
  const [userProfile, setUserProfile] = React.useState({});
  const [alertText, setAlertText] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }
  React.useEffect(() => {
    getUserData(props.match.params.id);
  }, [props.match.params.id]);

  const user = useContext(UserContext);

  const getUserData = (userId) => {
    firestore
      .collection("users")
      .doc(userId)
      .get()
      .then((res) => {
        let userProfile = res.data();
        setUserProfile(userProfile);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateHired = () => {
    if (userProfile.job === "employer") {
      let counter = 0;
      userProfile.jobs.forEach((job) => {
        if (job.status === "chosen") {
          counter++;
        }
      });
      return counter;
    }
  };

  //upvote endorsement
  const upvoteEndorse = (endorsement) => {
    firestore
      .collection("users")
      .doc(user.uid)
      .get()
      .then((res) => {
        let userVote = 0;
        let userData = res.data().endorsed;
        let indexOfEndorsement = userData.findIndex((item) => {
          return item.userId === props.match.params.id;
        });
        let endorsementCount = 0;
        if (
          endorsement !== "endorsement1" &&
          userData[indexOfEndorsement].endorsement1 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement2" &&
          userData[indexOfEndorsement].endorsement2 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement3" &&
          userData[indexOfEndorsement].endorsement3 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement4" &&
          userData[indexOfEndorsement].endorsement4 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement5" &&
          userData[indexOfEndorsement].endorsement5 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement6" &&
          userData[indexOfEndorsement].endorsement6 !== 0
        ) {
          endorsementCount++;
        }
        if (endorsementCount >= 3) {
          setAlertText("You can only vote for 3 endorsements per user!");
          openAlert();
        } else {
          if (userData[indexOfEndorsement][endorsement] === -1) {
            userData[indexOfEndorsement][endorsement] = 1;
            userVote = 2;
          } else if (userData[indexOfEndorsement][endorsement] === 0) {
            userData[indexOfEndorsement][endorsement] = 1;
            userVote++;
          } else {
            userData[indexOfEndorsement][endorsement] = 0;
            userVote--;
          }
          firestore
            .collection("users")
            .doc(user.uid)
            .update({
              endorsed: userData,
            })
            .then((res) => {
              firestore
                .collection("users")
                .doc(props.match.params.id)
                .get()
                .then((res) => {
                  let profileData = res.data().endorsements;
                  let indexOfE = profileData.findIndex((item) => {
                    return item.name === endorsement;
                  });
                  if (userVote == 2) {
                    profileData[indexOfE].down++;
                    profileData[indexOfE].up++;
                  } else if (userVote == 1) {
                    profileData[indexOfE].up++;
                  } else if (userVote == -1) {
                    profileData[indexOfE].up--;
                  }
                  firestore
                    .collection("users")
                    .doc(props.match.params.id)
                    .update({
                      endorsements: profileData,
                    })
                    .then((res) => {
                      getUserData(props.match.params.id);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };

  //downvote endorsement
  const downvoteEndorse = (endorsement) => {
    firestore
      .collection("users")
      .doc(user.uid)
      .get()
      .then((res) => {
        let userVote = 0;
        let userData = res.data().endorsed;
        let indexOfEndorsement = userData.findIndex((item) => {
          return item.userId === props.match.params.id;
        });

        let endorsementCount = 0;
        if (
          endorsement !== "endorsement1" &&
          userData[indexOfEndorsement].endorsement1 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement2" &&
          userData[indexOfEndorsement].endorsement2 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement3" &&
          userData[indexOfEndorsement].endorsement3 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement4" &&
          userData[indexOfEndorsement].endorsement4 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement5" &&
          userData[indexOfEndorsement].endorsement5 !== 0
        ) {
          endorsementCount++;
        }
        if (
          endorsement !== "endorsement6" &&
          userData[indexOfEndorsement].endorsement6 !== 0
        ) {
          endorsementCount++;
        }
        if (endorsementCount >= 3) {
          setAlertText("You can only vote for 3 endorsements per user!");
          openAlert();
        } else {
          if (userData[indexOfEndorsement][endorsement] === -1) {
            userData[indexOfEndorsement][endorsement] = 0;
            userVote++;
          } else if (userData[indexOfEndorsement][endorsement] === 0) {
            userData[indexOfEndorsement][endorsement] = -1;
            userVote--;
          } else {
            userData[indexOfEndorsement][endorsement] = -1;
            userVote = -2;
          }
          firestore
            .collection("users")
            .doc(user.uid)
            .update({
              endorsed: userData,
            })
            .then((res) => {
              firestore
                .collection("users")
                .doc(props.match.params.id)
                .get()
                .then((res) => {
                  let profileData = res.data().endorsements;
                  let indexOfE = profileData.findIndex((item) => {
                    return item.name === endorsement;
                  });
                  if (userVote == -2) {
                    profileData[indexOfE].down--;
                    profileData[indexOfE].up--;
                  } else if (userVote == 1) {
                    profileData[indexOfE].down++;
                  } else if (userVote == -1) {
                    profileData[indexOfE].down--;
                  }
                  firestore
                    .collection("users")
                    .doc(props.match.params.id)
                    .update({
                      endorsements: profileData,
                    })
                    .then((res) => {
                      getUserData(props.match.params.id);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };

  return (
    <>
      <Navbar />
      <Profile
        name={userProfile.name}
        job={userProfile.job}
        headline={userProfile.headline}
        province={userProfile.province}
        city={userProfile.city}
        postal={userProfile.postal}
        rate={userProfile.rate}
        whimis={userProfile.whimis}
        policecheck={userProfile.policecheck}
        insurance={userProfile.insurance}
        businessNumberVerified={userProfile.businessNumberVerified}
        jobs={userProfile.jobs}
        email={userProfile.email}
        hired={calculateHired}
        user={user}
        negotiable={userProfile.negotiable}
        uploads={userProfile.uploads}
        page={"profile"}
      />
      <Modal
        isOpen={alertOpen}
        className="alertmodal"
        onRequestClose={closeAlert}
        overlayClassName="alertmodal__overlay"
        contentLabel="Free Post Modal"
      >
        <div className="alertmodal__contentbox">
          <h4>{alertText}</h4>
          <button onClick={closeAlert} className={"alertmodal__button"}>
            Close
          </button>
        </div>
      </Modal>
      {userProfile.job === "cleaner" ? (
        <CleanerDetail
          user={user}
          description={userProfile.description}
          availability={userProfile.availability}
          services={userProfile.services}
          areaOfWork={userProfile.areaOfWork}
          equipment={userProfile.equipment}
          supplies={userProfile.supplies}
        />
      ) : (
        <Listing user={user} page={"profile"} profile={props.match.params.id} />
      )}
      <Testimonials
        page={"cleanerProfile"}
        title={`${userProfile.name} Reviews`}
        userProfile={userProfile}
        userReview={userProfile.reviews}
        currentUser={user}
        profileJob={userProfile.job}
        profileId={props.match.params.id}
      />
      <Endorsements
        endorsements={userProfile.endorsements}
        profileJob={userProfile.job}
        user={user}
        upvoteEndorse={upvoteEndorse}
        downvoteEndorse={downvoteEndorse}
        profileId={props.match.params.id}
      />
      {userProfile.job === "employer" ? (
        <SimilarUser
          user={user}
          profile={userProfile}
          page={userProfile.job === "cleaner" ? "cleaner" : "employer"}
        />
      ) : (
        <></>
      )}
      <Footer page={userProfile.job === "cleaner" ? "cleanerprofile" : ""} />
    </>
  );
}

export default CleanerProfile;
