import React from "react";
import { useDropzone } from 'react-dropzone';

function DragoDropFiles(props){

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    getFilesFromEvent: (e) => {
      const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      console.log(file[0]);
      props.setTarget(file[0]);
      return file;
    }
  });

  const renderFiles = acceptedFiles.map(f => {
    console.log('test');
    return(
      <li>
        FF
      </li>
    );
  })

  return(
    <>
      <div {...getRootProps({ className: 'fileform__fileinput' })}>
        <input {...getInputProps()} />
        <p>Drag and drop file here, or click to select files</p>
      </div>
      <ul>
        {renderFiles}
      </ul>
    </>
  );
}

export default DragoDropFiles;