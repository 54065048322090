import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../firebase.js";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import "../Styles/Sass/UserCard.scss";

function UserCard(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const deleteUser = () => {
    firestore
      .collection("users")
      .doc(props.userId)
      .get()
      .then(async (res) => {
        let jobArray = res.data().jobs;
        if (props.job === "employer") {
          await deleteRelatedJobs(jobArray);
        } else if (props.job === "cleaner") {
          await deleteRelatedApplications(jobArray);
        } else {
          console.log("error!");
        }
        firestore
          .collection("users")
          .doc(props.userId)
          .delete()
          .then((res) => {
            console.log("User Deleted");
            props.setAllUsers();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function openModal(job) {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const deleteRelatedApplications = (relatedApplications) => {
    relatedApplications.forEach((application) => {
      firestore
        .collection("jobs")
        .doc(application.jobId)
        .get()
        .then((res) => {
          let jobApplicants = res.data().applicants;
          let jobTakenBy = res.data().takenBy;
          if (jobTakenBy === props.userId) {
            jobTakenBy = "open";
          }
          let indexOfApplicant = jobApplicants.findIndex((applicant) => {
            return applicant.id === props.userId;
          });
          jobApplicants.splice(indexOfApplicant, 1);
          firestore
            .collection("jobs")
            .doc(application.jobId)
            .update({
              applicants: jobApplicants,
              takenBy: jobTakenBy,
            })
            .then((res) => {
              console.log("User removed from applicant");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const deleteRelatedJobs = (relatedJobs) => {
    relatedJobs.forEach((job) => {
      firestore
        .collection("jobs")
        .doc(job.jobId)
        .get()
        .then(async (res) => {
          let jobApplicants = res.data().applicants;
          await jobApplicants.forEach((applicant) => {
            firestore
              .collection("users")
              .doc(applicant.id)
              .get()
              .then((doc) => {
                let jobData = doc.data().jobs;
                let indexOfJob = jobData.findIndex((job) => {
                  return job.jobId === job.jobId;
                });
                jobData.splice(indexOfJob, 1);
                firestore
                  .collection("users")
                  .doc(applicant.id)
                  .update({
                    jobs: jobData,
                  })
                  .then((res) => {
                    console.log("Success");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          });
          firestore
            .collection("jobs")
            .doc(job.jobId)
            .delete()
            .then((res) => {
              console.log("Job Deleted");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <li key={props.userId} className="usercard">
      <ConfirmDeleteModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        clickFunction={deleteUser}
      />
      <Link to={`/profile/${props.userId}`} className="usercard__title">
        {props.name}
      </Link>
      <h5 className="usercard__job">
        {props.job === "" ? "Needs Update" : props.job}
      </h5>
      {props.job === "cleaner" && props.resumeFileUrl ? (
        <a
          className="usercard__resume"
          href={props.resumeFileUrl}
          target="_blank"
        >
          Resume
        </a>
      ) : (
        <></>
      )}
      <h6 className="usercard__email">{props.email}</h6>
      <div
        className={
          props.job === "cleaner"
            ? "usercard__buttonDivGridThree"
            : "usercard__buttonDivGridTwo"
        }
      >
        <div className="usercard__veritem">
          <div>
            <h5 className="usercard__vertext">Police Verification:</h5>
            {props.policeCheckFileUrl ? (
              <a
                className="usercard__policeLink"
                href={props.policeCheckFileUrl}
                target="_blank"
              >
                Link
              </a>
            ) : (
              <a className="usercard__policeLink usercard__policeLink--noLink">
                N/A
              </a>
            )}
          </div>
          <button
            className={
              props.policecheck
                ? "usercard__button usercard__button--verified"
                : "usercard__button"
            }
            onClick={() =>
              props.verifyUser(
                props.userId,
                "policecheck",
                props.policecheck,
                props.job
              )
            }
          >
            {props.policecheck ? "Police Verified" : "Needs Verification"}
          </button>
        </div>
        {props.job === "cleaner" ? (
          <div className="usercard__veritem">
            <div>
              <h5 className="usercard__vertext">Insurance Verification:</h5>
              {props.insuranceFileUrl ? (
                <a
                  className="usercard__policeLink"
                  href={props.insuranceFileUrl}
                  target="_blank"
                >
                  Link
                </a>
              ) : (
                <a className="usercard__policeLink usercard__policeLink--noLink">
                  N/A
                </a>
              )}
            </div>
            <button
              className={
                props.insurance
                  ? "usercard__button usercard__button--verified"
                  : "usercard__button"
              }
              onClick={() =>
                props.verifyUser(
                  props.userId,
                  "insurance",
                  props.insurance,
                  props.job
                )
              }
            >
              {props.insurance ? "Insurance Verified" : "Needs Verification"}
            </button>
          </div>
        ) : (
          <></>
        )}
        {props.job === "cleaner" ? (
          <div className="usercard__veritem">
            <div>
              <h5 className="usercard__vertext">WHMIS Verification:</h5>
              {props.whmisFileUrl ? (
                <a
                  className="usercard__whmisLink"
                  href={props.whmisFileUrl}
                  target="_blank"
                >
                  Link
                </a>
              ) : (
                <a className="usercard__policeLink usercard__policeLink--noLink">
                  N/A
                </a>
              )}
            </div>
            <button
              className={
                props.whimis
                  ? "usercard__button usercard__button--verified"
                  : "usercard__button"
              }
              onClick={() =>
                props.verifyUser(
                  props.userId,
                  "whimis",
                  props.whimis,
                  props.job
                )
              }
            >
              {props.whimis ? "WHMIS Verified" : "Needs Verification"}
            </button>
          </div>
        ) : (
          <></>
        )}
        {props.job === "employer" ? (
          <div className="usercard__veritem">
            <div>
              <h5 className="usercard__vertext">Business Verification:</h5>
              {props.businessNumber ? (
                <a
                  className="usercard__whmisLink"
                  href={`https://beta.canadasbusinessregistries.ca/search/results?search=%7B${props.businessNumber}%7D&status=Active`}
                  target="_blank"
                >
                  #{props.businessNumber}
                </a>
              ) : (
                <a className="usercard__policeLink usercard__policeLink--noLink">
                  N/A
                </a>
              )}
            </div>
            <button
              className={
                props.businessNumberVerified
                  ? "usercard__button usercard__button--verified"
                  : "usercard__button"
              }
              onClick={() =>
                props.verifyUser(
                  props.userId,
                  "businessNumberVerified",
                  props.businessNumberVerified,
                  props.job
                )
              }
            >
              {props.businessNumberVerified
                ? "Business Verified"
                : "Needs Verification"}
            </button>
          </div>
        ) : (
          <></>
        )}
        {/* {props.job === "cleaner" ? (
          <button
            className={
              props.insurance
                ? "usercard__button usercard__button--verified"
                : "usercard__button"
            }
            onClick={() =>
              props.verifyUser(
                props.userId,
                "insurance",
                props.insurance,
                props.job
              )
            }
          >
            {props.insurance ? "Insurance Verified" : "Needs Verification"}
          </button>
        ) : (
          <></>
        )}
        {props.job === "cleaner" ? (
          <button
            className={
              props.whimis
                ? "usercard__button usercard__button--verified"
                : "usercard__button"
            }
            onClick={() =>
              props.verifyUser(props.userId, "whimis", props.whimis, props.job)
            }
          >
            {props.whimis ? "WHMIS Verified" : "Needs Verification"}
          </button>
        ) : (
          <></>
        )}
        {props.job === "employer" ? (
          <button
            className={
              props.businessNumberVerified
                ? "usercard__button usercard__button--verified"
                : "usercard__button"
            }
            onClick={() =>
              props.verifyUser(
                props.userId,
                "businessNumberVerified",
                props.businessNumberVerified,
                props.job
              )
            }
          >
            {props.businessNumberVerified
              ? "Business Verified"
              : "Needs Verification"}
          </button>
        ) : (
          <></>
        )} */}
      </div>

      {/* <div className="usercard__verificationbox">
        {props.job === "cleaner" ? (
          <div className="usercard__innerver">
            <h5 className="usercard__vertext">Insurance Verification:</h5>
            {props.insuranceFileUrl ? (
              <a
                className="usercard__policeLink"
                href={props.insuranceFileUrl}
                target="_blank"
              >
                Link
              </a>
            ) : (
              <p>N/A</p>
            )}
          </div>
        ) : (
          <></>
        )}
        {props.job === "cleaner" ? (
          <div className="usercard__innerver">
            <h5 className="usercard__vertext">WHMIS Verification:</h5>
            {props.whmisFileUrl ? (
              <a
                className="usercard__whmisLink"
                href={props.whmisFileUrl}
                target="_blank"
              >
                Link
              </a>
            ) : (
              <p>N/A</p>
            )}
          </div>
        ) : (
          <></>
        )}
        {props.job === "employer" ? (
          <div className="usercard__innerver">
            <h5 className="usercard__vertext">Business Verification:</h5>
            {props.businessNumber ? (
              <a
                className="usercard__whmisLink"
                href={`https://beta.canadasbusinessregistries.ca/search/results?search=%7B${props.businessNumber}%7D&status=Active`}
                target="_blank"
              >
                #{props.businessNumber}
              </a>
            ) : (
              <p>N/A</p>
            )}
          </div>
        ) : (
          <></>
        )}
      </div> */}
      <button
        onClick={() => setIsOpen(true)}
        className="usercard__button usercard__button--delete"
      >
        Delete User
      </button>
    </li>
  );
}

export default UserCard;
