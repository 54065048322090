import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { firestore } from "../firebase.js";
import "../Styles/Sass/JobModal.scss";

function JobDataModal(props) {
  const [alertText, setAlertText] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }

  React.useEffect(() => {}, [props.jobDetail, props.modalIsOpen]);

  const renderArray = (array) => {
    let returnString = "";
    if (array) {
      array.forEach((word) => {
        returnString = returnString.concat(
          `${word[0].toUpperCase() + word.slice(1)} `
        );
        returnString = returnString.replace(/[ ,]+/g, ", ");
      });
      returnString = returnString.slice(0, returnString.length - 2);
      returnString = (
        <p className="jobmodal__description jobmodal__description--array">
          {returnString}
        </p>
      );
      return returnString;
    }
  };

  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        className="jobmodal"
        overlayClassName="jobmodal__overlay"
        contentLabel="Example Modal"
      >
        <div className="jobmodal__contentbox">
          <p className="jobmodal__headline">{props.jobDetail.headline}</p>
          <h3 className="jobmodal__criteria">Area of Work</h3>
          <div className="jobmodal__arrayBox">
            {renderArray(props.jobDetail.areaOfWork)}
          </div>
          <h3 className="jobmodal__criteria">Services Required</h3>
          <div className="jobmodal__arrayBox">
            {renderArray(props.jobDetail.requiredServices)}
          </div>
          <h3 className="jobmodal__criteria">Job Availability</h3>
          <div className="jobmodal__arrayBox">
            {renderArray(props.jobDetail.availability)}
          </div>
          <h3 className="jobmodal__criteria">Number of Cleaners required</h3>
          <p className="jobmodal__description jobmodal__description--array">
            {props.jobDetail.numberOfCleaners}
          </p>
          <h3 className="jobmodal__criteria">Job Type</h3>
          <p className="jobmodal__description jobmodal__description--array">
            <>
              {props.jobDetail.jobType === "fullTime" ? "Full Time" : ""}
              {props.jobDetail.jobType === "partTime" ? "Part Time" : ""}
              {props.jobDetail.jobType === "permanent" ? "Permanent" : ""}
              {props.jobDetail.jobType === "other" ? "Other" : ""}
            </>
          </p>
          <h3 className="jobmodal__criteria">Supplies/Equipment</h3>
          <div className="jobmodal__arrayBox">
            <p className="jobmodal__description jobmodal__description--array">
              {props.jobDetail.suppliesProvided === "true"
                ? "Supplies Provided"
                : "Supplies Not Provided"}
              /
              {props.jobDetail.equipmentProvided === "true"
                ? "Equipment Provided"
                : "Equipment Not Provided"}
            </p>
          </div>
          <h3 className="jobmodal__criteria">Job Description</h3>
          <p className="jobmodal__description">{props.jobDetail.description}</p>
          <div className="jobmodal__subBox">
            <p className="jobmodal__price">
              ${props.jobDetail.rate} per hour (
              {props.jobDetail.negotiable === "true"
                ? "Negotiable"
                : "Non-negotiable"}
              )
            </p>
            <div className="jobmodal__detailBox">
              <p className="jobmodal__headline jobmodal__SFL">
                {props.jobDetail.city} | {props.jobDetail.postal} |{" "}
                {props.jobDetail.province}{" "}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default JobDataModal;
