 import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
// import AboutHero from "../Components/About/AboutHero";

import FAQS from "../Components/About/FAQS";


function FAQ(){
  return(
    <>
      <Navbar/>
      <FAQS/>
      <Footer/>
    </>
  );
}

export default FAQ;