import {React ,useState} from "react";
import Modal from 'react-modal';
import '../Styles/Sass/ReviewModal.scss';
import { firestore } from '../firebase.js';
import badwords from '../Components/badWords.js';

function ReviewModal(props) {
  const badwords = require('./badWords');
  const badWordsArray = new RegExp(badwords.badWords.join("|"), 'gi');
  const [review, setReview] = useState("");
  const preventBadWords = (e) =>{
    var newReview = e.target.value.replace(badWordsArray, '');
    setReview(newReview);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    firestore.collection('users').doc(props.profileId).get()
      .then(doc => {
        let userReviews = doc.data().reviews;
        if (props.editText === '') {
          userReviews.unshift({
            review: e.target.review.value,
            leftBy: props.currentUserName,
            leftById: props.currentUser.uid,
            leftByEmail: props.currentEmail
          });
        } else {
          let indexOfReview = userReviews.findIndex(review => {
            return review.leftById === props.currentUser.uid
          });
          userReviews[indexOfReview].review = e.target.review.value;
        }
        firestore.collection("users").doc(props.profileId).update({
          reviews: userReviews
        })
          .then((docRef) => {
            console.log(docRef);
            props.getUserData();
            props.closeModal();
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      })
      .then((res) => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className='reviewmodal'
      overlayClassName='reviewmodal__overlay'
      contentLabel="Review Modal"
    >
      <div className='reviewmodal__contentbox'>
        <form onSubmit={onSubmit} className='reviewmodal__form'>
          <label className='reviewmodal__label' htmlFor="review">Leave a Review</label>
          <textarea className='reviewmodal__input' name="review" value={review} onChange={preventBadWords}>{props.editText}</textarea>
          <button type='submit' className='reviewmodal__button'>Submit</button>
        </form>
      </div>
    </Modal>
  );
}

export default ReviewModal;