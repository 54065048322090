import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import '../Styles/Sass/TermsAndConditions.scss';

function Home(){

  return(
    <>
      <Navbar />
      <section className='tandc'>
        <h2 className='tandc__title'>Terms and Conditions</h2>
        <p className='tandc__text'>
          The following are the terms and conditions for using the services offered by Cleanconnection.ca, including without limitation, email and website services and other services which may be offered from time to time by Cleanconnection.ca (each feature individually and collectively referred to as "The Service"). Please read the terms carefully. The Service is provided to individuals who are of the age of consent according to the laws of the Province in which they reside.
        </p>
        <p className='tandc__text'>
          The Service is offered to you conditioned upon your accepting all of the following terms, conditions, and notices contained herein, without any modification thereto:
        </p>
        <h3 className='tandc__subtitle'>1. MEMBER ACCOUNT, PASSWORD, AND SECURITY</h3>
        <p className='tandc__text'>
          To open an account, you must complete the registration process by providing us with current, complete and accurate information as prompted by the Registration Form. You will then choose a password and an account name. You alone are responsible for the security of your password, which should be kept confidential at all times. You agree to notify Cleanconnection.ca upon your learning of any unauthorized use of your account or any other breach of security. We accept no liability for any unauthorized or improper use of disclosure of any password and take no responsibility for the activities on your account.
        </p>
        <h3 className='tandc__subtitle'>2. DESCRIPTION OF SERVICE</h3>
        <p className='tandc__text'>
          Cleanconnection.ca (hereinafter sometimes referred to as "Clean Connection”) is an on-line cleaner/cleaning and domestic/personal / commercial matching service which allows users to search “Clean Connection’s” database either to obtain leads from employers or from applicants. Cleanconnection.ca does not involve itself in the transactions between Job Seekers and Employers. Cleanconnection.ca does not interview, screen, or perform background checks on either job seekers or employers that appear on the site. Cleanconnection.ca therefore has no control over the safety, accuracy or quality of the jobs or resumes posted, or knowledge of either the capability of prospective employers to offer job opportunities to candidates or the ability of the Job Seekers to fill job openings. Cleanconnection.ca cannot guarantee or provide assurance that Job Seekers employment will successfully find employment; or that Employers who are seeking to hire jobseekers will do so.
        </p>
        <h3 className='tandc__subtitle'>3. MEMBER PRIVACY</h3>
        <p className='tandc__text'>
          Cleanconnection.ca will only use the information you provide on the web site in accordance with our Privacy Policy. For more information, see our full Privacy Policy.
        </p>
        <h3 className='tandc__subtitle'>4. MESSAGE STORAGE, OUTBOUND MESSAGES AND OTHER LIMITATIONS</h3>
        <p className='tandc__text'>
          You agree that Cleanconnection.ca is not responsible or liable for the deletion of or the failure to store messages or other information.
        </p>
        <h3 className='tandc__subtitle'>5. MEMBER CONDUCT</h3>
        <p className='tandc__text'>
          As a condition of using of the Service, you warrant to Cleanconnection.ca that you will not use the Service for any purpose that is unlawful or prohibited by the TERMS OF SERVICE.
        </p>
        <br></br>
        <h2 className='tandc__title'>Privacy Policy</h2>
        <p className='tandc__text'>
          This Privacy Policy explains how Cleanconnection.ca collects, uses, discloses, stores, safeguards and retains your Personal Information. If you do not agree to the terms of this Privacy Policy, you may not use our website, services or products.
        </p>
        <p className='tandc__text'>
          PLEASE READ THESE TERMS OF USE CAREFULLY.  THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, AS WELL AS EXCLUSIONS AND LIMITATIONS ON LIABILITY.
        </p>
        <h3 className='tandc__subtitle'>Overview</h3>
        <p className='tandc__text'>
          Our primary goal in collecting any personal information is to protect all that use this site. Further to provide you with an efficient and customized service to advertise all the information provided by yourself to other members or visitors of the web site Cleanconnection.ca in a clear safe manner.
          <br></br>
          As part of the normal operational procedures we collect and, in some cases, may disclose information about you for the comfort of our users. By accepting this Privacy Policy and our Terms of Services, you expressly consent to our use and disclosure of your personal information in the manner described in this Privacy Policy. This Privacy Policy is incorporated into and subject to the terms of the Cleanconnection.ca Terms of Services.
        </p>
        <h3 className='tandc__subtitle'>The Information We Collect :</h3>
        <p className='tandc__text'>
         Contact and identification information: your name, address, postal code, telephone number and e-mail address.
         <br></br>
         Your personal information: marriage status, date of birth, gender, nationality, religion, your pictures, education, interests and hobbies and other details related to your personal skills. Information regarding your previous work experience, your job preferences, references from previous employer's and information regarding your recruitment needs. 
         <br></br>
         Relationship information: information related to your preferences, service needs and decision-making, customer service inquiries and feed-back, and other information requested or provided by you.
         <br></br>
         Communication information messages and chat with other members of the web site. Technological information about the computer you use to access our website: your IP address, information on your browser, and which pages you view. Our website also uses "cookies" (small data files placed on your computer by our website that allows the site to identify you and track your visit).
         <br></br>
         We use third party company products to analyze traffic to the website and to count the number of users that visit a page. These products may use "cookies". We use a third-party advertising company to serve advertisements on our behalf across the Internet. That advertising company may also collect anonymous information about your visits to our websites and provide targeted advertising in the Contact Us.
         <br></br>
         Cleanconnection.ca will make every effort to respond quickly to all inquiries related to any management issues, or the collection and disclosure of any personal information. If you have any questions, concerns or complaints in relation to the policy or practices of Cleanconnection.ca in reference specifically to privacy issues, please contact our Privacy Officer by e-mail at privacy@Cleanconnection.ca</p>
      </section>
      <Footer />
    </>
  );
}

export default Home;