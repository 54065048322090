import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import icnCleaner from "../Images/icnCleaner.png";
import icnEmployer from "../Images/icnEmployer.png";
import icnReport from "../Images/icnReport.png";
import icnQuestions from "../Images/icnQuestions.png";
import icnVerify from "../Images/icnVerify.png";
import icnJobs from "../Images/icnJobs.png";
import { firestore } from "../firebase.js";
function AdminDashboard(props) {
  const [currentUser, setCurrentUser] = React.useState("admin");
  const [userProfile, setUserProfile] = React.useState({});
  React.useEffect(() => {
    if (props.user) {
      setCurrentUser(props.user.uid);
      getUserData(props.user.uid);
    }
  }, [props.user]);
  const getUserData = (userId) => {
    firestore
      .collection("users")
      .doc(userId)
      .get()
      .then((res) => {
        let userProfile = res.data();
        setUserProfile(userProfile);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {userProfile.admin ? (
        <div className="admin__selectsection">
          <div className="admin__selectbox">
            <div
              className="selectcard"
              onClick={() => props.setCurrentTab("Cleaner data & info")}
            >
              <p className="selectcard__text">Cleaner data & information</p>
              <img className="selectcard__image" src={icnCleaner} />
            </div>
            <div
              className="selectcard"
              onClick={() => props.setCurrentTab("View questions")}
            >
              <p className="selectcard__text">View questions</p>
              <img className="selectcard__image" src={icnQuestions} />
            </div>

            <div
              className="selectcard"
              onClick={() => props.setCurrentTab("Employer data & info")}
            >
              <p className="selectcard__text">Employer data & information</p>
              <img className="selectcard__image" src={icnEmployer} />
            </div>
            <div
              className="selectcard"
              onClick={() => props.setCurrentTab("Verify users")}
            >
              <p className="selectcard__text">Verify users</p>
              <img className="selectcard__image" src={icnVerify} />
            </div>
            <div
              className="selectcard"
              onClick={() => props.setCurrentTab("User report")}
            >
              <p className="selectcard__text">User report</p>
              <img className="selectcard__image" src={icnReport} />
            </div>
            <div
              className="selectcard"
              onClick={() => props.setCurrentTab("Admin Init")}
            >
              <p className="selectcard__text">Admin Init</p>
              <img className="selectcard__image" src={icnReport} />
            </div>
            <div
              className="selectcard"
              onClick={() => props.setCurrentTab("Job data")}
            >
              <p className="selectcard__text">Job data</p>
              <img className="selectcard__image" src={icnJobs} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="admin__selectsection">
            <h2>Please log in to view admin page</h2>
          </div>
        </>
      )}
    </>
  );
}

export default AdminDashboard;
