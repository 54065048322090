import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import PaymentDetail from "../Components/PaymentDetail";
import { UserContext } from "../Providers/UserProvider";

function Payment() {
  const user = React.useContext(UserContext);

  return (
    <>
      <Navbar />
      <PaymentDetail user={user} />
      <Footer />
    </>
  );
}

export default Payment;
