import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import dotenv from "dotenv";
dotenv.config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const fbProvider = new firebase.auth.FacebookAuthProvider();
export const emailProvider = new firebase.auth.EmailAuthProvider();
const db = firebase.firestore();

export const signIn = (provider) => {
  auth
    .signInWithPopup(provider)
    .then((res) => {
      db.collection("users")
        .doc(`${res.user.uid}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Existing User");
          } else {
            db.collection("users")
              .doc(res.user.uid)
              .set({
                name: res.user.displayName,
                email: res.user.email,
              })
              .then((docRef) => {
                console.log("Document written with ID: ", res.user.uid);
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
          }
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const logOut = () => {
  auth
    .signOut()
    .then(() => {
      console.log("logged out");
      window.location.href = "/";
    })
    .catch((error) => {
      console.log(error.message);
    });
};
