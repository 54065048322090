import { firestore } from '../firebase.js';
import React from "react";
import Modal from 'react-modal';
import '../Styles/Sass/AdminReviewModal.scss';

function AdminReviewModal(props) {

	const deleteReview = (leftFor, leftById) => {
		firestore.collection('users').doc(leftFor).get()
			.then(res => {
				let userReviews = res.data().reviews;
				let indexOfReview = userReviews.findIndex(review => {
					return review.leftById === leftById;
				});
				if (indexOfReview > -1) {
					userReviews.splice(indexOfReview, 1);
					firestore.collection('users').doc(leftFor).update({
						reviews: userReviews
					})
						.then(res => {
							props.getUserReviews();
						})
						.catch(err => {
							console.log(err);
						})
				}
			})
			.catch(err => {
				console.log(err);
			})
	}

	const renderReviews = () => {
		if (props.reviews.length === 0) {
			return (
				<>
					<h2 className='adminreviewmodal__na'>No reviews</h2>
					<button className='adminreviewmodal__nabutton' onClick={props.closeModal}>Close</button>
				</>
			)
		} else {
			let renderedReviews = props.reviews.map(review => {
				return (
					<div className='adminreviewcard'>
						<div className='adminreviewcard__titlebox'>
							<h3 className='adminreviewcard__title'>{review.leftForName}</h3>
							<h4 className='adminreviewcard__email'>{review.leftForEmail}</h4>
						</div>
						<p className='adminreviewcard__text'>{review.review}</p>
						<button
							className='adminreviewcard__button'
							onClick={() => deleteReview(review.leftFor, review.leftById)}
						>
							Delete review
						</button>
					</div>
				);
			});
			return renderedReviews;
		}
	}

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className='adminreviewmodal'
      overlayClassName='adminreviewmodal__overlay'
      contentLabel="Admin Review Modal"
    >
      <div className='adminreviewmodal__contentbox'>
        {renderReviews()}
      </div>
    </Modal>
  );
}

export default AdminReviewModal;