import React from "react";
import Modal from "react-modal";
import { firestore } from "../firebase.js";
import UserListingCard from "./UserListingCard";
import "../Styles/Sass/ConfirmDeleteModal.scss";

function ViewApplicantsModal(props) {
  const confirmDelete = () => {
    props.clickFunction();
    props.closeModal();
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="confirmmodal"
      overlayClassName="confirmmodal__overlay"
      contentLabel="Example Modal"
    >
      <div className="confirmmodal__contentbox">
        <p className="confirmmodal__text">Are you sure you want to delete?</p>
        <button
          className="confirmmodal__button confirmmodal__button--delete"
          onClick={confirmDelete}
        >
          Delete
        </button>
        <button className="confirmmodal__button" onClick={props.closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default ViewApplicantsModal;
