import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { firestore } from "../firebase.js";
import SimilarUser from "./CleanerProfile/SimilarUser";
import "../Styles/Sass/JobModal.scss";

function JobModal(props) {
  const [applicationStatus, setApplicationStatus] = React.useState(false);
  const [userJob, setUserJob] = React.useState("");
  const [alertText, setAlertText] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }

  React.useEffect(() => {
    checkApplicationStatus();
  }, [props.jobDetail, props.modalIsOpen]);

  const checkApplicationStatus = () => {
    if (props.user) {
      firestore
        .collection("users")
        .doc(props.user.uid)
        .get()
        .then((res) => {
          let userJobs = res.data().jobs;
          let indexOfJob = userJobs.findIndex((job) => {
            return job.jobId === props.jobDetail.jobId;
          });
          if (indexOfJob !== -1) {
            setApplicationStatus(true);
          } else {
            setApplicationStatus(false);
          }
          if (res.data().job === "cleaner") {
            setUserJob("cleaner");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const applyToJob = () => {
    firestore
      .collection("jobs")
      .doc(props.jobDetail.jobId)
      .get()
      .then((res) => {
        let applicants = res.data().applicants;
        applicants.push({ id: props.user.uid, timestamp: Date.now() });
        firestore
          .collection("jobs")
          .doc(props.jobDetail.jobId)
          .update({
            applicants: applicants,
          })
          .then((res) => {
            firestore
              .collection("users")
              .doc(props.user.uid)
              .get()
              .then((res) => {
                let userJobs = res.data().jobs;
                userJobs.push({
                  jobId: props.jobDetail.jobId,
                  status: "applied",
                });
                firestore
                  .collection("users")
                  .doc(props.user.uid)
                  .update({
                    jobs: userJobs,
                  })
                  .then((res) => {
                    checkApplicationStatus();
                    setAlertText("Application Submitted");
                    openAlert();
                    props.closeModal();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const withdrawApplication = () => {
    firestore
      .collection("jobs")
      .doc(props.jobDetail.jobId)
      .get()
      .then((res) => {
        let applicants = res.data().applicants;
        let indexOfApplicant = applicants.findIndex((applicant) => {
          return applicant.id === props.user.uid;
        });
        applicants.splice(indexOfApplicant, 1);
        firestore
          .collection("jobs")
          .doc(props.jobDetail.jobId)
          .update({
            applicants: applicants,
          })
          .then((res) => {
            firestore
              .collection("users")
              .doc(props.user.uid)
              .get()
              .then((res) => {
                let userJobs = res.data().jobs;
                let indexOfJob = userJobs.findIndex((job) => {
                  return job.jobId === props.jobDetail.jobId;
                });
                userJobs.splice(indexOfJob, 1);
                firestore
                  .collection("users")
                  .doc(props.user.uid)
                  .update({
                    jobs: userJobs,
                  })
                  .then((res) => {
                    props.closeModal();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderArray = (array) => {
    let returnString = "";
    if (array) {
      array.forEach((word) => {
        returnString = returnString.concat(
          `${word[0].toUpperCase() + word.slice(1)} `
        );
        returnString = returnString.replace(/[ ,]+/g, ", ");
      });
      returnString = returnString.slice(0, returnString.length - 2);
      returnString = (
        <p className="jobmodal__description jobmodal__description--array">
          {returnString}
        </p>
      );
      return returnString;
    }
  };

  return (
    <>
      {/*Alert Modal */}
      <Modal
        isOpen={alertOpen}
        className="alertmodal"
        onRequestClose={closeAlert}
        overlayClassName="alertmodal__overlay"
        contentLabel="Free Post Modal"
      >
        <div className="alertmodal__contentbox">
          <h4>{alertText}</h4>
          <button onClick={closeAlert} className={"alertmodal__button"}>
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        className="jobmodal"
        overlayClassName="jobmodal__overlay"
        contentLabel="Example Modal"
      >
        <div className="jobmodal__contentbox">
          <Link to={`/profile/${props.jobDetail.postedBy}`}>
            <h2 className="jobmodal__title">{props.jobDetail.postedByName}</h2>
          </Link>
          <p className="jobmodal__headline">{props.jobDetail.headline}</p>
          <h3 className="jobmodal__criteria">Area of Work</h3>
          <div className="jobmodal__arrayBox">
            {renderArray(props.jobDetail.areaOfWork)}
          </div>
          <h3 className="jobmodal__criteria">Services Required</h3>
          <div className="jobmodal__arrayBox">
            {renderArray(props.jobDetail.requiredServices)}
          </div>
          <h3 className="jobmodal__criteria">Job Availability</h3>
          <div className="jobmodal__arrayBox">
            {renderArray(props.jobDetail.availability)}
          </div>
          <h3 className="jobmodal__criteria">Number of Cleaners required</h3>
          <p className="jobmodal__description jobmodal__description--array">
            {props.jobDetail.numberOfCleaners}
          </p>
          <h3 className="jobmodal__criteria">Job Type</h3>
          <p className="jobmodal__description jobmodal__description--array">
            <>
              {props.jobDetail.jobType === "fullTime" ? "Full Time" : ""}
              {props.jobDetail.jobType === "partTime" ? "Part Time" : ""}
              {props.jobDetail.jobType === "permanent" ? "Permanent" : ""}
              {props.jobDetail.jobType === "other" ? "Other" : ""}
            </>
          </p>
          <h3 className="jobmodal__criteria">Supplies/Equipment</h3>
          <div className="jobmodal__arrayBox">
            <p className="jobmodal__description jobmodal__description--array">
              {props.jobDetail.suppliesProvided === "true"
                ? "Supplies Provided"
                : "Supplies Not Provided"}
              /
              {props.jobDetail.equipmentProvided === "true"
                ? "Equipment Provided"
                : "Equipment Not Provided"}
            </p>
          </div>
          <h3 className="jobmodal__criteria">Job Description</h3>
          <p className="jobmodal__description">{props.jobDetail.description}</p>
          <div className="jobmodal__subBox">
            <p className="jobmodal__price">
              ${props.jobDetail.rate} per hour (
              {props.jobDetail.negotiable === "true"
                ? "Negotiable"
                : "Non-negotiable"}
              )
            </p>
            <div className="jobmodal__detailBox">
              <p className="jobmodal__headline jobmodal__SFL">
                {props.jobDetail.city} | {props.jobDetail.postal} |{" "}
                {props.jobDetail.province}{" "}
              </p>
            </div>
          </div>
          {applicationStatus === true && userJob === "cleaner" ? (
            <button
              onClick={withdrawApplication}
              className="greenButton jobmodal__button"
            >
              Withdraw Application
            </button>
          ) : (
            <></>
          )}
          {applicationStatus === false && userJob === "cleaner" ? (
            <button onClick={applyToJob} className="jobmodal__button">
              Apply To Job
            </button>
          ) : (
            <></>
          )}
          {/* <h3 className="jobmodal__subtitle">Related Jobs</h3>
          <SimilarUser
            user={props.user}
            profile={props.jobDetail}
            page={"jobmodal"}
            setJobDetail={props.setJobDetail}
          /> */}
        </div>
      </Modal>
    </>
  );
}

export default JobModal;
