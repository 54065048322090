import { loadStripe } from "@stripe/stripe-js";
// let stripePromise = Stripe || null;

const initializeStripe = async () => {
  // if (!stripePromise) {
  let stripePromise = await loadStripe(
    "pk_live_51LUg7mKBQTWM7dQgIfmdA5Osyu8QXCCh8tjI8WV03XMpEhO7IpIUm2sx2RY8VhVRGPRydVPmpn5rbeqGqBKQXKIo00bB8RBMxf"
  );
  // }
  return stripePromise;
};

export default initializeStripe;
