import '../../Styles/Sass/LookingCleaner.scss';
import { Link } from 'react-router-dom';

function LookingCleaner() {
  return (
    <section className='lookingCleaner'>
    	<h2 className='lookingCleaner__title'>Looking for a Job as a Cleaner?</h2>
    	<h3 className='lookingCleaner__subtitle'>Sign up today to start exploring new opportunities!</h3>
      <Link to="/findjob">
        <button className='greenButton lookingCleaner__button'>Get Started</button>
      </Link>
    </section>
  );
}

export default LookingCleaner;