import { Container, Row, Col } from 'react-grid-system';
import imageSearch from '../../Images/Home/search.svg';
import imageConnection from '../../Images/Home/connection.svg';
import imageHire from '../../Images/Home/hire.svg';
import  '../../Styles/Sass/HowItWorks.scss';
import { Link } from 'react-router-dom';
function App() {
  return (
    <section className='howItWorks'>
      <h2 className='howItWorks__title'>HOW IT WORKS</h2>
      <Container fluid={true} className='howItWorks__list'>
        <Row justify={'around'}>
          <Col md={4} className='howCard'>
            <img className='howCard__image' src={imageSearch} alt="" />
            <h3 className='howCard__title'>1. Browse Cleaners</h3>
            <p className='howCard__description'>Looking for a cleaning service? Post your job, and let candidates come to you</p>
          </Col>
          <Col md={4} className='howCard'>
            <img className='howCard__image' src={imageConnection} alt="" />
            <h3 className='howCard__title'>2. Connect With Candidates</h3>
            <p className='howCard__description'>Choose and select jobs or candidates by connecting through your secure inbox</p>
          </Col>
          <Col md={4} className='howCard'>
            <img className='howCard__image' src={imageHire} alt="" />
            <h3 className='howCard__title'>3. Hire Your Best Match</h3>
            <p className='howCard__description'>Whether you’re looking to hire, or get hired, select your best match with the process we’ve set in place</p>
          </Col>
        </Row>
      </Container>
      <Link to="/findcleaner">
        <button className='howItWorks__button'>Begin Search</button>
      </Link>
    </section>
  );
}

export default App;
