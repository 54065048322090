import React from "react";
import { firestore } from "../firebase.js";
import AdminReviewModal from "./AdminReviewModal";
import AdminJobModal from "./AdminJobModal";
import AdminInformationModal from "./AdminInformationModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import "../Styles/Sass/DataCard.scss";

function AdminInitDataCard(props) {
  function addAdmin() {
    firestore
      .collection("users")
      .doc(props.id)
      .update({
        admin: true,
      })
      .then((res) => {
        props.setChange(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function removeAdmin() {
    firestore
      .collection("users")
      .doc(props.id)
      .update({
        admin: false,
      })
      .then((res) => {
        props.setChange(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <li className="userdatacard">
      <div className="userdatacard__row">
        <h3 className="userdatacard__title">{props.name}</h3>
        <h4 className="userdatacard__subtitle">
          Member since: {props.joinDate}
        </h4>
      </div>
      <div className="userdatacard__row">
        <h3 className="userdatacard__subtitle">{props.email}</h3>
        {/* <button className="userdatacard__delete" onClick={toggleDeleteModal}>
          Delete account
        </button> */}
      </div>
      {!props.admin ? (
        <div className="userdatacard__row userdatacard__row--buttons">
          <button className="userdatacard__button" onClick={() => addAdmin()}>
            Add Admin Status
          </button>
        </div>
      ) : (
        <div className="userdatacard__row userdatacard__row--buttons">
          <button
            className="userdatacard__button"
            onClick={() => removeAdmin()}
          >
            Remove Admin Status
          </button>
        </div>
      )}
    </li>
  );
}

export default AdminInitDataCard;
