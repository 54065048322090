import React from "react";
import { firestore } from "../firebase.js";
import { Link } from "react-router-dom";
import iconBadge from "../Images/iconBadge.svg";
import "../Styles/Sass/UserListingCard.scss";

function UserListingCard(props) {
  const [userProfile, setUserProfile] = React.useState({});

  React.useEffect(() => {
    const getUserData = () => {
      firestore
        .collection("users")
        .doc(props.user)
        .get()
        .then((res) => {
          let userData = res.data();
          setUserProfile(userData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUserData();
  }, [props.user]);

  return (
    <Link className="userListingCard" to={`/profile/${props.user}`}>
      <h4 className="userListingCard__name">{userProfile.name}</h4>
      <h5 className="userListingCard__headline">{userProfile.headline}</h5>
      <p className="userListingCard__description">{userProfile.description}</p>

      {userProfile.policecheck === true ? (
        <div className="userListingCard__badgerow ">
          <img
            className="userListingCard__badgerow--badge"
            src={iconBadge}
            alt="Police Badge Icon"
          />
          <h5 className="userListingCard__badgerow--subtitle">Police Check</h5>
        </div>
      ) : (
        <></>
      )}
      {userProfile.whimis === true ? (
        <div className="userListingCard__badgerow ">
          <img
            className="userListingCard__badgerow--badge"
            src={iconBadge}
            alt="WHMIS Icon"
          />
          <h5 className="userListingCard__badgerow--subtitle">WHMIS Check</h5>
        </div>
      ) : (
        <></>
      )}
      <div className="userListingCard__subBox">
        <p className="userListingCard__rate">${userProfile.rate} per hour</p>
        <p className="userListingCard__location">
          {userProfile.city} | {userProfile.postal} | {userProfile.province}
        </p>
      </div>
    </Link>
  );
}

export default UserListingCard;
