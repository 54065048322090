import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import FindJob from "./Pages/FindJob";
import FindCleaner from "./Pages/FindCleaner";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import CleanerProfile from "./Pages/CleanerProfile";
import Admin from "./Pages/Admin";
import UserDashboard from "./Pages/UserDashboard";
import TermsAndConditions from "./Pages/TermsAndConditions";
import UserProvider from "./Providers/UserProvider";
import FAQ from "./Pages/FAQ";
import Payment from "./Pages/Payment";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import "./Styles/_common.scss";
import Modal from "react-modal";
import SubmitJob from "./Pages/SubmitJob";
import ProfileInfo from "./Pages/ProfileInfo";
import Chat from "./Pages/Chat";

function App() {
  return (
    <UserProvider>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={AboutUs} />
          <Route path="/findjob" component={FindJob} />
          <Route path="/findcleaner" component={FindCleaner} />
          <Route path="/profile/:id" component={CleanerProfile} />
          <Route path="/chat/:id" component={Chat} />
          <Route path="/admin" component={Admin} />
          <Route path="/profileinfo" component={ProfileInfo} />
          <Route path="/dashboard/" component={UserDashboard} />
          <Route path="/faq" component={FAQ} />
          <Route path="/termsandconditions" component={TermsAndConditions} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/payment" component={Payment} />
          <Route path="/submitjob" component={SubmitJob} />
        </Switch>
      </Router>
    </UserProvider>
  );
}

Modal.setAppElement("body");

export default App;
