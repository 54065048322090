import firebase from 'firebase/compat/';

const app = firebase.app();

export async function goToBillingPortal(user) {
  const functionRef = app
    .functions('us-east4')
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
  const { data } = await functionRef({
    returnUrl: `${window.location.origin}`,
    // customer: 'cus_JspiYA0UJPRp07'
  })
  window.location.assign(data.url)
}