import React from "react";
import { firestore } from "../firebase.js";
import AdminReviewModal from "./AdminReviewModal";
import AdminJobModal from "./AdminJobModal";
import AdminInformationModal from "./AdminInformationModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import iconBadge from "../Images/iconBadge.svg";
import "../Styles/Sass/DataCard.scss";

function DataCard(props) {
  const [reviews, setReviews] = React.useState([]);
  const [userJobs, setUserJobs] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [jobModalIsOpen, setJobModalIsOpen] = React.useState(false);
  const [infoModalIsOpen, setInfoModalIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [endorsements, setEndorsements] = React.useState(0);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const toggleJobModal = () => {
    setJobModalIsOpen(!jobModalIsOpen);
  };

  const toggleInfoModal = () => {
    setInfoModalIsOpen(!infoModalIsOpen);
  };

  const toggleDeleteModal = () => {
    setDeleteModalIsOpen(!deleteModalIsOpen);
  };

  React.useEffect(() => {
    getUserReviews();
    getEndorsements();
    if (props.job === "employer") {
      filterUserJobs();
    }
  }, [props.jobs]);
  {
    /* 
    TODO:
    Endorsement adding is not working 
    */
  }
  const getEndorsements = () => {
    let temp = 0;
    props?.endorsements.forEach((endorsement) => {
      temp = temp + endorsement.up - endorsement.down;
    });
    setEndorsements(temp);
  };
  const getUserReviews = () => {
    firestore
      .collection("users")
      .get()
      .then((res) => {
        let userReviews = [];
        res.forEach((user) => {
          user.data().reviews.forEach((review) => {
            if (review.leftById === props.id) {
              let reviewData = review;
              reviewData.leftFor = user.id;
              reviewData.leftForName = user.data().name;
              reviewData.leftForEmail = user.data().email;
              userReviews.push(reviewData);
            }
          });
        });
        setReviews(userReviews);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterUserJobs = () => {
    let filteredJobs = props.jobs.filter((job) => {
      return job.postedBy === props.id;
    });
    setUserJobs(filteredJobs);
  };

  const deleteRelatedApplications = (relatedApplications) => {
    relatedApplications.forEach((application) => {
      firestore
        .collection("jobs")
        .doc(application.jobId)
        .get()
        .then((res) => {
          let jobApplicants = res.data().applicants;
          let jobTakenBy = res.data().takenBy;
          if (jobTakenBy === props.id) {
            jobTakenBy = "open";
          }
          let indexOfApplicant = jobApplicants.findIndex((applicant) => {
            return applicant.id === props.id;
          });
          jobApplicants.splice(indexOfApplicant, 1);
          firestore
            .collection("jobs")
            .doc(application.jobId)
            .update({
              applicants: jobApplicants,
              takenBy: jobTakenBy,
            })
            .then((res) => {
              console.log("User removed from applicant");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const deleteRelatedJobs = (relatedJobs) => {
    relatedJobs.forEach((job) => {
      firestore
        .collection("jobs")
        .doc(job.jobId)
        .get()
        .then(async (res) => {
          let jobApplicants = res.data().applicants;
          await jobApplicants.forEach((applicant) => {
            firestore
              .collection("users")
              .doc(applicant.id)
              .get()
              .then((doc) => {
                let jobData = doc.data().jobs;
                let indexOfJob = jobData.findIndex((job) => {
                  return job.jobId === job.jobId;
                });
                jobData.splice(indexOfJob, 1);
                firestore
                  .collection("users")
                  .doc(applicant.id)
                  .update({
                    jobs: jobData,
                  })
                  .then((res) => {
                    console.log("Success");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          });
          firestore
            .collection("jobs")
            .doc(job.jobId)
            .delete()
            .then((res) => {
              console.log("Job Deleted");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const deleteUser = () => {
    firestore
      .collection("users")
      .doc(props.id)
      .get()
      .then(async (res) => {
        let jobArray = res.data().jobs;
        if (props.job === "employer") {
          await deleteRelatedJobs(jobArray);
        } else if (props.job === "cleaner") {
          await deleteRelatedApplications(jobArray);
        } else {
          console.log("error!");
        }
        firestore
          .collection("users")
          .doc(props.id)
          .delete()
          .then((res) => {
            console.log("User Deleted");
            if (props.job === "employer") {
              props.getEmployerData();
            } else if (props.job === "cleaner") {
              props.getCleanerData();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function suspendHandle() {
    firestore
      .collection("users")
      .doc(props.id)
      .update({
        suspended: true,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function unsuspendHandle() {
    firestore
      .collection("users")
      .doc(props.id)
      .update({
        suspended: false,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <li className="userdatacard">
      <AdminReviewModal
        modalIsOpen={modalIsOpen}
        closeModal={toggleModal}
        reviews={reviews}
        getUserReviews={getUserReviews}
      />
      <ConfirmDeleteModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={toggleDeleteModal}
        clickFunction={deleteUser}
      />
      {props.job === "employer" ? (
        <AdminJobModal
          modalIsOpen={jobModalIsOpen}
          closeModal={toggleJobModal}
          jobs={userJobs}
          getJobPostings={props.getJobPostings}
          userEmail={props.email}
        />
      ) : (
        <AdminInformationModal
          modalIsOpen={infoModalIsOpen}
          closeModal={toggleInfoModal}
          headline={props.headline}
          description={props.description}
          city={props.city}
          province={props.province}
          postal={props.postal}
          name={props.name}
          userEmail={props.email}
          rate={props.rate}
          id={props.id}
          getCleanerData={props.getCleanerData}
        />
      )}
      <div className="userdatacard__row">
        <h3 className="userdatacard__title">{props.name}</h3>
        <h4 className="userdatacard__subtitle">
          Member since: {props.joinDate}
        </h4>
      </div>

      <div className="userdatacard__row">
        <h3 className="userdatacard__subtitle">{props.email}</h3>
        <button className="userdatacard__delete" onClick={toggleDeleteModal}>
          Delete account
        </button>
      </div>
      {/* Badges placed under */}

      {props.policecheck === true ? (
        <div className="userdatacard__row">
          <div className="userdatacard__badgerow ">
            <img
              className="userdatacard__badgerow--badge"
              src={iconBadge}
              alt="Police Badge Icon"
            />
            <h5 className="userdatacard__subtitle">Police Check</h5>
          </div>
        </div>
      ) : (
        <></>
      )}
      {props.whmis === true ? (
        <div className="userdatacard__row">
          <div className="userdatacard__badgerow ">
            <img
              className="userdatacard__badgerow--badge"
              src={iconBadge}
              alt="WHMIS Icon"
            />
            <h5 className="userdatacard__subtitle">WHMIS Check</h5>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="userdatacard__row">
        <h3 className="userdatacard__subtitle">Reviews: {reviews.length}</h3>
      </div>
      <div className="userdatacard__row">
        <h3 className="userdatacard__subtitle">Endorsement: {endorsements}</h3>
      </div>
      <div className="userdatacard__row">
        {props.job === "cleaner" ? (
          <></>
        ) : (
          <h3 className="userdatacard__subtitle">
            Postings: {userJobs.length}
          </h3>
        )}
      </div>
      <div className="userdatacard__row userdatacard__row--buttons">
        <button className="userdatacard__button" onClick={toggleModal}>
          View/delete reviews
        </button>
        {props.suspended ? (
          <button
            onClick={() => unsuspendHandle()}
            className="userdatacard__button"
          >
            Unsuspend account
          </button>
        ) : (
          <button
            onClick={() => suspendHandle()}
            className="userdatacard__button"
          >
            Suspend account
          </button>
        )}
        {props.job === "cleaner" ? (
          <>
            <button className="userdatacard__button" onClick={toggleInfoModal}>
              View/delete information
            </button>
          </>
        ) : (
          <button className="userdatacard__button" onClick={toggleJobModal}>
            View/delete postings
          </button>
        )}
      </div>
    </li>
  );
}

export default DataCard;
