import React from "react";
import { firestore } from "../firebase.js";
import { v4 as uuidv4 } from "uuid";
import "../Styles/Sass/UserQuestions.scss";

function UserQuestions(props) {
  const [questions, setQuestions] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(5);
  const [sortBy, setSortBy] = React.useState("desc");
  React.useEffect(() => {
    getAllQuestions();
  }, [sortBy]);

  const getAllQuestions = () => {
    firestore
      .collection("contactForm")
      .orderBy("time", sortBy)
      .get()
      .then(async (res) => {
        let allQuestions = [];
        await res.docs.forEach((doc) => {
          allQuestions.push({
            id: doc.id,
            question: doc.data(),
          });
        });
        setQuestions(allQuestions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStatus = (docId, statusUpdate) => {
    firestore
      .collection("contactForm")
      .doc(docId)
      .update({
        status: statusUpdate,
      })
      .then((res) => {
        console.log("success");
        getAllQuestions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteQuestion = (docId) => {
    firestore
      .collection("contactForm")
      .doc(docId)
      .delete()
      .then((res) => {
        getAllQuestions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderQuestions = () => {
    let currentQuestions = questions;
    let currentPageQuestions = [];

    for (
      let i = postsPerPage * pageNumber - postsPerPage;
      i < postsPerPage * pageNumber;
      i++
    ) {
      if (currentQuestions[i]) {
        currentPageQuestions.push(currentQuestions[i]);
      }
    }
    let renderedQuestions = currentPageQuestions.map((questions) => {
      let questionDate = new Date(questions.question.time)
        .toString()
        .split(" ");
      return (
        <li className="questioncard" key={uuidv4()}>
          <div className="questioncard__halfbox">
            <h3 className="questioncard__sub">Name:</h3>
            <p className="questioncard__text">{questions.question.name}</p>
          </div>
          <div className="questioncard__halfbox">
            <h3 className="questioncard__sub">Email:</h3>
            <p className="questioncard__text">{questions.question.email}</p>
          </div>

          <div className="questioncard__halfbox">
            <h3 className="questioncard__sub">Message:</h3>
            <p className="questioncard__text">{questions.question.message}</p>
            <h3 className="questioncard__sub">Status:</h3>
            <p className="questioncard__text">{questions.question.status}</p>
          </div>
          <div className="questioncard__halfbox">
            <h3 className="questioncard__sub">Date:</h3>
            <p className="questioncard__text">
              {questionDate[1]} {questionDate[2]} {questionDate[3]}
            </p>
          </div>
          <div className="questioncard__buttonbox">
            <button
              className="questioncard__statusbutton"
              onClick={() =>
                updateStatus(
                  questions.id,
                  questions.question.status === "Arrival"
                    ? "Responding"
                    : questions.question.status === "Responding"
                    ? "Resolved"
                    : "Arrival"
                )
              }
            >
              {questions.question.status === "Arrival"
                ? "Set to Responding"
                : questions.question.status === "Responding"
                ? "Set to Resolved"
                : "Set to Arrival"}
            </button>
            <button
              className="questioncard__button"
              onClick={() => deleteQuestion(questions.id)}
            >
              DELETE
            </button>
          </div>
        </li>
      );
    });
    return renderedQuestions;
  };

  return (
    <div className="userquestions">
      <div className="adminnav">
        <label className="adminnav__label" htmlFor="">
          Dashboard view:
        </label>
        <select
          className="adminnav__select"
          onChange={(e) => props.setCurrentTab(e.target.value)}
          name=""
          id=""
        >
          <option value="View questions">View questions</option>
          <option value="User report">User report</option>
          <option value="Cleaner data & info">Cleaner data & info</option>
          <option value="Employer data & info">Employer data & info</option>
          <option value="Verify users">Verify users</option>
        </select>
      </div>
      <ul className="userquestions__list">
        <div className="cleanerdata__titlebox">
          <h2 className="cleanerdata__title"></h2>
          <div className="cleanerdata__sortbox">
            <label className="cleanerdata__label" htmlFor="sortFilter">
              Sort by:
            </label>
            <select
              className="cleanerdata__select"
              name="sortFilter"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="desc">Newest</option>
              <option value="asc">Oldest</option>
            </select>
          </div>
        </div>
        {renderQuestions()}
        {questions.length > 0 ? (
          <div className="posts__pageBox">
            <button
              className="posts__pageLink posts__pageLink--left"
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              &lt; Prev
            </button>
            <div className="posts__displayPage">
              Page {pageNumber} of {Math.ceil(questions.length / postsPerPage)}
            </div>
            <button
              className="posts__pageLink posts__pageLink--right"
              onClick={() => setPageNumber(pageNumber + 1)}
              disabled={
                pageNumber === Math.ceil(questions.length / postsPerPage)
              }
            >
              Next &gt;
            </button>
          </div>
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
}

export default UserQuestions;
