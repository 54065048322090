import { firestore } from "../firebase.js";
import React from "react";
import Modal from "react-modal";
import "../Styles/Sass/AdminReviewModal.scss";

function AdminJobModal(props) {
  const deleteJob = (jobId) => {
    firestore
      .collection("jobs")
      .doc(jobId)
      .get()
      .then(async (res) => {
        let jobApplicants = res.data().applicants;
        await jobApplicants.forEach((applicant) => {
          firestore
            .collection("users")
            .doc(applicant.id)
            .get()
            .then((doc) => {
              let jobData = doc.data().jobs;
              let indexOfJob = jobData.findIndex((job) => {
                return job.jobId === job.jobId;
              });
              jobData.splice(indexOfJob, 1);
              firestore
                .collection("users")
                .doc(applicant.id)
                .update({
                  jobs: jobData,
                })
                .then((res) => {
                  console.log("success");
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
        firestore
          .collection("jobs")
          .doc(jobId)
          .delete()
          .then((res) => {
            console.log("Job Deleted");
            props.getJobPostings();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const suspendPosting = (jobId) => {
    firestore
      .collection("jobs")
      .doc(jobId)
      .update({
        suspended: true,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const unsuspendPosting = (jobId) => {
    firestore
      .collection("jobs")
      .doc(jobId)
      .update({
        suspended: false,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const renderPostings = () => {
    if (props.jobs.length === 0) {
      return (
        <>
          <h2 className="adminreviewmodal__na">No postings</h2>
          <button
            className="adminreviewmodal__nabutton"
            onClick={props.closeModal}
          >
            Close
          </button>
        </>
      );
    } else {
      let renderedPostings = props.jobs.map((job) => {
        return (
          <>
            <div className="adminjobcard">
              <div className="adminjobcard__titlebox">
                <h3 className="adminjobcard__title">{job.postedByName}</h3>
                <h4 className="adminjobcard__email">{props.userEmail}</h4>
              </div>
              <h4 className="adminjobcard__email">{job.headline}</h4>
              <p className="adminjobcard__text">{job.description}</p>
              <div className="adminjobcard__titlebox">
                <h5 className="adminjobcard__email">${job.rate} per hour</h5>
                <h5 className="adminjobcard__location">
                  {job.city} | {job.province} | {job.postal}
                </h5>
              </div>
              <div className="adminjobcard__titlebox">
                <button
                  className="adminjobcard__button"
                  onClick={
                    !job.suspended
                      ? () => suspendPosting(job.jobId)
                      : () => unsuspendPosting(job.jobId)
                  }
                >
                  {job.suspended ? "Unsuspend Job" : "Suspend Job"}
                </button>

                <button
                  className="adminjobcard__button"
                  onClick={() => deleteJob(job.jobId)}
                >
                  Delete job posting
                </button>
              </div>
            </div>
          </>
        );
      });
      return renderedPostings;
    }
  };

  return (
    <>
      {" "}
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        className="adminreviewmodal"
        overlayClassName="adminreviewmodal__overlay"
        contentLabel="Admin Review Modal"
      >
        <div className="adminreviewmodal__contentbox">{renderPostings()}</div>
      </Modal>
    </>
  );
}

export default AdminJobModal;
