import { firestore } from "../firebase.js";
import React from "react";
import AdminInitDataCard from "./AdminInitDataCard";
import iconSearch from "../Images/iconSearch.svg";
import "../Styles/Sass/CleanerData.scss";
import { CSVLink } from "react-csv";
function AdminInit(props) {
  const [userData, setUserData] = React.useState([]);
  const [userCSVData, setUserCSVData] = React.useState([]);
  const [partialUserCSVData, setPartialUserCSVData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("newest");
  const [searchValue, setSearchValue] = React.useState("");
  const [change, setChange] = React.useState(false);
  //   const [pageNumber, setPageNumber] = React.useState(1);
  //   const [postsPerPage, setPostsPerPage] = React.useState(5);

  React.useEffect(() => {
    getUserData();

    setChange(false);
  }, [change, sortBy]);

  const getUserData = () => {
    firestore
      .collection("users")
      .get()
      .then((res) => {
        let allEmployers = [];
        let csvData = [["Name", "Email", "Account type"]];
        let partialCSVData = [["Name", "Email"]];
        res.forEach((doc) => {
          let userDetail = doc.data();
          let joinDate = new Date(userDetail.joined).toString().split(" ");
          userDetail.id = doc.id;
          userDetail.joinDate = `${joinDate[1]} ${joinDate[2]} ${joinDate[3]}`;
          if (userDetail) {
            csvData.push([userDetail.name, userDetail.email, userDetail.job]);
            if (userDetail.job === "" || !userDetail.job) {
              partialCSVData.push([userDetail.name, userDetail.email]);
            }
            if (searchValue === "") {
              allEmployers.push(userDetail);
            } else if (
              searchValue !== "" &&
              userDetail.email.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              allEmployers.push(userDetail);
            }
          }
        });
        if (sortBy === "newest") {
          allEmployers = allEmployers.sort((a, b) => {
            return b.joined - a.joined;
          });
        } else if (sortBy === "oldest") {
          allEmployers = allEmployers.sort((a, b) => {
            return a.joined - b.joined;
          });
        }
        setUserData(allEmployers);
        setUserCSVData(csvData);
        setPartialUserCSVData(partialCSVData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderCleaners = () => {
    let renderedCleaners = userData.map((user) => {
      return (
        <AdminInitDataCard
          admin={user.admin}
          name={user.name}
          email={user.email}
          memberSince={user.joined}
          id={user.id}
          getData={getUserData}
          job="cleaner"
          joinDate={user.joinDate}
          headline={user.headline}
          description={user.description}
          city={user.city}
          province={user.province}
          postal={user.postal}
          rate={user.rate}
          getUserData={getUserData}
          setChange={setChange}
        />
      );
    });
    return renderedCleaners;
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchValue(e.target.search.value);
    setChange(true);
  };

  return (
    <section className="cleanerdata">
      <div className="adminnav">
        <label className="adminnav__label" htmlFor="">
          Dashboard view:
        </label>
        <select
          className="adminnav__select"
          onChange={(e) => props.setCurrentTab(e.target.value)}
          name=""
          id=""
        >
          <option value="Cleaner data & info">Cleaner data & info</option>
          <option value="View questions">View questions</option>
          <option value="User report">User report</option>
          <option value="Employer data & info">Employer data & info</option>
          <option value="Verify users">Verify users</option>
        </select>
      </div>
      <div className="cleanerdata__titlebox">
        <h2 className="cleanerdata__title">{userData.length} users found</h2>

        <div className="cleanerdata__downloadbox">
          <CSVLink className="cleanerdata__downloadbutton" data={userCSVData}>
            Download CSV (All users)
          </CSVLink>
          <CSVLink
            className="cleanerdata__downloadbutton"
            data={partialUserCSVData}
          >
            Download CSV (Partial users)
          </CSVLink>
          {/* <label className="cleanerdata__label" htmlFor="">
            Sort by:
          </label>
          <select
            className="cleanerdata__select"
            onChange={(e) => setSortBy(e.target.value)}
            name=""
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select> */}
        </div>
      </div>
      <form
        className="cleanerdata__searchbox"
        onSubmit={(e) => searchHandler(e)}
      >
        <input
          className="cleanerdata__input"
          name="search"
          type="text"
          placeholder="Search by name or email"
        />
        <button className="cleanerdata__search" type="submit">
          <img className="cleanerdata__icon" src={iconSearch} alt="" />
        </button>
      </form>
      {renderCleaners()}
    </section>
  );
}

export default AdminInit;
