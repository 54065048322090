import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { firestore } from "../firebase.js";
import ReviewModal from "./ReviewModal";
import imageQuote from "../Images/Home/quotes.svg";
import iconDelete from "../Images/iconDelete.svg";
import iconEdit from "../Images/iconEdit.svg";
import "../Styles/Sass/Testimonials.scss";
import { Link } from "react-router-dom";

function Testimonials(props) {
  const [currentUser, setCurrentUser] = React.useState({});
  const [currentEmail, setCurrentEmail] = React.useState({});
  const [userJob, setUserJob] = React.useState("");
  const [haveJobTogether, setHaveJobTogether] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userReviews, setUserReviews] = React.useState([]);
  const [editText, setEditText] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState(false);
  const [myPage, setMyPage] = React.useState(false);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 950, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  ];

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setEditText("");
    getUserData();
    setIsOpen(false);
  }

  React.useEffect(() => {
    if (props.page === "cleanerProfile") {
      getUserData();
      getHaveJobTogether();
      if (props.currentUser) {
        if (props.currentUser.uid === props.profileId) {
          setMyPage(true);
        }
      }
    } else if (props.page === "home" || props.page === "about") {
      getCompanyTestimonials();
    }
  }, [
    props.page,
    props.currentUser,
    props.userReview,
    props.userProfile,
    props.profileId,
  ]);

  //Get user data for profile owner
  const getUserData = () => {
    if (props.profileId) {
      firestore
        .collection("users")
        .doc(props.profileId)
        .get()
        .then((res) => {
          let userProfile = res.data();
          setUserReviews(userProfile.reviews);
          if (props.currentUser.uid) {
            let indexOfReview = userProfile.reviews.findIndex((review) => {
              return review.leftById === props.currentUser.uid;
            });
            if (indexOfReview != -1) {
              setReviewStatus(true);
              setEditText(userProfile.reviews[indexOfReview].review);
            }
          }
        })
        .then((res) => {
          firestore
            .collection("users")
            .doc(props.currentUser.uid)
            .get()
            .then((res) => {
              setUserJob();
              setCurrentUser(res.data().name);
              setCurrentEmail(res.data().email);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getHaveJobTogether = async () => {
    if (props.profileId && props.currentUser) {
      await firestore
        .collection("jobs")
        .where(
          "takenBy",
          "==",
          `${
            props.profileJob === "cleaner"
              ? props.profileId
              : props.currentUser.uid
          }`
        )
        .where(
          "postedBy",
          "==",
          `${
            props.profileJob === "employer"
              ? props.profileId
              : props.currentUser.uid
          }`
        )
        .get()
        .then((res) => {
          res.forEach((job) => {
            job.data() ? setHaveJobTogether(true) : setHaveJobTogether(false);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  //Get company testimonials
  const getCompanyTestimonials = () => {
    firestore
      .collection("companyTestimonials")
      .get()
      .then((res) => {
        let userReview = [];
        res.forEach((testimonial) => {
          userReview.push(testimonial.data());
        });
        setUserReviews(userReview);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Delete Testimonial
  const deleteTestimonial = () => {
    firestore
      .collection("users")
      .doc(props.profileId)
      .get()
      .then((res) => {
        let reviews = res.data().reviews;
        let indexOfReview = reviews.findIndex((review) => {
          return review.leftById === props.currentUser.uid;
        });
        reviews.splice(indexOfReview, 1);
        firestore
          .collection("users")
          .doc(props.profileId)
          .update({
            reviews: reviews,
          })
          .then((res) => {
            getUserData();
            setReviewStatus(false);
            setEditText("");
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };
  //View and edit testimonial
  const editTestimonial = (testimonial) => {
    setEditText(testimonial);
    openModal();
  };

  //Render testimonial cards
  const renderCards = () => {
    let renderedCards = userReviews.map((review) => {
      return (
        <div className="testCard" key={review.leftById}>
          <img className="testCard__quote" src={imageQuote} alt="" />
          {props.currentUser && props.currentUser.uid === review.leftById ? (
            <>
              <button
                className="testCard__button testCard__button--edit"
                onClick={() => editTestimonial(review.review)}
              >
                <img className="testCard__icon" src={iconEdit} alt="" />
              </button>
              <button
                className="testCard__button testCard__button--del"
                onClick={deleteTestimonial}
              >
                <img className="testCard__icon" src={iconDelete} alt="" />
              </button>
            </>
          ) : (
            <></>
          )}
          <p className="testCard__text">{review.review}</p>
          <h4 className="testCard__leftBy">{review.leftBy}</h4>
        </div>
      );
    });
    return renderedCards;
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    let pointer = type === consts.PREV ? "<" : ">";
    return (
      <button
        className={
          props.page === "home" || props.page === "about"
            ? "testimonials__carouselButton testimonials__carouselButton--hide"
            : "testimonials__carouselButton"
        }
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };
  console.log();
  return (
    <>
      {props.page === "cleanerProfile" && !props.currentUser ? (
        <></>
      ) : (
        <section
          className={
            props.page === "home"
              ? "testimonials"
              : "testimonials testimonials--about"
          }
        >
          <h3
            className={
              props.page === "home"
                ? "testimonials__title"
                : "testimonials__title testimonials__title--about"
            }
          >
            {props.title}
          </h3>
          {userReviews.length > 0 ? (
            <Carousel
              breakPoints={breakPoints}
              itemsToShow={3}
              renderArrow={myArrow}
              pagination={false}
            >
              {renderCards()}
            </Carousel>
          ) : (
            <div className="testCard">
              <img className="testCard__quote" src={imageQuote} alt="" />
              <p className="testCard__text">Be the first to leave a review!</p>
              <h4 className="testCard__text testCard__text--bold">
                No Reviews Yet
              </h4>
            </div>
          )}
          {props.page === "home" || props.page === "about" ? (
            <Link to="/findcleaner">
              <button className="testimonials__button">
                Find a Cleaner Now
              </button>
            </Link>
          ) : haveJobTogether ? (
            <>
              {reviewStatus === false ? (
                <button
                  className="testimonials__clickText"
                  onClick={openModal}
                  disabled={myPage}
                >
                  Leave a Review
                </button>
              ) : (
                <button
                  className="testimonials__clickText"
                  onClick={() => editTestimonial(editText)}
                >
                  Edit/View Review
                </button>
              )}
              <ReviewModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                getUserData={getUserData}
                currentUser={props.currentUser}
                editText={editText}
                profileId={props.profileId}
                currentUserName={currentUser}
                currentEmail={currentEmail}
              />
            </>
          ) : (
            <></>
          )}
        </section>
      )}
    </>
  );
}

export default Testimonials;
