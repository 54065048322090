import React from "react";
import '../../Styles/Sass/HomeHero.scss';
import heroImage from '../../Images/Home/hero.svg';
import heroImageMobile from '../../Images/Home/Nexus_Mobile.png';
import Navbar from '../Navbar';
import { UserContext } from "../../Providers/UserProvider";
import { useContext } from "react";
import LoginModal from './../SignUpModal';
import { Link } from "react-router-dom";

function HomeHero() {
  const user = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  function openModal() {
    setModalIsOpen(true);
  }


  function closeModal() {
    setModalIsOpen(false);
  }

  return (
      <section className="contHomeHero" >
        <LoginModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
        />
        <Navbar/>
        <img
          src={heroImage}
          alt="Cleaners cleaning office"
          className='homeHero__hero'
        />
        <img
          src={heroImageMobile}
          alt="Cleaners cleaning office"
          className='homeHero__heroMobile'
        />
        <div className="homeHero">
          <h6 className="homeHero__title">
            Give your place the clean it deserves
          </h6>
          <p className="homeHero__list">
           Find a cleaner in your area today
          </p>
          <>
            {
              user === null ?
              <>
                <button className='homeHero__button' onClick={openModal}>Explore Now</button>
                <p className="homeHero__subtext">
                  Looking for a cleaning job? <a className ='homeHero__link' onClick={openModal}>Sign Up</a>
                </p> 
              </>
              :
              <>
              <Link to="/findcleaner">
                <button className='homeHero__button' >Explore Now</button>
              </Link>
              </>
            }
          </>  
        </div>
      </section>

  );
}

export default HomeHero;

