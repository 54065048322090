import React, { useState } from "react";
import { Link } from "react-router-dom";
import JobDataModal from "./JobDataModal";
import { firestore } from "../firebase.js";
export default function JobDataCard(props) {
  const [jobModal, setJobModal] = useState(false);
  function suspendHandle() {
    firestore
      .collection("jobs")
      .doc(props.jobId)
      .update({
        suspended: true,
      })
      .then((res) => {
        props.setChanges(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function unsuspendHandle() {
    firestore
      .collection("jobs")
      .doc(props.jobId)
      .update({
        suspended: false,
      })
      .then((res) => {
        props.setChanges(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <JobDataModal
        modalIsOpen={jobModal}
        closeModal={() => setJobModal(false)}
        jobDetail={props.jobDetails}
      />
      <li className="userdatacard">
        <div className="userdatacard__row">
          <h3 className="userdatacard__title">{props.headline}</h3>
          <Link to={`/profile/${props.postedBy}`}>
            <button className="userdatacard__button">
              Posted by: {props.postedByName}{" "}
            </button>
          </Link>
        </div>
        <div className="userdatacard__row">
          <h3 className="userdatacard__subtitle">
            {props.applicants.length} applicants{" "}
          </h3>
          {props.suspended == false ? (
            <button
              className="userdatacard__delete"
              onClick={() => suspendHandle()}
            >
              Suspend job
            </button>
          ) : (
            <button
              className="userdatacard__delete"
              onClick={() => unsuspendHandle()}
            >
              Unsuspend job
            </button>
          )}
        </div>
        <div className="userdatacard__row">
          {/* {props.takenBy != "open" ? (
          <Link to={`/profile/${props.takenBy}`}>
            <button className="userdatacard__button">Taken by </button>
          </Link>
        ) : (
          <button className="userdatacard__button">Taken by: TBA</button>
        )} */}

          <button
            className="userdatacard__button"
            onClick={() => setJobModal(true)}
          >
            View posting
          </button>
        </div>
      </li>
    </>
  );
}
