import { firestore } from '../firebase.js';
import React from "react";
import Modal from 'react-modal';
import '../Styles/Sass/AdminReviewModal.scss';

function AdminJobModal(props) {

	const deleteInformation = () => {
		firestore.collection('users').doc(props.id).update({
			headline: '',
			description: ''
		})
			.then(res => {
				props.getCleanerData();
			})
			.catch(err => {
				console.log(err);
			});
	}

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className='adminreviewmodal'
      overlayClassName='adminreviewmodal__overlay'
      contentLabel="Admin Review Modal"
    >
      <div className='adminreviewmodal__contentbox'>
      	<div className='adminjobcard'>
					<div className='adminjobcard__titlebox'>
						<h3 className='adminjobcard__title'>{props.name}</h3>
						<h4 className='adminjobcard__email'>{props.userEmail}</h4>
					</div>
					<h4 className='adminjobcard__email'>{props.headline}</h4>
					<p className='adminjobcard__text'>{props.description}</p>
					<div className='adminjobcard__titlebox'>
						<h5 className='adminjobcard__email'>${props.rate} per hour</h5>
						<h5 className='adminjobcard__location'>{props.city} | {props.province} | {props.postal}</h5>
					</div>
					<button
						className='adminjobcard__button'
						onClick={deleteInformation}
					>
						Delete information
					</button>
      	</div>
      </div>
    </Modal>
  );
}

export default AdminJobModal;