import React from "react";
import createCheckoutSession from "../stripe/createCheckoutSession";
import usePremiumStatus from "../stripe/usePremiumStatus";
import { goToBillingPortal } from "../stripe/createPortalLink";
import { firestore } from "../firebase.js";
import "../Styles/Sass/PaymentDetail.scss";

function PaymentDetail(props) {
  const [userAffiliate, setUserAffiliate] = React.useState(false);
  const [isEmployer, setIsEmployer] = React.useState(false);
  React.useEffect(() => {
    if (props.user) {
      checkUserAffiliate();
    }
  }, [props.user, userAffiliate]);

  const checkUserAffiliate = () => {
    firestore
      .collection("users")
      .doc(props.user.uid)
      .get()
      .then((res) => {
        if (res.data().affiliate) {
          setUserAffiliate(true);
        }
        if (res.data().job === "employer") {
          setIsEmployer(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userIsPremium = usePremiumStatus(props.user);

  return (
    <>
      <section className="paymentheader">
        <h2 className="paymentheader__title">Payment Page</h2>
      </section>
      <section className="paymentdetail">
        <h3 className="paymentdetail__subtitle">Subscription Plans</h3>
        <h4 className="paymentdetail__description">
          Subscription Plans are for Employers.
          <strong> Each Job post lasts 30 days.</strong>
        </h4>
        <div className="paymentdetail__cardlist">
          <div className="paymentcard paymentcard--post">
            <h4 className="paymentcard__title">1 day</h4>
            <p className="paymentcard__rate">
              {userAffiliate ? "$8.10" : "$9.00"}
              {userAffiliate ? (
                <span className="paymentcard__rate--discount"> (10% Off)</span>
              ) : (
                <></>
              )}
            </p>
            <p className="paymentcard__description">Unlimited job postings</p>
            <button
              className="paymentcard__button"
              onClick={
                userAffiliate
                  ? () => createCheckoutSession(props.user.uid, "dailyAff")
                  : () => createCheckoutSession(props.user.uid, "daily")
              }
              disabled={!props.user || userIsPremium || !isEmployer}
            >
              {!userIsPremium ? "Buy Now" : "Subscription Active"}
            </button>
          </div>
          <div className="paymentcard">
            <h4 className="paymentcard__title">3 days</h4>
            <p className="paymentcard__rate">
              {userAffiliate ? "$20.70" : "$23.00"}
              {userAffiliate ? (
                <span className="paymentcard__rate--discount"> (10% Off)</span>
              ) : (
                <></>
              )}
            </p>
            <p className="paymentcard__description">Unlimited job postings</p>
            <button
              className="paymentcard__button"
              onClick={
                userAffiliate
                  ? () => createCheckoutSession(props.user.uid, "threeDaysAff")
                  : () => createCheckoutSession(props.user.uid, "threeDays")
              }
              disabled={!props.user || userIsPremium || !isEmployer}
            >
              {!userIsPremium ? "Buy Now" : "Subscription Active"}
            </button>
          </div>
        </div>
        <div className="paymentdetail__cardlist">
          <div className="paymentcard">
            <h4 className="paymentcard__title">1 week</h4>
            <p className="paymentcard__rate">
              {userAffiliate ? "$45.90" : "$51.00"}
              {userAffiliate ? (
                <span className="paymentcard__rate--discount"> (10% Off)</span>
              ) : (
                <></>
              )}
            </p>
            <p className="paymentcard__description">Unlimited job postings</p>
            <button
              className="paymentcard__button"
              onClick={
                userAffiliate
                  ? () => createCheckoutSession(props.user.uid, "oneWeekAff")
                  : () => createCheckoutSession(props.user.uid, "oneWeek")
              }
              disabled={!props.user || userIsPremium || !isEmployer}
            >
              {!userIsPremium ? "Buy Now" : "Subscription Active"}
            </button>
          </div>
        </div>
        {userIsPremium ? (
          <button
            className="paymentdetail__button"
            onClick={() => goToBillingPortal(props.user.uid)}
          >
            VIEW BILLING
          </button>
        ) : (
          <></>
        )}
      </section>
    </>
  );
}

export default PaymentDetail;
