import imageLogo from "../../Images/Home/CC_SubmarkLogo_Colour.svg";
import "../../Styles/Sass/WhoWeAre.scss";
import { Link } from "react-router-dom";
function WhoWeAre() {
  return (
    <section className="whoWeAre">
      <div className="whoWeAre__textBox">
        <h2 className="whoWeAre__title">WHO WE ARE</h2>
        <p className="whoWeAre__text">
          Who are we? We are you, like-minded individuals that share the common
          goal of hiring or becoming hired to clean at the highest level. Clean
          Connection is a platform focused on finding quality cleaning
          candidates while maintaining accountability from both parties. We hold
          each other accountable to the highest of standards in home and
          establishment cleaning services.<p></p>Welcome to Clean Connection.
        </p>
        <Link to="/about">
          <button className="whoWeAre__button">Learn More</button>
        </Link>
      </div>
      <img className="whoWeAre__image" src={imageLogo} alt="" />
    </section>
  );
}

export default WhoWeAre;
