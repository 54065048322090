import React from "react";
import "../../Styles/Sass/Contact.scss";
import { firestore } from "../../firebase.js";

import { UserContext } from "../../Providers/UserProvider";
import AlertModal from "../AlertModal";
import Modal from "react-modal";
import { useContext, useState } from "react";
function Contact() {
  /*	On submit nameForm, emailForm and messageForm to the contactForm collection
			Issues: 
		*/
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }
  const submitHandler = (e) => {
    e.preventDefault();
    let currentTime = Date.now();
    firestore
      .collection("contactForm")
      .add({
        name: name,
        email: email,
        message: message,
        time: currentTime,
        status: "Arrival",
      })
      .then(() => {
        setAlertText("Thank you! Your message has been submitted");
        openAlert();
      })
      .catch((error) => {
        setAlertText(error.message);
        openAlert();
      });
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      {/*Alert Modal */}
      <Modal
        isOpen={alertOpen}
        className="alertmodal"
        onRequestClose={closeAlert}
        overlayClassName="alertmodal__overlay"
        contentLabel="Free Post Modal"
      >
        <div className="alertmodal__contentbox">
          <h4>{alertText}</h4>
          <button onClick={closeAlert} className={"alertmodal__button"}>
            Close
          </button>
        </div>
      </Modal>
      <section className="contact">
        <h2 className="contact__title">Contact Us</h2>
        <form onSubmit={submitHandler} className="contactForm">
          <label className="contactForm__label" htmlFor="name" required>
            Enter Your Name *
          </label>
          <input
            className="contactForm__input"
            name="name"
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label className="contactForm__label" htmlFor="email" required>
            Enter Your Email *
          </label>
          <input
            className="contactForm__input"
            name="email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className="contactForm__label" htmlFor="message">
            Enter Your Message *
          </label>
          <textarea
            className="contactForm__input contactForm__input--area"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit" className="greenButton contactForm__button">
            Submit
          </button>
        </form>
      </section>
    </>
  );
}

export default Contact;

// const user = useContext(UserContext);
// const submitHandler = (e) => {
// 	e.preventDefault();
// 	firestore.collection('contactForm').doc(user.uid)
// 	  .update({
// 		nameForm: e.target.name.value,
// 		emailForm: e.target.email.value,
// 		messageForm: e.target.message.value,
// 	  })
// 	  .then(res => {
// 		console.log(res);
// 	  })
// 	  .catch(err => {
// 		console.log(err);
// 	  })
//   }
