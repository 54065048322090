import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Listing from "../Components/JobListing/Listing";
import Filter from "../Components/JobListing/Filters";
import { UserContext } from "../Providers/UserProvider";
import { useContext, useEffect, useState } from "react";
import "../Styles/Sass/FindJob.scss";
import LoginModal from "../Components/SignUpModal";

function FindAJob() {
  const [jobFilter, setJobFilter] = React.useState({
    keyword: "",
    areaOfWork: [],
    requiredServices: [],
    availability: [],
    jobType: [],
    numberOfWorkers: 0,
    supplies: false,
    equipment: false,
    rate: 150,
    negotiable: false,
    province: "",
    city: "",
    postal: "",
    placeId: "",
    latLng: {},
    distance: 10,
  });
  const [triggerUpdate, setTriggerUpdate] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const user = useContext(UserContext);

  const setFilter = (filter, value) => {
    let currentFilter = jobFilter;
    if (filter === "supplies") {
      currentFilter.supplies = !currentFilter.supplies;
    } else if (filter === "keyword") {
      currentFilter.keyword = value;
    } else if (filter === "equipment") {
      currentFilter.equipment = !currentFilter.equipment;
    } else if (filter === "negotiable") {
      currentFilter.negotiable = !currentFilter.negotiable;
    } else if (filter === "numberOfWorkers" || filter === "rate") {
      currentFilter[filter] = value;
    } else if (filter === "city" || filter === "province") {
      currentFilter[filter] = value;
    } else {
      let indexOfValue = currentFilter[filter].findIndex((filterValue) => {
        return value === filterValue;
      });
      if (indexOfValue === -1) {
        currentFilter[filter].push(value);
      } else {
        currentFilter[filter].splice(indexOfValue, 1);
      }
    }
    setJobFilter(currentFilter);
    setTriggerUpdate(!triggerUpdate);
  };

  const setLocationFilter = (
    city,
    province,
    postal,
    placeId,
    latLng,
    distance
  ) => {
    let currentFilter = jobFilter;
    currentFilter.city = city;
    currentFilter.province = province;
    currentFilter.postal = postal;
    currentFilter.distance = distance;
    currentFilter.placeId = placeId;
    currentFilter.latLng = latLng;
    setJobFilter(currentFilter);
    setTriggerUpdate(!triggerUpdate);
  };

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className="findJob">
      <LoginModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
      <Navbar page="findcleaner" />
      <div
        className={`findJob__banner ${
          scrollY > 0 ? "findJob__banner--scrolling" : ""
        }`}
      >
        <h1>Find a Cleaner</h1>
      </div>
      <div className="findJob__postBox">
        <Filter
          jobFilter={jobFilter}
          setFilter={setFilter}
          setLocationFilter={setLocationFilter}
          page={"findCleaner"}
        />
        <Listing
          user={user}
          jobFilter={jobFilter}
          triggerUpdate={triggerUpdate}
          page={"findCleaner"}
        />
      </div>
      <Footer />
    </section>
  );
}

export default FindAJob;
