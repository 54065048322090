import "../../Styles/Sass/CleanerDetail.scss";

function CleanerDetail(props) {
  const concatArray = (array) => {
    let returnString = "";
    if (array) {
      array.forEach((word) => {
        returnString = returnString.concat(
          `${word[0].toUpperCase() + word.slice(1)} `
        );
        returnString = returnString.replace(/[ ,]+/g, ", ");
      });
      returnString = returnString.slice(0, returnString.length - 2);
      return returnString;
    }
  };

  return (
    <>
      {props.user ? (
        <section className="cleanerdetail">
          {props.description ? (
            <>
              {" "}
              <h3 className="cleanerdetail__text cleanerdetail__text--subtitle">
                Description:
              </h3>
              <p className="cleanerdetail__text cleanerdetail__text--detail">
                {props.description}
              </p>
            </>
          ) : (
            <></>
          )}

          <h3 className="cleanerdetail__text cleanerdetail__text--subtitle">
            Area of Work:
          </h3>
          <p className="cleanerdetail__text cleanerdetail__text--detail">
            {props.areaOfWork
              ? concatArray(props.areaOfWork)
              : "Incomplete Profile"}
          </p>
          <h3 className="cleanerdetail__text cleanerdetail__text--subtitle">
            Service Provided:
          </h3>
          <p className="cleanerdetail__text cleanerdetail__text--detail">
            {props.services
              ? concatArray(props.services)
              : "Incomplete Profile"}
          </p>
          <h3 className="cleanerdetail__text cleanerdetail__text--subtitle">
            Availability:
          </h3>
          <p className="cleanerdetail__text cleanerdetail__text--detail">
            {props.availability
              ? concatArray(props.availability)
              : "Incomplete Profile"}
          </p>
          <h3 className="cleanerdetail__text cleanerdetail__text--subtitle">
            Equipment/Supplies:
          </h3>
          <p className="cleanerdetail__text cleanerdetail__text--detail">
            {props.equipment === "true"
              ? "Owns Equipment"
              : "Requires Equipment"}
            /{props.supplies === "true" ? "Owns Supplies" : "Requires Supplies"}
          </p>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
export default CleanerDetail;
