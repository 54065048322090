import React from "react";
import isUserPremium from "./isUserPremium";

export default function UserPremiumStatus(props) {
  const [premiumStatus, setPremiumStatus] = React.useState(false);

  React.useEffect(() => {
    if (props) {
      const checkPremiumStatus = async function () {
        setPremiumStatus(await isUserPremium(props));
      };
      checkPremiumStatus();
    }
  }, [props]);

  return premiumStatus;
}
