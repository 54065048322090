import getStripe from "./initializeStripe";
import { firestore } from "../firebase.js";

export default async function createCheckoutSession(uid, plan) {
  let priceId = "";
  let mode = "";
  if (plan === "daily") {
    priceId = "price_1MgrweKBQTWM7dQg0xj5wlNq";
    mode = "payment";
  } else if (plan === "threeDays") {
    priceId = "price_1MOAG2KBQTWM7dQgmi9uwn3U";
    mode = "subscription";
  } else if (plan === "oneWeek") {
    priceId = "price_1MOAG7KBQTWM7dQgOBuLvIOv";
    mode = "subscription";
  } else if (plan === "dailyAff") {
    priceId = "price_1MPYenKBQTWM7dQgCxcD7hds";
    mode = "payment";
  } else if (plan === "threeDaysAff") {
    priceId = "price_1MPYfUKBQTWM7dQgLph0YBJk";
    mode = "subscription";
  } else if (plan === "oneWeekAff") {
    priceId = "price_1MPYghKBQTWM7dQgC1naTFc7";
    mode = "subscription";
  }

  const checkoutSessionRef = await firestore
    .collection("users")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: `${priceId}`,
      mode: `${mode}`,
      success_url: window.location.origin + "/dashboard",
      cancel_url: window.location.origin + "/dashboard",
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    console.log(snap.data());
    const sessionId = { sessionId: snap.data().sessionId };
    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout(sessionId);
    }
  });
}
