import { firestore } from "../firebase.js";
import React from "react";
import DataCard from "./DataCard";
import iconSearch from "../Images/iconSearch.svg";
import "../Styles/Sass/CleanerData.scss";
import JobDataCard from "./JobDataCard.js";

function JobData(props) {
  const [jobData, setJobData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("newest");
  const [searchValue, setSearchValue] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(5);
  const [changes, setChanges] = React.useState(false);
  React.useEffect(() => {
    getJobPostings();
    setChanges(false);
  }, [changes, searchValue]);
  const getJobPostings = () => {
    firestore
      .collection("jobs")
      .get()
      .then((res) => {
        let allJobs = [];
        res.forEach((doc) => {
          if (searchValue === "") {
            allJobs.push(doc.data());
          } else if (
            searchValue !== "" &&
            (doc
              .data()
              .headline.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
              doc
                .data()
                .postedByName.toLowerCase()
                .includes(searchValue.toLowerCase()))
          ) {
            allJobs.push(doc.data());
          }
        });
        setJobData(allJobs);
      });
  };
  const renderedJobs = () => {
    let renderedJobs = jobData.map((job) => {
      return (
        <>
          <JobDataCard
            applicants={job.applicants}
            headline={job.headline}
            postedByName={job.postedByName}
            jobId={job.jobId}
            postedBy={job.postedBy}
            takenBy={job.takenBy}
            suspended={job.suspended}
            jobDetails={job}
            setChanges={setChanges}
          />
        </>
      );
    });
    return renderedJobs;
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchValue(e.target.search.value);
  };

  return (
    <section className="cleanerdata">
      <div className="adminnav">
        <label className="adminnav__label" htmlFor="">
          Dashboard view:
        </label>
        <select
          className="adminnav__select"
          onChange={(e) => props.setCurrentTab(e.target.value)}
          name=""
          id=""
        >
          <option value="Cleaner data & info">Cleaner data & info</option>
          <option value="View questions">View questions</option>
          <option value="User report">User report</option>
          <option value="Employer data & info">Employer data & info</option>
          <option value="Verify users">Verify users</option>
        </select>
      </div>
      <div className="cleanerdata__titlebox">
        <h2 className="cleanerdata__title">{jobData.length} jobs</h2>
        {/* <div className="cleanerdata__sortbox">
          <label className="cleanerdata__label" htmlFor="sortFilter">
            Sort by:
          </label>
          <select
            className="cleanerdata__select"
            name="sortFilter"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div> */}
      </div>
      <form
        className="cleanerdata__searchbox"
        onSubmit={(e) => searchHandler(e)}
      >
        <input
          className="cleanerdata__input"
          type="text"
          name="search"
          placeholder="Search by job title or name"
        />
        <button className="cleanerdata__search" type="submit">
          <img className="cleanerdata__icon" src={iconSearch} alt="" />
        </button>
      </form>
      {renderedJobs()}
    </section>
  );
}

export default JobData;
