import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import OurStory from "../Components/About/OurStory";
import OurValues from "../Components/About/OurValues";
import Testimonials from "../Components/Testimonials";
import Contact from "../Components/About/Contact";
import { Fragment } from "react";

function AboutUs(){
  return(
    <>
      <Navbar/>
      <OurStory/>
      <OurValues/>
      <Testimonials
        page={"about"}
        title={"We Specialize in Good Clean Connections"}
      />
      <Contact/>
      <Footer/>
    </>
  );
}

export default AboutUs;