import React, { useContext } from "react";
import { UserContext } from "../Providers/UserProvider";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import DashBoardInfo from '../Components/DashBoardInfo';
import AssignJob from '../Components/AssignJob';
import ViewMyJobs from '../Components/ViewMyJobs';
import Profile from '../Components/Profile';

function UserDashboard(){
  const [openFilter, setOpenFilter] = React.useState(false);
  const [viewJobs, setViewJobs] = React.useState(true);

  const user = useContext(UserContext);

  return(
    <>
      <Navbar />
      { (user)
        ?
          <>
            <Profile
              user={user}
              page={'dashboard'}
            />
            <DashBoardInfo
              user={user}
              setViewJobs={setViewJobs}
              viewJobs={viewJobs}
            />
            {
              viewJobs === true ?
              <>
                <AssignJob
                  user={user}
                  setOpenFilter={setOpenFilter}
                  openFilter={openFilter}
                />
                <ViewMyJobs
                  user={user}
                />
              </> :
              <></>
            }
          </>
        :
          <>
          </>
      }
      <Footer />
    </>
  );
}

export default UserDashboard;