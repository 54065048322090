import imageLoyalty from '../../Images/About/loyalty.svg'
import imageIntegrity from '../../Images/About/integrity.svg'
import imageDecisiveness from '../../Images/About/decisiveness.svg'
import imageHonestpay from '../../Images/About/honestpay.svg'
import '../../Styles/Sass/OurValues.scss';

function OurValues() {
  return (
    <section className='ourValues'>
    	<h2 className='ourValues__title'>Our Values</h2>
    	<ul className='ourValues__list'>
    		<li className='ourCard'>
    			<img className='ourCard__image' src={imageLoyalty} alt="" />
    			<h3 className='ourCard__title'>Transparency</h3>
    			<p className='ourCard__description'>Clean Connection stands by you, providing the tool box to ensure you have what you need to meet your goals</p>
    		</li>
    		<li className='ourCard'>
    			<img className='ourCard__image ourCard__image--integrity' src={imageIntegrity} alt="" />
    			<h3 className='ourCard__title'>Integrity</h3>
    			<p className='ourCard__description'>We focus on ensuring clarity between both parties for a smooth transaction</p>
    		</li>
    		<li className='ourCard'>
    			<img className='ourCard__image' src={imageDecisiveness} alt="" />
    			<h3 className='ourCard__title'>Decisiveness</h3>
    			<p className='ourCard__description'>We’ve created profiles with reviews, experience and more to make your decision making easy</p>
    		</li>
    		<li className='ourCard'>
    			<img className='ourCard__image ourCard__image--honestpay' src={imageHonestpay} alt="" />
    			<h3 className='ourCard__title'>Honest Pay</h3>
    			<p className='ourCard__description'>We believe in living wages. This is why we expect an above average minimum hourly wage</p>
    		</li>
    	</ul>
    </section>
  );
}

export default OurValues;
