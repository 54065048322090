import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Slider from "@material-ui/core/Slider";
import "../../Styles/Sass/Listings/Filter/filter.scss";
import Modal from "react-modal";
function valuetext(value) {
  return `${value}`;
}

function Filter(props) {
  const [byKeyword, setByKeyword] = React.useState("");
  const [currentRate, setCurrentRate] = React.useState(150);
  const [city, setCity] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [postal, setPostal] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [latLng, setLatLng] = React.useState({});
  const [distance, setDistance] = React.useState(10);
  const [alertText, setAlertText] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }
  const setBudget = (e) => {
    e.preventDefault();
    props.setFilter("rate", e.target.rate.value);
  };

  const searchOptions = {
    componentRestrictions: { country: "CA" },
    types: ["(cities)"],
  };

  const searchOptionsTwo = {
    componentRestrictions: { country: "CA" },
    types: ["(regions)"],
  };

  const handleSelect = async (value) => {
    setCity(value.split(",")[0]);
  };

  const handleSelectTwo = async (value) => {
    const results = await geocodeByAddress(value);
    const resultLatLng = await getLatLng(results[0]);
    if (
      results[0].types.includes("postal_code") &&
      results[0].types.length === 1
    ) {
      setPlaceId(results[0].place_id);
      setPostal(
        value.split(",")[1].split(" ")[2] +
          " " +
          value.split(",")[1].split(" ")[3]
      );
      setLatLng(resultLatLng);
    } else {
      setPostal("");
      setAlertText("Please enter valid full postal code");
      openAlert();
    }
  };

  const resetLocation = () => {
    setCity("");
    setProvince("");
    setPostal("");
    setPlaceId("");
    setLatLng({});
    setDistance(10);
    props.setLocationFilter("", "", "", "", "", 0);
  };

  const setProvinceFilter = (value) => {
    if (value === "ALL") {
      setProvince("");
    } else {
      setProvince(value);
    }
  };

  const setKeywordFilter = (e) => {
    e.preventDefault();
    props.setFilter("keyword", byKeyword);
  };

  const resetKeywordFilter = () => {
    setByKeyword("");
    props.setFilter("keyword", "");
  };

  return (
    <div className="filter-BG">
      <Modal
        isOpen={alertOpen}
        className="alertmodal"
        onRequestClose={closeAlert}
        overlayClassName="alertmodal__overlay"
        contentLabel="Free Post Modal"
      >
        <div className="alertmodal__contentbox">
          <h4>{alertText}</h4>
          <button onClick={closeAlert} className={"alertmodal__button"}>
            Close
          </button>
        </div>
      </Modal>
      <div className="filterOptions">
        <form className="filterSearch" onSubmit={(e) => setKeywordFilter(e)}>
          <h3 className="filterSearch__title">Search For:</h3>
          <input
            className="search"
            type="text"
            name="keyword"
            placeholder="Search by Keyword..."
            value={byKeyword}
            onChange={(e) => setByKeyword(e.target.value)}
          />
          <button className="search__button" type="submit">
            Set Keyword
          </button>
          <button className="search__button" onClick={resetKeywordFilter}>
            Reset
          </button>
        </form>
        <div className="filterSearch">
          <h3 className="filterSearch__title">Location:</h3>
          <label htmlFor="commercial"> City</label>
          <PlacesAutocomplete
            value={city}
            onChange={setCity}
            onSelect={handleSelect}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input {...getInputProps({ className: "search" })} />
                <div>
                  {loading ? <div>...loading</div> : null}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "search--active search"
                      : "search";
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <label htmlFor="commercial"> Province</label>
          <form onChange={(e) => setProvinceFilter(e.target.value)} action="">
            <select name="provinceSelect" className="search">
              <option value="ALL">All</option>
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland and Labrador</option>
              <option value="NT">Northwest Territories</option>
              <option value="NS">Nova Scotia</option>
              <option value="NU">Nunavut</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="YT">Yukon</option>
            </select>
          </form>
          <label htmlFor="commercial"> Postal Code</label>
          <form className="filterSearch__postalBox">
            <PlacesAutocomplete
              value={postal}
              onChange={setPostal}
              onSelect={handleSelectTwo}
              searchOptions={searchOptionsTwo}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <>
                  <div className="filterSearch__postalInputBox">
                    <input
                      {...getInputProps({
                        className: "filterSearch__postalInput",
                      })}
                    />
                    {/*<p className='filterSearch__division'>|</p>*/}
                    <div className="filterSearch__division"></div>
                    <select
                      onChange={(e) => setDistance(e.target.value)}
                      name="distance"
                      className="filterSearch__distance"
                    >
                      <option value={10}>Within 10km</option>
                      <option value={25}>Within 25km</option>
                      <option value={50}>Within 50km</option>
                    </select>
                  </div>
                  <div>
                    {loading ? <div>...loading</div> : null}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "search--active search"
                        : "search";
                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </PlacesAutocomplete>
          </form>
          <button
            className="search__button"
            onClick={() =>
              props.setLocationFilter(
                city,
                province,
                postal,
                placeId,
                latLng,
                distance
              )
            }
          >
            Set Location
          </button>
          <button className="search__button" onClick={resetLocation}>
            All
          </button>
        </div>
        <div className="filterSearch">
          <h3 className="filterSearch__title">Area of Work:</h3>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="home"
              defaultChecked={props.jobFilter.areaOfWork.includes("home")}
              onChange={() => props.setFilter("areaOfWork", "home")}
            />
            <label htmlFor="home"> Home</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="commercial"
              defaultChecked={props.jobFilter.areaOfWork.includes("commercial")}
              onChange={() => props.setFilter("areaOfWork", "commercial")}
            />
            <label htmlFor="commercial"> Commercial</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="restaurant"
              defaultChecked={props.jobFilter.areaOfWork.includes("restaurant")}
              onChange={() => props.setFilter("areaOfWork", "restaurant")}
            />
            <label htmlFor="restaurant"> Restaurant</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="industrial"
              defaultChecked={props.jobFilter.areaOfWork.includes("industrial")}
              onChange={() => props.setFilter("areaOfWork", "industrial")}
            />
            <label htmlFor="industrial"> Industrial</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="project"
              defaultChecked={props.jobFilter.areaOfWork.includes("project")}
              onChange={() => props.setFilter("areaOfWork", "project")}
            />
            <label htmlFor="project"> Project</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="events"
              defaultChecked={props.jobFilter.areaOfWork.includes("events")}
              onChange={() => props.setFilter("areaOfWork", "events")}
            />
            <label htmlFor="events"> Events</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="airbnb"
              defaultChecked={props.jobFilter.areaOfWork.includes("airbnb")}
              onChange={() => props.setFilter("areaOfWork", "airbnb")}
            />
            <label htmlFor="airbnb"> Air BNB</label>
          </div>
        </div>
        <div className="filterSearch">
          {props.page !== "findCleaner" ? (
            <h3 className="filterSearch__title">Services</h3>
          ) : (
            <h3 className="filterSearch__title">
              Able to service the following
            </h3>
          )}
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="offices"
              defaultChecked={props.jobFilter.requiredServices.includes(
                "offices"
              )}
              onChange={() => props.setFilter("requiredServices", "offices")}
            />
            <label htmlFor="offices"> Offices</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="washrooms"
              defaultChecked={props.jobFilter.requiredServices.includes(
                "washrooms"
              )}
              onChange={() => props.setFilter("requiredServices", "washrooms")}
            />
            <label htmlFor="washrooms"> Washrooms</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="kitchen"
              defaultChecked={props.jobFilter.requiredServices.includes(
                "kitchen"
              )}
              onChange={() => props.setFilter("requiredServices", "kitchen")}
            />
            <label htmlFor="kitchen"> Kitchen</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="specialty"
              defaultChecked={props.jobFilter.requiredServices.includes(
                "specialty"
              )}
              onChange={() => props.setFilter("requiredServices", "specialty")}
            />
            <label htmlFor="specialty"> Specialty</label>
          </div>

          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="carpet"
              defaultChecked={props.jobFilter.requiredServices.includes(
                "carpet"
              )}
              onChange={() => props.setFilter("requiredServices", "carpet")}
            />
            <label htmlFor="carpet"> Carpet</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="floorCare"
              defaultChecked={props.jobFilter.requiredServices.includes(
                "floorCare"
              )}
              onChange={() => props.setFilter("requiredServices", "floorCare")}
            />
            <label htmlFor="floorCare"> Floor Care</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="window"
              defaultChecked={props.jobFilter.requiredServices.includes(
                "window"
              )}
              onChange={() => props.setFilter("requiredServices", "window")}
            />
            <label htmlFor="window"> Window</label>
          </div>
        </div>
        <div className="filterSearch">
          <h3 className="filterSearch__title">Job Availablity:</h3>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="mornings"
              defaultChecked={props.jobFilter.availability.includes("mornings")}
              onChange={() => props.setFilter("availability", "mornings")}
            />
            <label htmlFor="mornings"> Mornings</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="days"
              defaultChecked={props.jobFilter.availability.includes("days")}
              onChange={() => props.setFilter("availability", "days")}
            />
            <label htmlFor="days"> Days</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="evenings"
              defaultChecked={props.jobFilter.availability.includes("evenings")}
              onChange={() => props.setFilter("availability", "evenings")}
            />
            <label htmlFor="evenings"> Evenings</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="nights"
              defaultChecked={props.jobFilter.availability.includes("nights")}
              onChange={() => props.setFilter("availability", "nights")}
            />
            <label htmlFor="nights"> Nights</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="weekends"
              defaultChecked={props.jobFilter.availability.includes("weekends")}
              onChange={() => props.setFilter("availability", "weekends")}
            />
            <label htmlFor="weekends"> Weekends</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="smallProjects"
              defaultChecked={props.jobFilter.availability.includes(
                "smallProjects"
              )}
              onChange={() => props.setFilter("availability", "smallProjects")}
            />
            <label htmlFor="smallProjects"> Small Projects</label>
          </div>
        </div>
        {props.page !== "findCleaner" ? (
          <div className="filterSearch">
            <h3 className="filterSearch__title">Job Type:</h3>
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="checkbox"
                name="fullTime"
                defaultChecked={props.jobFilter.jobType.includes("fullTime")}
                onChange={() => props.setFilter("jobType", "fullTime")}
              />
              <label htmlFor="fullTime"> Full Time</label>
            </div>
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="checkbox"
                name="partTime"
                defaultChecked={props.jobFilter.jobType.includes("partTime")}
                onChange={() => props.setFilter("jobType", "partTime")}
              />
              <label htmlFor="partTime"> Part Time</label>
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.page !== "findCleaner" ? (
          <div className="filterSearch">
            {props.page !== "findCleaner" ? (
              <h3 className="filterSearch__title">Cleaners Required:</h3>
            ) : (
              <h3 className="filterSearch__title">Cleaners Available:</h3>
            )}
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="radio"
                name="numberOfWorkers"
                defaultChecked={props.jobFilter.numberOfWorkers === 0}
                onChange={() => props.setFilter("numberOfWorkers", 0)}
              />
              <label htmlFor="one"> All</label>
            </div>
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="radio"
                name="numberOfWorkers"
                defaultChecked={props.jobFilter.numberOfWorkers === 1}
                onChange={() => props.setFilter("numberOfWorkers", 1)}
              />
              <label htmlFor="one"> 1 Person</label>
            </div>
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="radio"
                name="numberOfWorkers"
                defaultChecked={props.jobFilter.numberOfWorkers === 2}
                onChange={() => props.setFilter("numberOfWorkers", 2)}
              />
              <label htmlFor="two"> 2 People</label>
            </div>
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="radio"
                name="numberOfWorkers"
                defaultChecked={props.jobFilter.numberOfWorkers === 3}
                onChange={() => props.setFilter("numberOfWorkers", 3)}
              />
              <label htmlFor="three"> 3 People</label>
            </div>
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="radio"
                name="numberOfWorkers"
                defaultChecked={props.jobFilter.numberOfWorkers === 4}
                onChange={() => props.setFilter("numberOfWorkers", 4)}
              />
              <label htmlFor="four"> 4 People</label>
            </div>
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="radio"
                name="numberOfWorkers"
                defaultChecked={props.jobFilter.numberOfWorkers === 5}
                onChange={() => props.setFilter("numberOfWorkers", 5)}
              />
              <label htmlFor="five"> 5 or More People</label>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="filterSearch">
          <h3 className="filterSearch__title">Other: </h3>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="supplies"
              defaultChecked={props.jobFilter.supplies}
              onChange={() => props.setFilter("supplies")}
            />
            <label htmlFor="supplies"> Employer Provides Supplies</label>
          </div>
          <div className="filterSearch__inputBox">
            <input
              className="filterSearch__checkBox"
              type="checkbox"
              name="equipment"
              defaultChecked={props.jobFilter.equipment}
              onChange={() => props.setFilter("equipment")}
            />
            <label htmlFor="equipment"> Employer Provides Equipment</label>
          </div>
        </div>

        <div className="filterSearch">
          {props.page !== "findCleaner" ? (
            <h3 className="filterSearch__title">
              Budget:{" "}
              {currentRate > 149
                ? "+$150 per hour"
                : `$${currentRate} per hour`}
            </h3>
          ) : (
            <h3 className="filterSearch__title">
              Rate:{" "}
              {currentRate > 149
                ? "+$150 per hour"
                : `$${currentRate} per hour`}
            </h3>
          )}
          <form className="budgetForm" onSubmit={setBudget}>
            <Slider
              name="rate"
              defaultValue={150}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={22}
              max={150}
              onChange={(e) => setCurrentRate(e.target.ariaValueNow)}
            />
            <div className="filterSearch__inputBox">
              <input
                className="filterSearch__checkBox"
                type="checkbox"
                name="negotiable"
                defaultChecked={props.jobFilter.negotiable}
                onChange={() => props.setFilter("negotiable")}
              />
              <label htmlFor="equipment"> Negotiable</label>
            </div>
            <button buttonType="submit" className="setBudgetButton">
              Set Budget
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Filter;
