import { auth, firestore } from "../firebase";

export default async function isUserPremium(props) {
  {
    /*Pull current user ID in here*/
  }
  {
    /*
	Find Firestore doc in current user ID: 
	- "payments" that has the field named "created" 
	filtered by (created date + 24hrs > currentTime)
	*/
  }
  let timeOneDayAgo = Date.now() / 1000;
  timeOneDayAgo = timeOneDayAgo - 86400;
  let oneDaySub = false;
  const snapshot = await firestore
    .collection("users")
    .doc(props.uid)
    .collection("payments")
    .where("created", ">=", timeOneDayAgo)
    .get();
  if (snapshot.empty) {
    console.log("No matching one time documents.");
  }
  snapshot.forEach((doc) => {
    // console.log(doc.data().created);
    // console.log(doc.data().created - timeOneDayAgo);
    oneDaySub = true;
  });

  await auth.currentUser?.getIdToken(true);
  const decodedToken = await auth.currentUser?.getIdTokenResult();
  return decodedToken?.claims?.stripeRole || oneDaySub ? true : false;
}
