import React from "react";
import Modal from "react-modal";
import Chat from "./Chat";
import "../Styles/Sass/Chat/ChatModal.scss";
export default function ChatModal(props) {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="chatmodal"
      overlayClassName="chatmodal__overlay"
      contentLabel="Chat Modal"
    >
      <Chat user={props.user} job={props.job} />
    </Modal>
  );
}
