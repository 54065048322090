import { React, useState } from "react";
import Modal from "react-modal";
import "../Styles/Sass/FreePostModal.scss";
import { firestore } from "../firebase.js";

function ReviewModal(props) {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="freepostmodal"
      overlayClassName="freepostmodal__overlay"
      contentLabel="Free Post Modal"
    >
      <div className="freepostmodal__contentbox">
        <h2>Post your first listing on us</h2>
        <button
          onClick={() => props.buttonClose()}
          className={"freepostmodal__button"}
        >
          Submit a New Job
        </button>
      </div>
    </Modal>
  );
}

export default ReviewModal;
