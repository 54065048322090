import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDropzone } from "react-dropzone";
import { firestore, storage } from "../firebase.js";
import ReCAPTCHA from "react-google-recaptcha";
import JobForm from "./JobForm";
import DragDropFile from "./DragDropFile";
import "../Styles/Sass/DashBoardInfo.scss";
import "../Styles/Sass/CreateJobForm.scss";
import createCheckoutSession from "../stripe/createCheckoutSession";
import usePremiumStatus from "../stripe/usePremiumStatus";
import dotenv from "dotenv";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import FreePostModal from "../Components/FreePostModal.js";
import ClipLoader from "react-spinners/ClipLoader";
dotenv.config();

function DashBoardInfo(props) {
  const [userProfile, setUserProfile] = React.useState({});
  const [editProfile, setEditProfile] = React.useState(false);
  const [uploadStatus, setUploadStatus] = React.useState(false);
  const [inSetup, setInSetup] = React.useState(false);
  const [viewJobs, setViewJobs] = React.useState(true);
  const [policeFile, setPoliceFile] = React.useState(null);
  const [whimisFile, setWhimisFile] = React.useState(null);
  const [resume, setResume] = React.useState(null);
  const [insurance, setInsurance] = React.useState(null);
  const [businessNumber, setBusinessNumber] = React.useState("");
  const [city, setCity] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [postal, setPostal] = React.useState("");
  const [postalTwo, setPostalTwo] = React.useState("");
  const [placeId, setPlaceId] = React.useState("");
  const [latLng, setLatLng] = React.useState({});
  const [recaptcha, setRecaptcha] = React.useState(true);
  const [checkBoxStatus, setCheckBoxStatus] = React.useState({
    home: false,
    commercial: false,
    restaurant: false,
    industrial: false,
    project: false,
    events: false,
    specialty: false,
    airbnb: false,
    other: false,
    offices: false,
    washrooms: false,
    kitchen: false,
    cafeteria: false,
    carpet: false,
    floorCare: false,
    window: false,
    serviceOther: false,
    mornings: false,
    evenings: false,
    days: false,
    nights: false,
    afternoons: false,
    weekends: false,
  });
  const freePost = userProfile.freePost;

  const [modalIsOpen, setIsOpen] = React.useState({ freePost });
  const [alertText, setAlertText] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [descriptionText, setDescriptionText] = React.useState(
    userProfile.description
  );
  const [color, setColor] = React.useState("#ffffff");
  const [loading, setLoading] = React.useState(false);
  const phoneRegex =
    /(\b\d{3}[-.\s]?\d{3}[-.\s]?\d{4}\b)|(\b\d{10}\b)|(\b\d{3}[-.\s]?\d{4}\b)|(\b\d{7}\b)/;
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const phoneOrEmailRegex = new RegExp(
    `${phoneRegex.source}|${emailRegex.source}`
  );

  const emailsandnumbers = (e) => {
    var newText = e.target.value.replace(phoneOrEmailRegex, "");
    setDescriptionText(newText);
  };
  function closeAlert() {
    setAlertOpen(false);
  }
  function openAlert() {
    setAlertOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeCTAModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    getUserData();
  }, [props.user]);

  const userIsPremium = usePremiumStatus(props.user);
  //Limit search results to within CANADA and regions (for postal and province)
  const searchOptionsTwo = {
    componentRestrictions: { country: "CA" },
    types: ["(regions)"],
  };

  const setToJobs = () => {
    setEditProfile(false);

    props.setViewJobs(true);
  };

  const setToProfile = () => {
    setEditProfile(true);

    props.setViewJobs(false);
  };

  const handleSelectTwo = async (value) => {
    const results = await geocodeByAddress(value);
    const resultLatLng = await getLatLng(results[0]);
    if (
      results[0].types.includes("postal_code") &&
      results[0].types.length === 1
    ) {
      setPostal(
        value.split(",")[1].split(" ")[2] +
          " " +
          value.split(",")[1].split(" ")[3]
      );
      setPostalTwo(
        value.split(",")[1].split(" ")[2] +
          " " +
          value.split(",")[1].split(" ")[3]
      );
      setCity(value.split(",")[0]);
      setProvince(value.split(",")[1].split(" ")[1]);
      setPlaceId(results[0].place_id);
      setLatLng(resultLatLng);
    } else {
      setPostal("");
      setPostalTwo("");
      setCity("");
      setProvince("");
      setLatLng({});
      setAlertText("Please enter valid full postal code");
      openAlert();
    }
  };

  const getUserData = () => {
    firestore
      .collection("users")
      .doc(props.user.uid)
      .get()
      .then((res) => {
        let userData = res.data();
        setUserProfile(userData);
        let checkBox = checkBoxStatus;
        setCity(userData.province);
        setPostalTwo(userData.postal);
        setProvince(userData.city);
        setPlaceId(userData.placeId);
        userData.areaOfWork.includes("home")
          ? (checkBox.home = true)
          : (checkBox.home = false);
        userData.areaOfWork.includes("commercial")
          ? (checkBox.commercial = true)
          : (checkBox.commercial = false);
        userData.areaOfWork.includes("restaurant")
          ? (checkBox.restaurant = true)
          : (checkBox.restaurant = false);
        userData.areaOfWork.includes("industrial")
          ? (checkBox.industrial = true)
          : (checkBox.industrial = false);
        userData.areaOfWork.includes("project")
          ? (checkBox.project = true)
          : (checkBox.project = false);
        userData.areaOfWork.includes("events")
          ? (checkBox.events = true)
          : (checkBox.events = false);
        userData.areaOfWork.includes("specialty")
          ? (checkBox.specialty = true)
          : (checkBox.specialty = false);
        userData.areaOfWork.includes("airbnb")
          ? (checkBox.airbnb = true)
          : (checkBox.airbnb = false);
        userData.areaOfWork.includes("other")
          ? (checkBox.other = true)
          : (checkBox.other = false);
        userData.services.includes("offices")
          ? (checkBox.offices = true)
          : (checkBox.offices = false);
        userData.services.includes("washrooms")
          ? (checkBox.washrooms = true)
          : (checkBox.washrooms = false);
        userData.services.includes("kitchen")
          ? (checkBox.kitchen = true)
          : (checkBox.kitchen = false);
        userData.services.includes("cafeteria")
          ? (checkBox.cafeteria = true)
          : (checkBox.cafeteria = false);
        userData.services.includes("carpet")
          ? (checkBox.carpet = true)
          : (checkBox.carpet = false);
        userData.services.includes("floorCare")
          ? (checkBox.floorCare = true)
          : (checkBox.floorCare = false);
        userData.services.includes("window")
          ? (checkBox.window = true)
          : (checkBox.window = false);
        userData.services.includes("serviceOther")
          ? (checkBox.serviceOther = true)
          : (checkBox.serviceOther = false);
        userData.availability.includes("mornings")
          ? (checkBox.mornings = true)
          : (checkBox.mornings = false);
        userData.availability.includes("evenings")
          ? (checkBox.evenings = true)
          : (checkBox.evenings = false);
        userData.availability.includes("days")
          ? (checkBox.days = true)
          : (checkBox.days = false);
        userData.availability.includes("nights")
          ? (checkBox.nights = true)
          : (checkBox.nights = false);
        userData.availability.includes("afternoons")
          ? (checkBox.afternoons = true)
          : (checkBox.afternoons = false);
        userData.availability.includes("weekends")
          ? (checkBox.weekends = true)
          : (checkBox.weekends = false);
        setCheckBoxStatus(checkBox);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clickCheckBox = (value) => {
    let checkBox = checkBoxStatus;
    let valueStatus = checkBox[value];
    checkBox[value] = !valueStatus;
    setCheckBoxStatus(checkBox);
  };

  //Submit handler for first time user choosing job
  const submitJob = (e) => {
    e.preventDefault();
    if (recaptcha === false) {
      setAlertText("Please verify you are not a robot through reCAPTCHA");
      openAlert();
      return "";
    }
    let affiliateCode = e.target.affiliateCode.value;
    if (affiliateCode) {
      if (
        affiliateCode === "cc821" ||
        affiliateCode === "cc901" ||
        affiliateCode === "cc324" ||
        affiliateCode === "cc423" ||
        affiliateCode === "cc526" ||
        affiliateCode === "cc712"
      ) {
        firestore
          .collection("affiliate")
          .doc(affiliateCode)
          .get()
          .then((res) => {
            let counter = res.data().count;
            counter.push(Date.now());
            firestore
              .collection("affiliate")
              .doc(affiliateCode)
              .update({
                count: counter,
              })
              .then((res) => {
                console.log("Affiliate added");
                firestore
                  .collection("users")
                  .doc(props.user.uid)
                  .update({
                    job: e.target.job.value,
                    affiliate: true,
                  })
                  .then((res) => {
                    console.log("Succes");
                    getUserData();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        setAlertText("Please enter valid affiliate code");
        openAlert();
        return "";
      }
    } else {
      firestore
        .collection("users")
        .doc(props.user.uid)
        .update({
          job: e.target.job.value,
          tos: true,
        })
        .then((res) => {
          console.log(res);
          getUserData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Submit handler for employer setup
  const submitEmployerSetup = (e) => {
    e.preventDefault();
    if (!e.target.cleanerName.value) {
      setAlertText("Please provide valid name");
      openAlert();
      return "";
    }
    let newName = e.target.cleanerName.value;
    let newProvince = e.target.cleanerProvince.value;
    let newPostalcode = e.target.cleanerPostalcode.value;
    let newCity = e.target.cleanerCity.value;
    let newBusinessNumber = e.target.businessNumber.value;
    firestore
      .collection("users")
      .doc(props.user.uid)
      .update({
        name: newName,
        province: newProvince,
        city: newCity,
        postal: newPostalcode,
        businessNumber: newBusinessNumber,
        profileSetup: true,
      })
      .then((res) => {
        getUserData();
        setEditProfile(true);
        setInSetup(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Submit handler for cleaner setup
  const submitCleanerSetup = (e) => {
    e.preventDefault();
    //Area of Work
    let areaOfWork = [];
    e.target.home.checked && areaOfWork.push("home");
    e.target.commercial.checked && areaOfWork.push("commercial");
    e.target.restaurant.checked && areaOfWork.push("restaurant");
    e.target.industrial.checked && areaOfWork.push("industrial");
    e.target.projects.checked && areaOfWork.push("project");
    e.target.events.checked && areaOfWork.push("events");
    e.target.specialty.checked && areaOfWork.push("specialty");
    e.target.airbnb.checked && areaOfWork.push("airbnb");
    e.target.areaOfWorkOther.checked && areaOfWork.push("other");
    if (areaOfWork.length === 0) {
      setAlertText("Please select at least one area of work");
      openAlert();
      return "";
    }
    //Services
    let serviceArray = [];
    e.target.offices.checked && serviceArray.push("offices");
    e.target.washrooms.checked && serviceArray.push("washrooms");
    e.target.kitchen.checked && serviceArray.push("kitchen");
    e.target.cafeteria.checked && serviceArray.push("cafeteria");
    e.target.carpet.checked && serviceArray.push("carpet");
    e.target.floorCare.checked && serviceArray.push("floorCare");
    e.target.window.checked && serviceArray.push("window");
    e.target.serviceOther.checked && serviceArray.push("other");
    if (serviceArray.length === 0) {
      setAlertText("Please select at least one service");
      openAlert();
      return "";
    }
    //Availability
    let availabilityArray = [];
    e.target.mornings.checked && availabilityArray.push("mornings");
    e.target.evenings.checked && availabilityArray.push("evenings");
    e.target.days.checked && availabilityArray.push("days");
    e.target.nights.checked && availabilityArray.push("nights");
    e.target.afternoons.checked && availabilityArray.push("afternoons");
    e.target.weekends.checked && availabilityArray.push("weekends");
    if (availabilityArray.length === 0) {
      setAlertText("Please select at least one availability");
      openAlert();
      return "";
    }
    if (!e.target.name.value) {
      setAlertText("Please provide valid name");
      openAlert();
      return "";
    }
    if (
      !e.target.province.value ||
      !e.target.city.value ||
      !e.target.postalcode.value
    ) {
      setAlertText("Please provide valid location");
      openAlert();
      return "";
    }
    if (!e.target.rate.value || e.target.rate.value < 22) {
      setAlertText("Please provide valid rate (minimum 22)");
      openAlert();
      return "";
    }
    if (!e.target.headline.value) {
      setAlertText("Please provide valid headline");
      openAlert();
      return "";
    }
    // if (!e.target.description.value) {
    //   setAlertText("Please provide valid description");
    //   openAlert();
    //   return "";
    // }
    firestore
      .collection("users")
      .doc(props.user.uid)
      .update({
        name: e.target.name.value,
        province: e.target.province.value,
        city: e.target.city.value,
        postal: e.target.postalcode.value,
        areaOfWork: areaOfWork,
        services: serviceArray,
        rate: e.target.rate.value,
        negotiable: e.target.negotiable.value,
        headline: e.target.headline.value,
        description: e.target.description.value,
        supplies: e.target.supplies.value,
        equipment: e.target.equipment.value,
        availability: availabilityArray,
        placeId: placeId,
        latLng: latLng,
        profileSetup: true,
      })
      .then((res) => {
        getUserData();
        setEditProfile(true);
        setInSetup(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Submit handler for employer name change
  const submitNameChange = (e) => {
    e.preventDefault();
    if (!e.target.employerName.value) {
      setAlertText("Please provide valid name");
      openAlert();
      return "";
    }
    let newName = e.target.employerName.value;
    let newProvince = e.target.cleanerProvince.value;
    let newPostalcode = e.target.cleanerPostalcode.value;
    let newCity = e.target.cleanerCity.value;
    let newBusinessNumber = e.target.businessNumber.value;
    firestore
      .collection("users")
      .doc(props.user.uid)
      .update({
        name: newName,
        province: newProvince,
        city: newCity,
        postal: newPostalcode,
        businessNumber: newBusinessNumber,
        profileSetup: true,
      })
      .then((res) => {
        getUserData();
        setEditProfile(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Submit handler for cleaner profile change
  const submitProfileChange = (e) => {
    e.preventDefault();
    //Area of Work
    let areaOfWork = [];
    e.target.home.checked && areaOfWork.push("home");
    e.target.commercial.checked && areaOfWork.push("commercial");
    e.target.restaurant.checked && areaOfWork.push("restaurant");
    e.target.industrial.checked && areaOfWork.push("industrial");
    e.target.projects.checked && areaOfWork.push("project");
    e.target.events.checked && areaOfWork.push("events");
    e.target.specialty.checked && areaOfWork.push("specialty");
    e.target.airbnb.checked && areaOfWork.push("airbnb");
    e.target.areaOfWorkOther.checked && areaOfWork.push("other");
    if (areaOfWork.length === 0) {
      setAlertText("Please select at least one area of work");
      openAlert();
      return "";
    }
    //Services
    let serviceArray = [];
    e.target.offices.checked && serviceArray.push("offices");
    e.target.washrooms.checked && serviceArray.push("washrooms");
    e.target.kitchen.checked && serviceArray.push("kitchen");
    e.target.cafeteria.checked && serviceArray.push("cafeteria");
    e.target.carpet.checked && serviceArray.push("carpet");
    e.target.floorCare.checked && serviceArray.push("floorCare");
    e.target.window.checked && serviceArray.push("window");
    e.target.serviceOther.checked && serviceArray.push("other");
    if (serviceArray.length === 0) {
      setAlertText("Please select at least one service");
      openAlert();
      return "";
    }
    //Availability
    let availabilityArray = [];
    e.target.mornings.checked && availabilityArray.push("mornings");
    e.target.evenings.checked && availabilityArray.push("evenings");
    e.target.days.checked && availabilityArray.push("days");
    e.target.nights.checked && availabilityArray.push("nights");
    e.target.afternoons.checked && availabilityArray.push("afternoons");
    e.target.weekends.checked && availabilityArray.push("weekends");
    if (availabilityArray.length === 0) {
      setAlertText("Please select at least one availability");
      openAlert();
      return "";
    }
    if (!e.target.name.value) {
      setAlertText("Please provide valid name");
      openAlert();
      return "";
    }
    if (
      !e.target.province.value ||
      !e.target.city.value ||
      !e.target.postalcode.value
    ) {
      setAlertText("Please provide valid location");
      openAlert();
      return "";
    }
    if (!e.target.rate.value || e.target.rate.value < 22) {
      setAlertText("Please provide valid rate (minimum 22)");
      openAlert();
      return "";
    }
    if (!e.target.headline.value) {
      setAlertText("Please provide valid headline");
      openAlert();
      return "";
    }
    // if (!e.target.description.value) {
    //   setAlertText("Please provide valid description");
    //   openAlert();
    //   return "";
    // }
    firestore
      .collection("users")
      .doc(props.user.uid)
      .update({
        name: e.target.name.value,
        province: e.target.province.value,
        city: e.target.city.value,
        postal: e.target.postalcode.value,
        areaOfWork: areaOfWork,
        services: serviceArray,
        rate: e.target.rate.value,
        negotiable: e.target.negotiable.value,
        headline: e.target.headline.value,
        description: e.target.description.value,
        supplies: e.target.supplies.value,
        equipment: e.target.equipment.value,
        availability: availabilityArray,
        placeId: placeId,
        latLng: latLng,
        profileSetup: true,
      })
      .then((res) => {
        getUserData();
        setEditProfile(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const policeFileHandler = async (file) => {
    await setPoliceFile(file);
  };

  const whimisFileHandler = async (file) => {
    await setWhimisFile(file);
  };

  const resumeHandler = async (file) => {
    await setResume(file);
  };

  const insuranceHandler = async (file) => {
    await setInsurance(file);
  };

  const submitFileHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let userUploads = {
      whimisFile: "",
      policecheckFile: "",
      resumeFile: "",
      insuranceFile: "",
    };
    await firestore
      .collection("users")
      .doc(`${props.user.uid}`)
      .get()
      .then((res) => {
        if (res.data().uploads) {
          userUploads = res.data().uploads;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const storageRef = storage.ref();
    if (policeFile) {
      const fileRef = storageRef.child(policeFile.name);
      await fileRef.put(policeFile);
      userUploads.policecheckFile = {
        name: policeFile.name,
        url: await fileRef.getDownloadURL(),
      };
    }
    if (whimisFile) {
      const fileRefTwo = storageRef.child(whimisFile.name);
      await fileRefTwo.put(whimisFile);
      userUploads.whimisFile = {
        name: whimisFile.name,
        url: await fileRefTwo.getDownloadURL(),
      };
    }
    if (resume) {
      const fileRefThree = storageRef.child(resume.name);
      await fileRefThree.put(resume);
      userUploads.resumeFile = {
        name: resume.name,
        url: await fileRefThree.getDownloadURL(),
      };
    }
    if (insurance) {
      const fileRefFour = storageRef.child(insurance.name);
      await fileRefFour.put(insurance);
      userUploads.insuranceFile = {
        name: insurance.name,
        url: await fileRefFour.getDownloadURL(),
      };
    }
    firestore
      .collection("users")
      .doc(props.user.uid)
      .update({
        uploads: userUploads,
      })
      .then((res) => {
        e.target.reset();
        setAlertText("Successfully submitted");
        openAlert();
        {
          /* TODO: Mail admin when user submits a file */
        }
        setPoliceFile(null);
        setWhimisFile(null);
        setResume(null);
        setInsurance(null);
        setLoading(false);
        if (inSetup) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createCheckoutSessionTest = (userId) => {
    createCheckoutSession(userId);
  };

  return (
    <section className="dashinfo">
      {userProfile.job === "" ? (
        <form className="dashinfo__jobform" onSubmit={submitJob}>
          {/*Alert Modal for form validation*/}
          <Modal
            isOpen={alertOpen}
            className="alertmodal"
            onRequestClose={closeAlert}
            overlayClassName="alertmodal__overlay"
            contentLabel="Alert Modal"
          >
            <div className="alertmodal__contentbox">
              <h4>{alertText}</h4>
              <button onClick={closeAlert} className={"alertmodal__button"}>
                Close
              </button>
            </div>
          </Modal>
          <h3 className="dashinfo__title">
            Select your profile Cleaner or Employer
          </h3>
          <p className="dashinfo__warning">
            <span className="dashinfo__warning ">
              (Once selected profiles can <strong>not</strong> be changed)
            </span>
          </p>
          <div>
            <input
              type="radio"
              name="job"
              value="cleaner"
              checked="checked"
              required
            />
            <label className="dashinfo__label" htmlFor="job">
              Cleaner
            </label>
            <input type="radio" name="job" value="employer" />
            <label className="dashinfo__label" htmlFor="job">
              Employer
            </label>
          </div>
          <label
            htmlFor="affiliateCode"
            className="dashinfo__label dashinfo__label--aff"
          >
            <strong>Employers</strong> using an affiliate code enter it below
          </label>
          <input
            className="dashinfo__input dashinfo__input--aff"
            name="affiliateCode"
            type="text"
          />
          <label
            htmlFor="affiliateCode"
            className="dashinfo__label dashinfo__label--aff"
          >
            By clicking "SUBMIT", you agree to{" "}
            <a href="/termsandconditions" target="_blank">
              Terms and Conditions
            </a>{" "}
            &{" "}
            <a target="_blank" href="/privacypolicy">
              Privacy Policy
            </a>
          </label>
          {/* Removed input for both t&c and pp */}
          {/* <div className="tnc__inputbox">
            <input type="checkbox" name="tnc" value={true} required />
            <label htmlFor="tnc" className="tnc__label">
              I have read and agree to the Terms and Conditions
            </label>
          </div> */}

          {/* Removed input for both t&c and pp */}
          {/* <div className="tnc__inputbox">
            <input type="checkbox" name="privacy" value={true} required />
            <label htmlFor="privacy" className="tnc__label">
              I have read and agree to the Privacy Policy
            </label>
          </div> */}
          {/* <div className='dashinfo__captchabox'>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={() => setRecaptcha(true)}
              />
            </div> */}
          <button type="submit" className="dashinfo__button">
            SUBMIT
          </button>
        </form>
      ) : (
        <></>
      )}
      {(userProfile.job === "employer" || userProfile.job === "cleaner") &&
      !userProfile.profileSetup ? (
        <>
          {true ? (
            <section className="createjob">
              {/*Alert Modal */}
              <Modal
                isOpen={alertOpen}
                className="alertmodal"
                onRequestClose={closeAlert}
                overlayClassName="alertmodal__overlay"
                contentLabel="Free Post Modal"
              >
                <div className="alertmodal__contentbox">
                  <h4>{alertText}</h4>
                  <button onClick={closeAlert} className={"alertmodal__button"}>
                    Close
                  </button>
                </div>
              </Modal>
              <h2 className="createjob__title">General Information</h2>
            </section>
          ) : (
            <></>
          )}
          {userProfile.job === "employer" ? (
            <form className="createjob" onSubmit={submitEmployerSetup}>
              <label className="createjob__subtitle" htmlFor="cleanerName">
                Display Name / Company Name
              </label>
              <input
                className="createjob__textinput"
                name="cleanerName"
                type="text"
                defaultValue={userProfile.name}
              />
              <label className="createjob__subtitle">
                Location (Please enter postal code)
              </label>
              <PlacesAutocomplete
                value={postal}
                onChange={setPostal}
                onSelect={handleSelectTwo}
                searchOptions={searchOptionsTwo}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({ className: "createjob__textinput" })}
                    />
                    <div>
                      {loading ? <div>...loading</div> : null}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "createjob__textinput createjob__textinput--active"
                          : "createjob__textinput";
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <label className="createjob__label" htmlFor="cleanerCity">
                City
              </label>
              <input
                className="createjob__textinput"
                type="text"
                value={city}
                disabled={true}
                name="cleanerCity"
              />
              <label className="createjob__label" htmlFor="cleanerPostalcode">
                Postal Code
              </label>
              <input
                className="createjob__textinput"
                type="text"
                value={postalTwo}
                disabled={true}
                name="cleanerPostalcode"
              />
              <label className="createjob__label" htmlFor="cleanerProvince">
                Province
              </label>
              <input
                className="createjob__textinput"
                type="text"
                value={province}
                disabled={true}
                name="cleanerProvince"
              />
              <label className="createjob__subtitle" htmlFor="businessNumber">
                Business Number (BIN) - optional
              </label>

              <input
                className="createjob__textinput"
                name="businessNumber"
                type="text"
                defaultValue={userProfile?.businessNumber}
              />
              <button className="createjob__button" type="submit">
                SUBMIT
              </button>
            </form>
          ) : (
            <></>
          )}
          {userProfile.job === "cleaner" ? (
            <form className="createjob" onSubmit={submitCleanerSetup}>
              <label className="createjob__subtitle" htmlFor="name">
                Display Name{" "}
                <span className="subscript">
                  (i.e. First name and last initial)
                </span>
              </label>
              <input
                className="createjob__textinput"
                name="name"
                type="text"
                defaultValue={userProfile.name}
              />
              {/*Location Section*/}
              <h3 className="createjob__subtitle">
                Where are you applying for jobs?
              </h3>
              <label className="createjob__label">
                Please enter Postal Code*
              </label>
              <PlacesAutocomplete
                value={postal}
                onChange={setPostal}
                onSelect={handleSelectTwo}
                searchOptions={searchOptionsTwo}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({ className: "createjob__textinput" })}
                    />
                    <div>
                      {loading ? <div>...loading</div> : null}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "createjob__textinput createjob__textinput--active"
                          : "createjob__textinput";
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <label className="createjob__label" htmlFor="city">
                City
              </label>
              <input
                className="createjob__textinput"
                type="text"
                value={city}
                disabled={true}
                name="city"
              />
              <label className="createjob__label" htmlFor="postalcode">
                Postal Code
              </label>
              <input
                className="createjob__textinput"
                type="text"
                value={postalTwo}
                disabled={true}
                name="postalcode"
              />
              <label className="createjob__label" htmlFor="province">
                Province
              </label>
              <input
                className="createjob__textinput"
                type="text"
                value={province}
                disabled={true}
                name="province"
              />
              {/*Area of Work Section*/}
              <h3 className="createjob__subtitle">
                Which job categories do you offer?
              </h3>
              <p className="createjob__desc">Select all that apply.</p>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="home"
                  defaultChecked={checkBoxStatus.home}
                  onClick={() => clickCheckBox("home")}
                />
                <label className="createjob__checklabel" htmlFor="home">
                  Home
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="commercial"
                  defaultChecked={checkBoxStatus.commercial}
                  onClick={() => clickCheckBox("commercial")}
                />
                <label className="createjob__checklabel" htmlFor="commercial">
                  Commercial
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="restaurant"
                  defaultChecked={checkBoxStatus.restaurant}
                  onClick={() => clickCheckBox("restaurant")}
                />
                <label className="createjob__checklabel" htmlFor="restaurant">
                  Restaurant
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="industrial"
                  defaultChecked={checkBoxStatus.industrial}
                  onClick={() => clickCheckBox("industrial")}
                />
                <label className="createjob__checklabel" htmlFor="industrial">
                  Industrial
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="projects"
                  defaultChecked={checkBoxStatus.project}
                  onClick={() => clickCheckBox("project")}
                />
                <label className="createjob__checklabel" htmlFor="project">
                  Projects
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="events"
                  defaultChecked={checkBoxStatus.events}
                  onClick={() => clickCheckBox("events")}
                />
                <label className="createjob__checklabel" htmlFor="events">
                  Events
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="specialty"
                  defaultChecked={checkBoxStatus.specialty}
                  onClick={() => clickCheckBox("specialty")}
                />
                <label className="createjob__checklabel" htmlFor="specialty">
                  Specialty (Window Cleaning etc.)
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="airbnb"
                  defaultChecked={checkBoxStatus.airbnb}
                  onClick={() => clickCheckBox("airbnb")}
                />
                <label className="createjob__checklabel" htmlFor="airbnb">
                  Air BNB
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="areaOfWorkOther"
                  defaultChecked={checkBoxStatus.other}
                  onClick={() => clickCheckBox("other")}
                />
                <label className="createjob__checklabel" htmlFor="other">
                  Other (Please describe in description)
                </label>
              </div>
              {/*Services Section*/}
              <h3 className="createjob__subtitle">
                Which services do you supply?
              </h3>
              <p className="createjob__desc">Select all that apply.</p>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="offices"
                  defaultChecked={checkBoxStatus.offices}
                  onClick={() => clickCheckBox("offices")}
                />
                <label className="createjob__checklabel" htmlFor="offices">
                  Offices
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="washrooms"
                  defaultChecked={checkBoxStatus.washrooms}
                  onClick={() => clickCheckBox("washrooms")}
                />
                <label className="createjob__checklabel" htmlFor="washrooms">
                  Washrooms
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="kitchen"
                  defaultChecked={checkBoxStatus.kitchen}
                  onClick={() => clickCheckBox("kitchen")}
                />
                <label className="createjob__checklabel" htmlFor="kitchen">
                  Kitchen
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="cafeteria"
                  defaultChecked={checkBoxStatus.cafeteria}
                  onClick={() => clickCheckBox("cafeteria")}
                />
                <label className="createjob__checklabel" htmlFor="cafeteria">
                  Cafeteria
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="carpet"
                  defaultChecked={checkBoxStatus.carpet}
                  onClick={() => clickCheckBox("carpet")}
                />
                <label className="createjob__checklabel" htmlFor="carpet">
                  Carpet
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="floorCare"
                  defaultChecked={checkBoxStatus.floorCare}
                  onClick={() => clickCheckBox("floorCare")}
                />
                <label className="createjob__checklabel" htmlFor="floorCare">
                  Floor Care
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="window"
                  defaultChecked={checkBoxStatus.window}
                  onClick={() => clickCheckBox("window")}
                />
                <label className="createjob__checklabel" htmlFor="window">
                  Window
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="serviceOther"
                  defaultChecked={checkBoxStatus.other}
                  onClick={() => clickCheckBox("other")}
                />
                <label className="createjob__checklabel" htmlFor="other">
                  Other (Please describe in description)
                </label>
              </div>
              {/*Rate Section*/}
              <h3 className="createjob__subtitle">What rate do you charge?</h3>
              <label className="createjob__label" htmlFor="rate">
                $22 an hour or greater
              </label>
              <input
                className="createjob__textinput"
                name="rate"
                type="number"
                step={0.01}
                min={22}
                defaultValue={userProfile.rate === 0 ? 22.0 : userProfile.rate}
              />
              <h4 className="createjob__subtitle createjob__subtitle--small">
                Is your rate negotiable?
              </h4>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__radioinput"
                  type="radio"
                  name="negotiable"
                  value={true}
                  defaultChecked={
                    userProfile.negotiable === "true" ? true : false
                  }
                  required
                />
                <label className="createjob__checklabel" htmlFor="negotiable">
                  Negotiable
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__radioinput"
                  type="radio"
                  name="negotiable"
                  value={false}
                  defaultChecked={
                    userProfile.negotiable === "false" ? true : false
                  }
                  required
                />
                <label className="createjob__checklabel" htmlFor="negotiable">
                  Non-negotiable
                </label>
              </div>

              {/*Description Section*/}
              <h3 className="createjob__subtitle">
                What short heading tells employers what you offer?
              </h3>
              <label className="createjob__label" htmlFor="headline">
                Headline
              </label>
              <input
                className="createjob__textinput"
                name="headline"
                type="text"
                defaultValue={userProfile.headline}
              />
              <label className="createjob__label" htmlFor="description">
                Description of Services
              </label>
              {/* BUG 05-29-2023
                Description goes blank when cleaners try to edit their information
                Could possibly bet the same for employers when editing jobs
            */}
              <textarea
                className="createjob__textinput createjob__textinput--area"
                name="description"
                defaultValue={userProfile.description}
                value={descriptionText}
                onChange={emailsandnumbers}
              ></textarea>
              {/*Supplies Section*/}
              <h4 className="createjob__subtitle createjob__subtitle--small">
                Do you own your own cleaning supplies?
              </h4>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__radioinput"
                  type="radio"
                  name="supplies"
                  value={true}
                  defaultChecked={
                    userProfile.supplies === "true" ? true : false
                  }
                  required
                />
                <label className="createjob__checklabel" htmlFor="supplies">
                  I own cleaning supplies
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__radioinput"
                  type="radio"
                  name="supplies"
                  value={false}
                  defaultChecked={
                    userProfile.supplies === "false" ? true : false
                  }
                  required
                />
                <label className="createjob__checklabel" htmlFor="supplies">
                  I do not own cleaning supplies
                </label>
              </div>
              {/*Supplies Section*/}
              <h4 className="createjob__subtitle createjob__subtitle--small">
                Do you own your own cleaning equipment?
              </h4>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__radioinput"
                  type="radio"
                  name="equipment"
                  value={true}
                  defaultChecked={
                    userProfile.equipment === "true" ? true : false
                  }
                  required
                />
                <label className="createjob__checklabel" htmlFor="equipment">
                  I own cleaning equipment
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__radioinput"
                  type="radio"
                  name="equipment"
                  value={false}
                  defaultChecked={
                    userProfile.equipment === "false" ? true : false
                  }
                  required
                />
                <label className="createjob__checklabel" htmlFor="equipment">
                  I do not own cleaning equipment
                </label>
              </div>
              {/*Availability Section*/}
              <h4 className="createjob__subtitle createjob__subtitle--small">
                What is your availability?
              </h4>
              <p className="createjob__desc">Select all that apply</p>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="mornings"
                  defaultChecked={checkBoxStatus.mornings}
                  onClick={() => clickCheckBox("mornings")}
                />
                <label className="createjob__checklabel" htmlFor="mornings">
                  Mornings
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="evenings"
                  defaultChecked={checkBoxStatus.evenings}
                  onClick={() => clickCheckBox("evenings")}
                />
                <label className="createjob__checklabel" htmlFor="evenings">
                  Evenings
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="days"
                  defaultChecked={checkBoxStatus.days}
                  onClick={() => clickCheckBox("days")}
                />
                <label className="createjob__checklabel" htmlFor="days">
                  Days
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="nights"
                  defaultChecked={checkBoxStatus.nights}
                  onClick={() => clickCheckBox("nights")}
                />
                <label className="createjob__checklabel" htmlFor="nights">
                  Nights
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="afternoons"
                  defaultChecked={checkBoxStatus.afternoons}
                  onClick={() => clickCheckBox("afternoons")}
                />
                <label className="createjob__checklabel" htmlFor="afternoons">
                  Afternoons
                </label>
              </div>
              <div className="createjob__checkContainer">
                <input
                  className="createjob__checkinput"
                  type="checkbox"
                  name="weekends"
                  defaultChecked={checkBoxStatus.weekends}
                  onClick={() => clickCheckBox("weekends")}
                />
                <label className="createjob__checklabel" htmlFor="weekends">
                  Weekends
                </label>
              </div>
              <button className="createjob__button" type="submit">
                SUBMIT
              </button>
            </form>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {/* Submit free job posting modal - redirect to /submitjob */}
      {freePost &&
      userProfile.job === "employer" &&
      userProfile.profileSetup ? (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="freepostmodal"
            overlayClassName="freepostmodal__overlay"
            contentLabel="Free Post Modal"
          >
            <div className="freepostmodal__contentbox">
              <h2>Post your first listing on us</h2>
              <Link to={"/submitjob"}>
                <button className={"freepostmodal__button"}>
                  Submit a New Job
                </button>
              </Link>
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
      {(userProfile.job === "cleaner" || userProfile.job === "employer") &&
      userProfile.profileSetup &&
      !inSetup ? (
        <div className="editButton__container">
          <button
            className={
              props.viewJobs === true
                ? "editButton editButton--active"
                : "editButton"
            }
            onClick={setToJobs}
          >
            View My Jobs
          </button>
          <button
            className={
              editProfile === true
                ? "editButton editButton--active"
                : "editButton"
            }
            onClick={setToProfile}
          >
            Edit General Information
          </button>
          {userProfile.job === "employer" ? (
            <Link to={"/submitjob"}>
              <button className={"editButton editButton--left"}>
                Submit a New Job
              </button>
            </Link>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {editProfile === true ? (
        <section className="createjob">
          {/*Alert Modal */}
          <Modal
            isOpen={alertOpen}
            className="alertmodal"
            onRequestClose={closeAlert}
            overlayClassName="alertmodal__overlay"
            contentLabel="Free Post Modal"
          >
            <div className="alertmodal__contentbox">
              <h4>{alertText}</h4>
              <button onClick={closeAlert} className={"alertmodal__button"}>
                Close
              </button>
            </div>
          </Modal>

          {inSetup ? (
            <>
              <div className="createjob__titlebox">
                <h2 className="createjob__title">Submit files</h2>
                <a
                  className="fileform__link"
                  onClick={() => window.location.reload()}
                >
                  {"Skip this step →"}
                </a>
              </div>
            </>
          ) : (
            <h2 className="createjob__title">General Information</h2>
          )}
          <form className="fileform" onSubmit={submitFileHandler}>
            <label className="fileform__subtitle" htmlFor="policecheck">
              Police Check
            </label>
            <div>
              <p className="fileform__text">
                {userProfile.job === "cleaner"
                  ? "Having a record check may save you time if an employer requires one. Get one "
                  : "Having a record check may attract more cleaners. Get one "}
              </p>
              <a
                className="fileform__link"
                href="https://www.tritoncanada.ca/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </div>
            {/*<input
                className='fileform__fileinput'
                type="file"
                name='policecheck'
                onChange={policeFileHandler}
              />*/}
            <div className="fileform__dragBox">
              <DragDropFile setTarget={policeFileHandler} />
              <div className="fileform__currentBox">
                {userProfile.uploads.policecheckFile.url ? (
                  <a href={userProfile.uploads.policecheckFile.url}>
                    <p>Current Uploads</p>
                  </a>
                ) : (
                  <div>
                    <p>No Uploads</p>
                  </div>
                )}
              </div>
            </div>

            {policeFile !== null ? (
              <p className="fileform__sent">
                New File Posted, press submit below to upload
              </p>
            ) : (
              <></>
            )}
            <button
              type="submit"
              className={
                policeFile !== null
                  ? "createjob__button "
                  : "createjob__button createjob__button__disabled"
              }
            >
              SUBMIT{"  "}
              <ClipLoader
                loading={loading}
                color={color}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </button>
            {userProfile.job === "cleaner" ? (
              <>
                <label className="fileform__subtitle" htmlFor="whimischeck">
                  WHMIS Check
                </label>
                <div>
                  <p className="fileform__text">
                    Having your WHMIS May save you time if an employer requires
                    it. Get one&nbsp;
                  </p>
                  <a
                    className="fileform__link"
                    href="https://worksitesafety.ca/product/training/online/whmis-online-training/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </div>
                {/*<input
                    className='fileform__fileinput'
                    type="file"
                    name='whimischeck'
                    onChange={whimisFileHandler}
                  />*/}
                <div className="fileform__dragBox">
                  <DragDropFile setTarget={whimisFileHandler} />
                  <div className="fileform__currentBox">
                    {userProfile.uploads.whimisFile.url ? (
                      <a href={userProfile.uploads.whimisFile.url}>
                        <p>Current Uploads</p>
                      </a>
                    ) : (
                      <p>No Uploads</p>
                    )}
                  </div>
                </div>
                {whimisFile !== null ? (
                  <p className="fileform__sent">
                    New File Posted, press submit below to upload
                  </p>
                ) : (
                  <></>
                )}{" "}
                <button
                  type="submit"
                  className={
                    whimisFile !== null
                      ? "createjob__button "
                      : "createjob__button createjob__button__disabled"
                  }
                >
                  SUBMIT{"  "}
                  <ClipLoader
                    loading={loading}
                    color={color}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </button>
                <label className="fileform__subtitle" htmlFor="resume">
                  Resume
                </label>
                {/*<input
                    className='fileform__fileinput'
                    type="file"
                    name='resume'
                    onChange={resumeHandler}
                  />*/}
                <div className="fileform__dragBox">
                  <DragDropFile setTarget={resumeHandler} />
                  <div className="fileform__currentBox">
                    {userProfile.uploads.resumeFile.url ? (
                      <a href={userProfile.uploads.resumeFile.url}>
                        <p>Current Uploads</p>
                      </a>
                    ) : (
                      <p>No Uploads</p>
                    )}
                  </div>
                </div>
                {resume !== null ? (
                  <p className="fileform__sent">
                    New File Posted, press submit below to upload
                  </p>
                ) : (
                  <></>
                )}
                <button
                  type="submit"
                  className={
                    resume !== null
                      ? "createjob__button "
                      : "createjob__button createjob__button__disabled"
                  }
                >
                  SUBMIT{"  "}
                  <ClipLoader
                    loading={loading}
                    color={color}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </button>
                <label className="fileform__subtitle" htmlFor="resume">
                  Insurance
                </label>
                {/*<input
                    className='fileform__fileinput'
                    type="file"
                    name='resume'
                    onChange={resumeHandler}
                  />*/}
                <div className="fileform__dragBox">
                  <DragDropFile setTarget={insuranceHandler} />
                  <div className="fileform__currentBox">
                    {userProfile.uploads.insuranceFile?.url ? (
                      <a href={userProfile.uploads.insuranceFile?.url}>
                        <p>Current Uploads</p>
                      </a>
                    ) : (
                      <p>No Uploads</p>
                    )}
                  </div>
                </div>
                {insurance !== null ? (
                  <p className="fileform__sent">
                    New File Posted, press submit below to upload
                  </p>
                ) : (
                  <></>
                )}
                <button
                  type="submit"
                  className={
                    insurance !== null
                      ? "createjob__button "
                      : "createjob__button createjob__button__disabled"
                  }
                >
                  SUBMIT{"  "}
                  <ClipLoader
                    loading={loading}
                    color={color}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </button>
              </>
            ) : (
              <></>
            )}
          </form>
        </section>
      ) : (
        <></>
      )}
      {userProfile.job === "employer" && editProfile === true && !inSetup ? (
        <form className="createjob" onSubmit={submitNameChange}>
          <label className="createjob__subtitle" htmlFor="employerName">
            Display Name / Company Name
          </label>
          <input
            className="createjob__textinput"
            name="employerName"
            type="text"
            defaultValue={userProfile.name}
          />
          <label className="createjob__subtitle">
            Location (Please enter postal code)
          </label>
          <PlacesAutocomplete
            value={postal}
            onChange={setPostal}
            onSelect={handleSelectTwo}
            searchOptions={searchOptionsTwo}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({ className: "createjob__textinput" })}
                />
                <div>
                  {loading ? <div>...loading</div> : null}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "createjob__textinput createjob__textinput--active"
                      : "createjob__textinput";
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <label className="createjob__label" htmlFor="cleanerCity">
            City
          </label>
          <input
            className="createjob__textinput"
            type="text"
            value={city}
            disabled={true}
            name="cleanerCity"
          />
          <label className="createjob__label" htmlFor="cleanerPostalcode">
            Postal Code
          </label>
          <input
            className="createjob__textinput"
            type="text"
            value={postalTwo}
            disabled={true}
            name="cleanerPostalcode"
          />
          <label className="createjob__label" htmlFor="cleanerProvince">
            Province
          </label>
          <input
            className="createjob__textinput"
            type="text"
            value={province}
            disabled={true}
            name="cleanerProvince"
          />
          <label className="createjob__subtitle" htmlFor="businessNumber">
            Business Number (BIN) - optional
          </label>

          <input
            className="createjob__textinput"
            name="businessNumber"
            type="text"
            defaultValue={userProfile?.businessNumber}
          />
          <button className="createjob__button" type="submit">
            SUBMIT
          </button>
        </form>
      ) : (
        <></>
      )}
      {userProfile.job === "cleaner" && editProfile === true && !inSetup ? (
        <form className="createjob" onSubmit={submitProfileChange}>
          <label className="createjob__subtitle" htmlFor="name">
            Display Name{" "}
            <span className="subscript">
              (i.e. First name and last initial)
            </span>
          </label>
          <input
            className="createjob__textinput"
            name="name"
            type="text"
            defaultValue={userProfile.name}
          />
          {/*Location Section*/}
          <h3 className="createjob__subtitle">
            Where are you applying for jobs?
          </h3>
          <label className="createjob__label">Please enter Postal Code*</label>
          <PlacesAutocomplete
            value={postal}
            onChange={setPostal}
            onSelect={handleSelectTwo}
            searchOptions={searchOptionsTwo}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({ className: "createjob__textinput" })}
                />
                <div>
                  {loading ? <div>...loading</div> : null}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "createjob__textinput createjob__textinput--active"
                      : "createjob__textinput";
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <label className="createjob__label" htmlFor="city">
            City
          </label>
          <input
            className="createjob__textinput"
            type="text"
            value={city}
            disabled={true}
            name="city"
          />
          <label className="createjob__label" htmlFor="postalcode">
            Postal Code
          </label>
          <input
            className="createjob__textinput"
            type="text"
            value={postalTwo}
            disabled={true}
            name="postalcode"
          />
          <label className="createjob__label" htmlFor="province">
            Province
          </label>
          <input
            className="createjob__textinput"
            type="text"
            value={province}
            disabled={true}
            name="province"
          />
          {/*Area of Work Section*/}
          <h3 className="createjob__subtitle">
            Which job categories do you offer?
          </h3>
          <p className="createjob__desc">Select all that apply.</p>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="home"
              defaultChecked={checkBoxStatus.home}
              onClick={() => clickCheckBox("home")}
            />
            <label className="createjob__checklabel" htmlFor="home">
              Home
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="commercial"
              defaultChecked={checkBoxStatus.commercial}
              onClick={() => clickCheckBox("commercial")}
            />
            <label className="createjob__checklabel" htmlFor="commercial">
              Commercial
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="restaurant"
              defaultChecked={checkBoxStatus.restaurant}
              onClick={() => clickCheckBox("restaurant")}
            />
            <label className="createjob__checklabel" htmlFor="restaurant">
              Restaurant
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="industrial"
              defaultChecked={checkBoxStatus.industrial}
              onClick={() => clickCheckBox("industrial")}
            />
            <label className="createjob__checklabel" htmlFor="industrial">
              Industrial
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="projects"
              defaultChecked={checkBoxStatus.project}
              onClick={() => clickCheckBox("project")}
            />
            <label className="createjob__checklabel" htmlFor="project">
              Projects
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="events"
              defaultChecked={checkBoxStatus.events}
              onClick={() => clickCheckBox("events")}
            />
            <label className="createjob__checklabel" htmlFor="events">
              Events
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="specialty"
              defaultChecked={checkBoxStatus.specialty}
              onClick={() => clickCheckBox("specialty")}
            />
            <label className="createjob__checklabel" htmlFor="specialty">
              Specialty (Window Cleaning etc.)
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="airbnb"
              defaultChecked={checkBoxStatus.airbnb}
              onClick={() => clickCheckBox("airbnb")}
            />
            <label className="createjob__checklabel" htmlFor="airbnb">
              Air BNB
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="areaOfWorkOther"
              defaultChecked={checkBoxStatus.other}
              onClick={() => clickCheckBox("other")}
            />
            <label className="createjob__checklabel" htmlFor="other">
              Other (Please describe in description)
            </label>
          </div>
          {/*Services Section*/}
          <h3 className="createjob__subtitle">Which services do you supply?</h3>
          <p className="createjob__desc">Select all that apply.</p>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="offices"
              defaultChecked={checkBoxStatus.offices}
              onClick={() => clickCheckBox("offices")}
            />
            <label className="createjob__checklabel" htmlFor="offices">
              Offices
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="washrooms"
              defaultChecked={checkBoxStatus.washrooms}
              onClick={() => clickCheckBox("washrooms")}
            />
            <label className="createjob__checklabel" htmlFor="washrooms">
              Washrooms
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="kitchen"
              defaultChecked={checkBoxStatus.kitchen}
              onClick={() => clickCheckBox("kitchen")}
            />
            <label className="createjob__checklabel" htmlFor="kitchen">
              Kitchen
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="cafeteria"
              defaultChecked={checkBoxStatus.cafeteria}
              onClick={() => clickCheckBox("cafeteria")}
            />
            <label className="createjob__checklabel" htmlFor="cafeteria">
              Cafeteria
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="carpet"
              defaultChecked={checkBoxStatus.carpet}
              onClick={() => clickCheckBox("carpet")}
            />
            <label className="createjob__checklabel" htmlFor="carpet">
              Carpet
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="floorCare"
              defaultChecked={checkBoxStatus.floorCare}
              onClick={() => clickCheckBox("floorCare")}
            />
            <label className="createjob__checklabel" htmlFor="floorCare">
              Floor Care
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="window"
              defaultChecked={checkBoxStatus.window}
              onClick={() => clickCheckBox("window")}
            />
            <label className="createjob__checklabel" htmlFor="window">
              Window
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="serviceOther"
              defaultChecked={checkBoxStatus.other}
              onClick={() => clickCheckBox("other")}
            />
            <label className="createjob__checklabel" htmlFor="other">
              Other (Please describe in description)
            </label>
          </div>
          {/*Rate Section*/}
          <h3 className="createjob__subtitle">What rate do you charge?</h3>
          <label className="createjob__label" htmlFor="rate">
            $22 an hour or greater
          </label>
          <input
            className="createjob__textinput"
            name="rate"
            type="number"
            min={22}
            defaultValue={userProfile.rate === 0 ? 22 : userProfile.rate}
          />
          <h4 className="createjob__subtitle createjob__subtitle--small">
            Is your rate negotiable?
          </h4>
          <div className="createjob__checkContainer">
            <input
              className="createjob__radioinput"
              type="radio"
              name="negotiable"
              value={true}
              defaultChecked={userProfile.negotiable === "true" ? true : false}
              required
            />
            <label className="createjob__checklabel" htmlFor="negotiable">
              Negotiable
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__radioinput"
              type="radio"
              name="negotiable"
              value={false}
              defaultChecked={userProfile.negotiable === "false" ? true : false}
              required
            />
            <label className="createjob__checklabel" htmlFor="negotiable">
              Non-negotiable
            </label>
          </div>

          {/*Description Section*/}
          <h3 className="createjob__subtitle">
            What short heading tells employers what you offer?
          </h3>
          <label className="createjob__label" htmlFor="headline">
            Headline
          </label>
          <input
            className="createjob__textinput"
            name="headline"
            type="text"
            defaultValue={userProfile.headline}
          />
          <label className="createjob__label" htmlFor="description">
            Description of Services
          </label>
          {/* BUG 05-29-2023
              Description goes blank when cleaners try to edit their information
              Could possibly bet the same for employers when editing jobs
          */}
          <textarea
            className="createjob__textinput createjob__textinput--area"
            name="description"
            defaultValue={userProfile.description}
            value={descriptionText}
            onChange={emailsandnumbers}
          ></textarea>
          {/*Supplies Section*/}
          <h4 className="createjob__subtitle createjob__subtitle--small">
            Do you own your own cleaning supplies?
          </h4>
          <div className="createjob__checkContainer">
            <input
              className="createjob__radioinput"
              type="radio"
              name="supplies"
              value={true}
              defaultChecked={userProfile.supplies === "true" ? true : false}
              required
            />
            <label className="createjob__checklabel" htmlFor="supplies">
              I own cleaning supplies
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__radioinput"
              type="radio"
              name="supplies"
              value={false}
              defaultChecked={userProfile.supplies === "false" ? true : false}
              required
            />
            <label className="createjob__checklabel" htmlFor="supplies">
              I do not own cleaning supplies
            </label>
          </div>
          {/*Supplies Section*/}
          <h4 className="createjob__subtitle createjob__subtitle--small">
            Do you own your own cleaning equipment?
          </h4>
          <div className="createjob__checkContainer">
            <input
              className="createjob__radioinput"
              type="radio"
              name="equipment"
              value={true}
              defaultChecked={userProfile.equipment === "true" ? true : false}
              required
            />
            <label className="createjob__checklabel" htmlFor="equipment">
              I own cleaning equipment
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__radioinput"
              type="radio"
              name="equipment"
              value={false}
              defaultChecked={userProfile.equipment === "false" ? true : false}
              required
            />
            <label className="createjob__checklabel" htmlFor="equipment">
              I do not own cleaning equipment
            </label>
          </div>
          {/*Availability Section*/}
          <h4 className="createjob__subtitle createjob__subtitle--small">
            What is your availability?
          </h4>
          <p className="createjob__desc">Select all that apply</p>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="mornings"
              defaultChecked={checkBoxStatus.mornings}
              onClick={() => clickCheckBox("mornings")}
            />
            <label className="createjob__checklabel" htmlFor="mornings">
              Mornings
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="evenings"
              defaultChecked={checkBoxStatus.evenings}
              onClick={() => clickCheckBox("evenings")}
            />
            <label className="createjob__checklabel" htmlFor="evenings">
              Evenings
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="days"
              defaultChecked={checkBoxStatus.days}
              onClick={() => clickCheckBox("days")}
            />
            <label className="createjob__checklabel" htmlFor="days">
              Days
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="nights"
              defaultChecked={checkBoxStatus.nights}
              onClick={() => clickCheckBox("nights")}
            />
            <label className="createjob__checklabel" htmlFor="nights">
              Nights
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="afternoons"
              defaultChecked={checkBoxStatus.afternoons}
              onClick={() => clickCheckBox("afternoons")}
            />
            <label className="createjob__checklabel" htmlFor="afternoons">
              Afternoons
            </label>
          </div>
          <div className="createjob__checkContainer">
            <input
              className="createjob__checkinput"
              type="checkbox"
              name="weekends"
              defaultChecked={checkBoxStatus.weekends}
              onClick={() => clickCheckBox("weekends")}
            />
            <label className="createjob__checklabel" htmlFor="weekends">
              Weekends
            </label>
          </div>
          <button className="createjob__button" type="submit">
            SUBMIT
          </button>
        </form>
      ) : (
        <></>
      )}

      {/* {userProfile.job === "employer" && postJob === true ? (
        <JobForm
          user={props.user}
          setPostJob={setPostJob}
          postJob={postJob}
          userProfile={userProfile}
        />
      ) : (
        <></>
      )} */}
    </section>
  );
}

export default DashBoardInfo;
