import { firestore } from "../firebase.js";
import React from "react";
import DataCard from "./DataCard";
import iconSearch from "../Images/iconSearch.svg";
import "../Styles/Sass/CleanerData.scss";

function CleanerData(props) {
  const [cleanerData, setCleanerData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("newest");
  const [searchValue, setSearchValue] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(5);

  React.useEffect(() => {
    getCleanerData();
  }, [sortBy, searchValue]);

  const getCleanerData = () => {
    firestore
      .collection("users")
      .get()
      .then((res) => {
        let allCleaners = [];
        res.forEach((doc) => {
          let userDetail = doc.data();
          let joinDate = new Date(userDetail.joined).toString().split(" ");
          userDetail.id = doc.id;
          userDetail.joinDate = `${joinDate[1]} ${joinDate[2]} ${joinDate[3]}`;
          if (doc.data().job === "cleaner") {
            if (searchValue === "") {
              allCleaners.push(userDetail);
            } else if (
              searchValue !== "" &&
              (userDetail.email
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
                userDetail.name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()))
            ) {
              allCleaners.push(userDetail);
            }
          }
        });
        if (sortBy === "newest") {
          allCleaners = allCleaners.sort((a, b) => {
            return b.joined - a.joined;
          });
        } else if (sortBy === "oldest") {
          allCleaners = allCleaners.sort((a, b) => {
            return a.joined - b.joined;
          });
        }
        setCleanerData(allCleaners);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderCleaners = () => {
    let renderedCleaners = cleanerData.map((cleaner) => {
      return (
        <DataCard
          name={cleaner.name}
          email={cleaner.email}
          memberSince={cleaner.joined}
          endorsements={cleaner.endorsements}
          suspended={cleaner.suspended}
          id={cleaner.id}
          getData={getCleanerData}
          job="cleaner"
          joinDate={cleaner.joinDate}
          headline={cleaner.headline}
          description={cleaner.description}
          policecheck={cleaner.policecheck}
          whmis={cleaner.whimis}
          city={cleaner.city}
          province={cleaner.province}
          postal={cleaner.postal}
          rate={cleaner.rate}
          getCleanerData={getCleanerData}
        />
      );
    });
    return renderedCleaners;
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchValue(e.target.search.value);
  };

  return (
    <section className="cleanerdata">
      <div className="adminnav">
        <label className="adminnav__label" htmlFor="">
          Dashboard view:
        </label>
        <select
          className="adminnav__select"
          onChange={(e) => props.setCurrentTab(e.target.value)}
          name=""
          id=""
        >
          <option value="Cleaner data & info">Cleaner data & info</option>
          <option value="View questions">View questions</option>
          <option value="User report">User report</option>
          <option value="Employer data & info">Employer data & info</option>
          <option value="Verify users">Verify users</option>
        </select>
      </div>
      <div className="cleanerdata__titlebox">
        <h2 className="cleanerdata__title">
          {cleanerData.length} cleaners found
        </h2>
        <div className="cleanerdata__sortbox">
          <label className="cleanerdata__label" htmlFor="sortFilter">
            Sort by:
          </label>
          <select
            className="cleanerdata__select"
            name="sortFilter"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
      </div>
      <form
        className="cleanerdata__searchbox"
        onSubmit={(e) => searchHandler(e)}
      >
        <input
          className="cleanerdata__input"
          type="text"
          name="search"
          placeholder="Search by email or name"
        />
        <button className="cleanerdata__search" type="submit">
          <img className="cleanerdata__icon" src={iconSearch} alt="" />
        </button>
      </form>
      {renderCleaners()}
    </section>
  );
}

export default CleanerData;
